import React from 'react';
import FilterButton from './FilterButton';
import categories from "../../resultaten/categories";
import slugify from "../../slugify";

const Filters = ({ ...props}) => (
    <div className="Filters" {...props}>
      <div className="d-none d-sm-block">
        <div className="btn-group btn-group-sm me-3">
          <FilterButton type="secondary" label="vrouwen">Vrouwen</FilterButton>
          <FilterButton type="secondary" label="mannen">Mannen</FilterButton>
        </div>

        <div className="btn-group btn-group-sm me-3">
          <FilterButton type="success" label="indoor">Indoor</FilterButton>
          <FilterButton type="success" label="outdoor">Outdoor</FilterButton>
        </div>

        <div className="btn-group btn-group-sm mt-3">
          {
            categories.map((category) => (
              <FilterButton key={category}
                            type="primary"
                            label={slugify(category)}>
                {category}
              </FilterButton>
            ))
          }
        </div>

      </div>

      <div className="d-sm-none">
        <div className="d-block btn-group-vertical mb-3 btn-group btn-group-sm">
          <FilterButton type="secondary" label="vrouwen">Vrouwen</FilterButton>
          <FilterButton type="secondary" label="mannen">Mannen</FilterButton>
        </div>

        <div className="d-block btn-group-vertical mb-3 btn-group btn-group-sm">
          <FilterButton type="success" label="indoor">Indoor</FilterButton>
          <FilterButton type="success" label="outdoor">Outdoor</FilterButton>
        </div>

        <div className="d-block btn-group-vertical mb-3 btn-group btn-group-sm">
          {
            categories.map((category) => (
                <FilterButton key={category}
                              type="primary"
                              label={slugify(category)}>
                  {category}
                </FilterButton>
            ))
          }
        </div>
      </div>
    </div>
)

export default Filters;
