import {
    ACHTKAMP,
    DERTIG_METER,
    DISCUSWERPEN,
    DRIE_KEER_ZESHONDERD_METER,
    DRIE_KILOMETER_SNELWANDELEN,
    DRIEDUIZEND_METER_SNELWANDELEN,
    DRIEKAMP,
    DUIZEND_METER_SNELWANDELEN,
    EEN_ENGELSE_MIJL,
    EEN_UUR,
    HAMERSLINGEREN,
    HAMERSLINGEREN_3KG,
    HAMERSLINGEREN_4KG,
    HINKSTAPSPRINGEN,
    HOCKEYWERPEN,
    HONDERDVIJFTIG_METER_HORDEN,
    KOGELSTOTEN,
    KOGELSTOTEN_2KG,
    KOGELSTOTEN_3KG,
    KOGELSTOTEN_4KG,
    NEGENKAMP,
    OLYMPISCHE_AFLOSSING,
    POLSSTOKHOOGSPRINGEN,
    SPEERWERPEN,
    SPEERWERPEN_400G,
    SPEERWERPEN_500G,
    SPEERWERPEN_600G,
    TIEN_KILOMETER_SNELWANDELEN,
    TIENDUIZEND_METER_SNELWANDELEN,
    TIENKAMP,
    TWEE_ENGELSE_MIJL,
    TWEEDUIZEND_METER_SNELWANDELEN,
    VEERTIG_METER,
    VIER_KEER_DUIZENDVIJFHONDERD_METER,
    VIER_KEER_TACHTIG_METER_HG, VIER_KEER_ZESTIG_METER_HG,
    VIERKAMP,
    VIJF_KILOMETER_SNELWANDELEN,
    VIJFDUIZEND_METER_SNELWANDELEN,
    VIJFENDERTIG_METER,
    VIJFKAMP,
    VIJFTIG_METER, VORTEX,
    ZESKAMP,
    ZEVENKAMP
} from "../constants/onderdelen";

const opOnderdeel = (a, b) =>
  (onderdelen.indexOf(a.onderdeel) + 1) - (onderdelen.indexOf(b.onderdeel) + 1)

const resultatenNaarTabellen = resultaten => {
  resultaten.sort(opOnderdeel)

  const hash = resultaten
    .reduce((accumulator, currentValue) => {
      const { categorie, locatie, geslacht } = currentValue;
      const key = `${categorie}-${geslacht}-${locatie}`;

      const titel = categorie.toLowerCase() === 'wegatletiek' ?
        `${geslacht} ${categorie}` :
        `${geslacht} ${categorie} ${locatie}`;

      accumulator[key] = accumulator[key] || {
        titel,
        geslacht: geslacht.toLowerCase(),
        categorie: categorie.toLowerCase().replace(' ', '-'),
        locatie: locatie.toLowerCase(),
        rijen: []
      };
      accumulator[key].rijen.push(currentValue);

      return accumulator;
    }, {});

    const tabellen = Object
      .keys(hash)
      .map(key => hash[key]);

    return tabellen
      .map(tabel => {
          tabel.rijen = filterMeestRecentePrestatiesEnEvenaringen(tabel.rijen)
          return tabel
      })
};

// we willen alleen het meest recente record laten zien.
// tenzij het record een evenaring is van een vorig record, dan willen
// we beiden laten zien
const filterMeestRecentePrestatiesEnEvenaringen = (rijen) => {
    const rijenPerOnderdeel = rijen.reduce((acc, currentValue) => {
        const key = currentValue.onderdeel
        acc[key] = acc[key] || []
        acc[key].push(currentValue)
        return acc
    }, {})

    return rijen.filter(rij => {
        const aantalRijenVoorOnderdeel = rijenPerOnderdeel[rij.onderdeel].length

        if (aantalRijenVoorOnderdeel <= 1) {
            return true
        }

        // skip deze logica voor meerkampen.
        // deze moeten nl. worden vergeleken op puntenaantal en niet op 'grootheid'
        if (rij.onderdeel === DRIEKAMP ||
            rij.onderdeel === VIERKAMP ||
            rij.onderdeel === VIJFKAMP ||
            rij.onderdeel === ZESKAMP ||
            rij.onderdeel === ZEVENKAMP ||
            rij.onderdeel === ACHTKAMP ||
            rij.onderdeel === NEGENKAMP ||
            rij.onderdeel === TIENKAMP
        ) {
            return true
        }

        const rijenVoorOnderdeel = rijenPerOnderdeel[rij.onderdeel]
        let grootheid

        try {
            grootheid = onderdeelToGrootheid(rij.onderdeel)
        } catch (error) {
            console.log('fout in filterMeestRecentePrestatiesEnEvenaringen: ', error)
        }

        // sorteer rijen per onderdeel op datum & prestatie
        rijenVoorOnderdeel.sort((a, b) => {
            let prestatieCompared
            if (grootheid === GROOTHEID_TIJD) {
                prestatieCompared = b.parsedPrestatie.value - a.parsedPrestatie.value
            } else {
                prestatieCompared = a.parsedPrestatie.value - b.parsedPrestatie.value
            }

            return stringDatumNaarDatumObject(a.datum) - stringDatumNaarDatumObject(b.datum) || prestatieCompared
        })

        const meestRecentePrestatie = rijenVoorOnderdeel[rijenVoorOnderdeel.length - 1]

        if (meestRecentePrestatie.datum === rij.datum) {
            if (grootheid === GROOTHEID_TIJD) {
                return meestRecentePrestatie.prestatie >= rij.prestatie
            } else {
                return meestRecentePrestatie.prestatie <= rij.prestatie
            }
        }

        if (meestRecentePrestatie.prestatie === rij.prestatie) {
            return true
        }
    })
}

const stringDatumNaarDatumObject = (stringDatum) => {
    const parts = stringDatum.split('-')
    return Date.parse(`${parts[2]}-${parts[1]}-${parts[0]}`)
}

export default resultatenNaarTabellen

export const GROOTHEID_TIJD = 'tijd'
export const GROOTHEID_AFSTAND = 'afstand'

export const onderdeelToGrootheid = (onderdeel) => {
    switch (onderdeel) {
        case DERTIG_METER:
        case VIJFENDERTIG_METER:
        case VEERTIG_METER:
        case VIJFTIG_METER:
        case "50 m h.t.":
        case "60 m":
        case "80 m":
        case "100 m":
        case "150 m":
        case "200 m":
        case "300 m":
        case "400 m":
        case "600 m":
        case "800 m":
        case "1 000 m":
        case "1 500 m":
        case EEN_ENGELSE_MIJL:
        case TWEE_ENGELSE_MIJL:
        case "10 Engelse mijl":
        case "2 000 m":
        case "3 000 m":
        case "5 000 m":
        case "10 000 m":
        case "10 km":
        case "15 km":
        case "20 km":
        case "Halve marathon":
        case "25 km":
        case "30 km":
        case "35 km":
        case "Marathon":
        case "100 km":
        case "40 m horden":
        case "60 m horden":
        case "80 m horden":
        case "100 m horden":
        case "100 m horden (84 cm)*":
        case "100 m horden (91,4 cm)*":
        case "110 m horden":
        case HONDERDVIJFTIG_METER_HORDEN:
        case "300 m horden":
        case "400 m horden":
        case DRIE_KEER_ZESHONDERD_METER:
        case "4 x 60 m":
        case VIER_KEER_ZESTIG_METER_HG:
        case VIER_KEER_TACHTIG_METER_HG:
        case "4 x 80 m":
        case "4 x 100 m":
        case "4 x 100 m h.t.":
        case "4 x 200 m":
        case "4 x 400 m":
        case "4 x 800 m":
        case VIER_KEER_DUIZENDVIJFHONDERD_METER:
        case "Zweedse estafette":
        case OLYMPISCHE_AFLOSSING:
        case DUIZEND_METER_SNELWANDELEN:
        case TWEEDUIZEND_METER_SNELWANDELEN:
        case DRIE_KILOMETER_SNELWANDELEN:
        case DRIEDUIZEND_METER_SNELWANDELEN:
        case VIJF_KILOMETER_SNELWANDELEN:
        case VIJFDUIZEND_METER_SNELWANDELEN:
        case TIEN_KILOMETER_SNELWANDELEN:
        case TIENDUIZEND_METER_SNELWANDELEN:
        case "20 km snelwandelen":
        case "30 km snelwandelen":
        case "50 km snelwandelen":
        case "1 500 m steeplechase":
        case "2 000 m steeplechase":
        case "3 000 m steeplechase":
          return GROOTHEID_TIJD

        case HOCKEYWERPEN:
        case EEN_UUR:
        case "Balwerpen":
        case "Hoogspringen":
        case "Verspringen":
        case "Gewichtwerpen":
        case KOGELSTOTEN:
        case KOGELSTOTEN_2KG:
        case KOGELSTOTEN_3KG:
        case KOGELSTOTEN_4KG:
        case "Kogelslingeren":
        case POLSSTOKHOOGSPRINGEN:
        case HINKSTAPSPRINGEN:
        case DISCUSWERPEN:
        case SPEERWERPEN:
        case SPEERWERPEN_400G:
        case SPEERWERPEN_500G:
        case SPEERWERPEN_600G:
        case "10 x 10 km":
        case HAMERSLINGEREN:
        case HAMERSLINGEREN_3KG:
        case HAMERSLINGEREN_4KG:
        case VORTEX:
            return GROOTHEID_AFSTAND

        case DRIEKAMP:
        case VIERKAMP:
        case VIJFKAMP:
        case ZESKAMP:
        case ZEVENKAMP:
        case ACHTKAMP:
        case NEGENKAMP:
        case TIENKAMP:
            console.log(`unable to determine grootheid for onderdeel '${onderdeel}'`)
            console.log(GROOTHEID_AFSTAND)
          // throw new Error(`unable to determine grootheid for onderdeel '${onderdeel}'`)
    }
}

const onderdelen = [
    "30 m",
    "35 m",
    "40 m",
    "50 m",
    "50 m h.t.",
    "60 m",
    "80 m",
    "100 m",
    "150 m",
    "200 m",
    "300 m",
    "400 m",
    "600 m",
    "800 m",
    "1 000 m",
    "1 500 m",
    EEN_ENGELSE_MIJL,
    TWEE_ENGELSE_MIJL,
    "10 Engelse mijl",
    "2 000 m",
    "3 000 m",
    "5 000 m",
    "10 000 m",
    "10 km",
    "15 km",
    "20 km",
    "Halve marathon",
    "25 km",
    "30 km",
    "35 km",
    "Marathon",
    "100 km",
    "40 m horden",
    "60 m horden",
    "80 m horden",
    "100 m horden",
    "100 m horden (84 cm)*",
    "100 m horden (91,4 cm)*",
    "110 m horden",
    HONDERDVIJFTIG_METER_HORDEN,
    "300 m horden",
    "400 m horden",
    DRIE_KEER_ZESHONDERD_METER,
    VIER_KEER_TACHTIG_METER_HG,
    "4 x 60 m",
    VIER_KEER_ZESTIG_METER_HG,
    "4 x 80 m",
    VIER_KEER_TACHTIG_METER_HG,
    "4 x 100 m",
    "4 x 100 m h.t.",
    "4 x 200 m",
    "4 x 400 m",
    "4 x 800 m",
    "4 x 1 500 m",
    "Zweedse estafette",
    OLYMPISCHE_AFLOSSING,
    DUIZEND_METER_SNELWANDELEN,
    TWEEDUIZEND_METER_SNELWANDELEN,
    DRIE_KILOMETER_SNELWANDELEN,
    DRIEDUIZEND_METER_SNELWANDELEN,
    VIJF_KILOMETER_SNELWANDELEN,
    VIJFDUIZEND_METER_SNELWANDELEN,
    TIEN_KILOMETER_SNELWANDELEN,
    TIENDUIZEND_METER_SNELWANDELEN,
    "20 km snelwandelen",
    "30 km snelwandelen",
    "50 km snelwandelen",
    "1 500 m steeplechase",
    "2 000 m steeplechase",
    "3 000 m steeplechase",
    EEN_UUR,
    "Balwerpen",
    HOCKEYWERPEN,
    "Hoogspringen",
    "Verspringen",
    "Gewichtwerpen",
    KOGELSTOTEN,
    KOGELSTOTEN_2KG,
    KOGELSTOTEN_3KG,
    KOGELSTOTEN_4KG,
    "Kogelslingeren",
    "Polsstokhoogspringen",
    HAMERSLINGEREN,
    HAMERSLINGEREN_3KG,
    HAMERSLINGEREN_4KG,
    "Hinkstapspringen",
    "Discuswerpen",
    SPEERWERPEN,
    SPEERWERPEN_400G,
    SPEERWERPEN_500G,
    SPEERWERPEN_600G,
    VORTEX,
    DRIEKAMP,
    VIERKAMP,
    VIJFKAMP,
    "Zeskamp",
    "Zevenkamp",
    "Achtkamp",
    "Negenkamp",
    "Tienkamp",
    "10 x 10 km",
]
