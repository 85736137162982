const categories = [
"Senioren",
"Junioren A",
"Junioren B",
"Junioren C",
"Junioren D",
"Wegatletiek",
]
export default categories

