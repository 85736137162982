import resultaten from './raw'
import {GROOTHEID_AFSTAND, GROOTHEID_TIJD, onderdeelToGrootheid} from "./resultaten-naar-tabellen";
import {isoToSeconds, secondsToIso} from "../notaties/tijd";
import {centimeterToNotatie, notatieToCentimeter} from "../notaties/afstand";
import {
  ACHTKAMP,
  DERTIG_METER,
  DRIEHONDERD_METER,
  DRIEHONDERD_METER_HORDEN,
  HONDERD_METER,
  HONDERD_METER_HORDEN,
  HONDERDENTIEN_METER_HORDEN,
  HONDERDVIJFTIG_METER,
  NEGENKAMP,
  TACHTIG_METER,
  TACHTIG_METER_HORDEN,
  TIENKAMP,
  TWEEHONDERD_METER,
  VEERTIG_METER,
  VEERTIG_METER_HORDEN,
  DRIE_KEER_ZESHONDERD_METER,
  VIER_KEER_HONDERD_METER,
  VIER_KEER_TACHTIG_METER,
  VIER_KEER_ZESTIG_METER,
  VIERHONDERD_METER,
  VIERHONDERD_METER_HORDEN,
  VIJFENDERTIG_METER,
  VIJFKAMP,
  VIJFTIG_METER,
  VIJFTIG_METER_HORDEN,
  ZESKAMP,
  ZESTIG_METER,
  ZESTIG_METER_HORDEN,
  ZEVENKAMP,
  DRIEKAMP,
  VIERKAMP,
} from "../constants/onderdelen";

export const MEERKAMPEN = [
  DRIEKAMP,
  VIERKAMP,
  VIJFKAMP,
  ZESKAMP,
  ZEVENKAMP,
  ACHTKAMP,
  NEGENKAMP,
  TIENKAMP
]

export const ONDERDELEN_ZONDER_ONDERSTEUNING_VOOR_HISTORIE = MEERKAMPEN

export default resultaten
  .map(resultaat => enhanceRawResultaat(resultaat))
  .filter(resultaat => resultaat !== null)

export function enhanceRawResultaat(resultaat) {
  try {
    if (resultaat.naam.indexOf(',') !== -1) {
      resultaat.naam = resultaat.naam.split(', ')
    }

    resultaat.prestatie = JSON.parse(resultaat.prestatie.code)

    if (ONDERDELEN_ZONDER_ONDERSTEUNING_VOOR_HISTORIE.includes(resultaat.onderdeel)) {
      return resultaat
    }

    const value = prestatieToValueForResultaat(resultaat)
    resultaat.parsedPrestatie = {
      value: value,
      grootheid: onderdeelToGrootheid(resultaat.onderdeel),
      eenheid: onderdeelToEenheid(resultaat.onderdeel),
      officieleNotatie: valueToPrestatie(resultaat.onderdeel, value)
    }

    return resultaat
  } catch (error) {
    console.debug(`failed to parse resultaat: ${JSON.stringify(resultaat)} error: ${error}`)
    return null
  }
}

function onderdeelToEenheid(onderdeel) {
  switch (onderdeelToGrootheid(onderdeel)) {
    case GROOTHEID_TIJD:
      return 'tijd'

    case GROOTHEID_AFSTAND:
      return 'meter'

    default:
      console.warn(`unable to determine eenheid for onderdeel: ${onderdeel}`)
      return '?'
  }
}

function prestatieToValueForResultaat(resultaat) {
  let prestatie = resultaat.prestatie

  if (resultaat.handgeklokt) {
    prestatie = resultaat.prestatie
  }

  switch (onderdeelToGrootheid(resultaat.onderdeel)) {
    case GROOTHEID_TIJD:
      let seconds = isoToSeconds(prestatie)

      return !resultaat.handgeklokt ? seconds : addCorrectieVoorHandgeklokteTijd(resultaat.onderdeel, seconds)

    case GROOTHEID_AFSTAND:
      // TODO: afstanden als '1 uur' zouden in m / km moeten worden getoond
      return notatieToCentimeter(prestatie)

    default:
      console.warn(`unable to convert prestatie to value: ${JSON.stringify(resultaat)}`)
      return 0
  }
}

export function valueToPrestatie(onderdeel, value) {
  switch (onderdeelToGrootheid(onderdeel)) {
    case GROOTHEID_TIJD:
      return secondsToIso(value)

    case GROOTHEID_AFSTAND:
      return `${centimeterToNotatie(value)} m`

    default:
      console.warn(`unable to convert value to prestatie: ${onderdeel}, ${value}`)
      return '?'
  }
}

export function addCorrectieVoorHandgeklokteTijd(onderdeel, seconden) {
  let result = seconden
  switch (onderdeel) {
    case DERTIG_METER:
    case VIJFENDERTIG_METER:
    case VEERTIG_METER:
    case VIJFTIG_METER:
    case ZESTIG_METER:
    case TACHTIG_METER:
    case HONDERD_METER:
    case HONDERDVIJFTIG_METER:
    case TWEEHONDERD_METER:
    case DRIEHONDERD_METER:
    case VEERTIG_METER_HORDEN:
    case VIJFTIG_METER_HORDEN:
    case ZESTIG_METER_HORDEN:
    case TACHTIG_METER_HORDEN:
    case HONDERD_METER_HORDEN:
    case HONDERDENTIEN_METER_HORDEN:
    case DRIEHONDERD_METER_HORDEN:
    case VIER_KEER_ZESTIG_METER:
    case VIER_KEER_TACHTIG_METER:
      result += 0.24
      break;

    case VIERHONDERD_METER:
    case VIER_KEER_HONDERD_METER:
    case VIERHONDERD_METER_HORDEN:
      result += 0.14
      break;
  }

  return result
}
