import React from 'react'
import { object } from 'prop-types'

const Prestatie = ({ record }) => {
  return record.handgeklokt ?
      `${record.parsedPrestatie.officieleNotatie} (${record.prestatie})` :
      record.parsedPrestatie.officieleNotatie
}

Prestatie.propTypes = {
  record: object.isRequired,
}

export default Prestatie
