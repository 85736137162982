import React from 'react'
import { oneOfType, array, string } from 'prop-types'

const Naam = ({children, ...props}) =>
  <span className="text-nowrap" {...props}>{children}</span>

const Namen = ({value}) =>
  Array.isArray(value) ?
    value
      .map((value, index) => <Naam key={index}>{ value }</Naam>)
      // reduce via https://stackoverflow.com/a/44738250
      .reduce((acc, x) => acc === null ? [x] : [acc, ', ', x], null):
    <Naam>{ value }</Naam>

Namen.propTypes = {
  value: oneOfType([array, string])
}

export default Namen
