import React from 'react';
import PropTypes from 'prop-types';

const RanglijstSectie = ({ title, children, ...props }) => (
    <div className="RanglijstSectie container" {...props}>
        <div className="row">
            <div className="col-12">
                <h3 className="mb-3">{ title }</h3>
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                { children }
            </div>
        </div>
    </div>
)

RanglijstSectie.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default RanglijstSectie;
