import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Overview from "./pages/Overview";
import Historie from "./pages/Historie";
import './print.css';

const App = () => (
    <Router>
        <Routes>
            <Route index element={<Overview />} />
            <Route path="/historie" element={<Historie />} />
        </Routes>
    </Router>
)

export default App;
