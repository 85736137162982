export const notatieToCentimeter = (notatie) => {
  return Math.round(parseFloat(notatie.replace(',', '.')) * 100)
}

const notatieFormatter = new Intl.NumberFormat('nl-NL', {
  style: 'decimal',
  minimumFractionDigits: 2,
})

export const centimeterToNotatie = (centimeter) => {
  return notatieFormatter.format(centimeter / 100)
}
