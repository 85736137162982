import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

const FilterButton = ({ label, type, children, ...props }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const onFilterClicked = (label) => {
    const { search } = location;

    const params = new URLSearchParams(search);
    const isSet = params.get(label) === 'true';

    if (isSet) {
      params.delete(label);
    } else {
      params.append(label, true);
    }

    navigate(`?${params.toString()}`);
  }

  const categoryIsActive = (label) => {
    const { search } = location;

    const params = new URLSearchParams(search);
    return params.get(label) === 'true';
  }

  const activeClass = categoryIsActive(label) ? 'active' : '';

  return (
      <button type="button"
              className={`FilterButton btn btn-outline-${type} ${activeClass}`}
              onClick={() => onFilterClicked(label)}>
        {children}
      </button>
  )
}

export default FilterButton
