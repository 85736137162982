import React from 'react';
import Ranglijst from "../components/Ranglijst/Ranglijst";
import RanglijstSectie from "../components/RanglijstSectie";
import Filters from "../components/Filters";
import resultaten from '../resultaten/index';
import resultatenNaarTabellen from '../resultaten/resultaten-naar-tabellen';
import categories from "../resultaten/categories";
import slugify from "../slugify";
import {useLocation} from "react-router-dom";

const Overview = () => {
  const location = useLocation()

  const categoryIsSet = (label) => {
    const params = new URLSearchParams(location.search);
    return params.get(label) === 'true';
  }

  const allCategoriesAreSet = (categories) => {
    return categories
      .reduce((accumulator, currentValue) =>
        accumulator !== false ?
          categoryIsSet(currentValue) :
          false
      , true);
  }

  const atLeastOneCategoryIsSet = (categories) => {
    return categories
      .reduce((accumulator, currentValue) =>
          accumulator === true ?
            true :
            categoryIsSet(currentValue)
        , false);
  }

  const data = resultatenNaarTabellen(resultaten)

  const clubName = process.env.REACT_APP_CLUB_NAME
  const emailAddress = process.env.REACT_APP_CONTACT_EMAIL_ADDRESS
  const newRecordUrl = process.env.REACT_APP_NEW_RECORD_URL

    return (
        <div className="Overview">
          <div className="container d-print-none">
            <div className="row">
              <div className="col-12">
                <h1>Clubrecords {clubName}</h1>
                <hr className="my-4"/>
              </div>
            </div>
          </div>
          <div className="container d-print-none">
            <div className="row">
              <div className="col-12">
                <Filters categories={categories} />
              </div>
            </div>
            <div className="row">
              <div className="col-12">&nbsp;</div>
            </div>
            <div className="row">
              <div className="col-12">
                {
                  newRecordUrl ?
                      <p>
                        Clubrecord toevoegen? <a href={`${newRecordUrl}`} target="_blank" rel="noopener noreferrer">Laat het ons weten via Twizzit!</a>
                      </p> :
                      <p>
                        Clubrecord toevoegen? <a href={`mailto:${emailAddress}`} target="_blank" rel="noopener noreferrer">Neem contact op</a>.
                      </p>
                }
              </div>
            </div>
          </div>
          {
            data
              .filter(tabel => {
                const { geslacht, categorie, locatie } = tabel;
                const geslachtIsSet = atLeastOneCategoryIsSet(['mannen', 'vrouwen']);
                const locatieIsSet = atLeastOneCategoryIsSet(['indoor', 'outdoor']);
                const categoryIsSet = atLeastOneCategoryIsSet(categories.map((c) => slugify(c)));

                const categoriesToCheck = [];
                if (geslachtIsSet) {
                  categoriesToCheck.push(geslacht);
                }

                if (locatieIsSet) {
                  categoriesToCheck.push(locatie);
                }

                if (categoryIsSet) {
                  categoriesToCheck.push(categorie);
                }

                return allCategoriesAreSet(categoriesToCheck);
              })
              .map((tabel, index) =>
                <RanglijstSectie title={tabel.titel} key={index}>
                  <Ranglijst rijen={tabel.rijen}/>
                </RanglijstSectie>
              )
          }
        </div>
    );
}

export default Overview;
