import React from 'react'
import { string, oneOfType, instanceOf } from "prop-types";

const Datum = ({ value }) =>
  typeof value === 'object' ?
    value.toLocaleDateString() :
    value;

Datum.propTypes = {
  value: oneOfType([
    string.isRequired,
    instanceOf(Date).isRequired
  ]).isRequired
}

export default Datum
