export const DERTIG_METER = '30 m';
export const VIJFENDERTIG_METER = '35 m';
export const VEERTIG_METER = '40 m';
export const VIJFTIG_METER = '50 m';
export const ZESTIG_METER = '60 m';
export const TACHTIG_METER = '80 m';
export const HONDERD_METER = '100 m';
export const HONDERDVIJFTIG_METER = '150 m';
export const TWEEHONDERD_METER = '200 m';
export const DRIEHONDERD_METER = '300 m';
export const VIERHONDERD_METER = '400 m';
export const ZESHONDERD_METER = '600 m';
export const ACHTHONDERD_METER = '800 m';
export const DUIZEND_METER = '1 000 m';
export const DUIZENDVIJFHONDERD_METER = '1 500 m';
export const EEN_ENGELSE_MIJL = '1 Engelse mijl';
export const TWEE_ENGELSE_MIJL = '2 Engelse mijl';
export const TIEN_ENGELSE_MIJL = '10 Engelse mijl';
export const TWEEDUIZEND_METER = '2 000 m';
export const DRIEDUIZEND_METER = '3 000 m';
export const VIJFDUIZEND_METER = '5 000 m';
export const TIENDUIZEND_METER = '10 000 m';
export const TIEN_KILOMETER = '10 km';
export const VIJFTIEN_KILOMETER = '15 km';
export const TWINTIG_KILOMETER = '20 km';
export const HALVE_MARATHON = 'Halve marathon';
export const VIJFENTWINTIG_KILOMETER = '25 km';
export const DERTIG_KILOMETER = '30 km';
export const VIJFENDERTIG_KILOMETER = '35 km';
export const MARATHON = 'Marathon';
export const HONDERD_KILOMETER = '100 km';
export const VEERTIG_METER_HORDEN = '40 m horden';
export const VIJFTIG_METER_HORDEN = '60 m horden';
export const ZESTIG_METER_HORDEN = '60 m horden';
export const TACHTIG_METER_HORDEN = '80 m horden';
export const HONDERD_METER_HORDEN = '100 m horden';
export const HONDERDENTIEN_METER_HORDEN = '110 m horden';
export const HONDERDVIJFTIG_METER_HORDEN = '150 m horden';
export const DRIEHONDERD_METER_HORDEN = '300 m horden';
export const VIERHONDERD_METER_HORDEN = '400 m horden';
export const DRIE_KEER_ZESHONDERD_METER = '3 x 600 m';
export const VIER_KEER_ZESTIG_METER = '4 x 60 m';
export const VIER_KEER_ZESTIG_METER_HG = '4 x 60 m hg';
export const VIER_KEER_TACHTIG_METER = '4 x 80 m';
export const VIER_KEER_TACHTIG_METER_HG = '4 x 80 m hg';
export const VIER_KEER_HONDERD_METER = '4 x 100 m';
export const VIER_KEER_TWEEHONDERD_METER = '4 x 200 m';
export const VIER_KEER_VIERHONDERD_METER = '4 x 400 m';
export const VIER_KEER_ACHTHONDERD_METER = '4 x 800 m';
export const VIER_KEER_DUIZENDVIJFHONDERD_METER = '4 x 1 500 m';
export const ZWEEDSE_ESTAFETTE = 'Zweedse estafette';
export const OLYMPISCHE_AFLOSSING = 'Olympische aflossing';

export const DUIZEND_METER_SNELWANDELEN = '1 000 m snelwandelen';
export const TWEEDUIZEND_METER_SNELWANDELEN = '2 000 m snelwandelen';
export const DRIE_KILOMETER_SNELWANDELEN = '3 km snelwandelen';
export const DRIEDUIZEND_METER_SNELWANDELEN = '3 000 m snelwandelen';
export const VIJF_KILOMETER_SNELWANDELEN = '5 km snelwandelen';
export const VIJFDUIZEND_METER_SNELWANDELEN = '5 000 m snelwandelen';
export const TIEN_KILOMETER_SNELWANDELEN = '10 km snelwandelen';
export const TIENDUIZEND_METER_SNELWANDELEN = '10 000 m snelwandelen';
export const TWINTIG_KILOMETER_SNELWANDELEN = '20 km snelwandelen';
export const DERTIG_KILOMETER_SNELWANDELEN = '30 km snelwandelen';
export const VIJFTIG_KILOMETER_SNELWANDELEN = '50 km snelwandelen';
export const DUIZENDVIJFHONDERD_METER_STEEPLECHASE = '1 500 m steeplechase';
export const TWEEDUIZEND_METER_STEEPLECHASE = '2 000 m steeplechase';
export const DRIEDUIZEND_METER_STEEPLECHASE = '3 000 m steeplechase';

export const EEN_UUR = '1 uur';

export const BALWERPEN = 'Balwerpen';
export const HOCKEYWERPEN = 'Hockeywerpen';
export const HOOGSPRINGEN = 'Hoogspringen';
export const VERSPRINGEN = 'Verspringen';
export const GEWICHTWERPEN = 'Gewichtwerpen';
export const KOGELSTOTEN= 'Kogelstoten';
export const KOGELSTOTEN_2KG= 'Kogelstoten 2kg';
export const KOGELSTOTEN_3KG= 'Kogelstoten 3kg';
export const KOGELSTOTEN_4KG= 'Kogelstoten 4kg';
export const KOGELSLINGEREN = 'Kogelslingeren';
export const POLSSTOKHOOGSPRINGEN = 'Polsstokhoogspringen';
export const HINKSTAPSPRINGEN = 'Hinkstapspringen';
export const HAMERSLINGEREN = 'Hamerslingeren';
export const HAMERSLINGEREN_3KG = 'Hamerslingeren 3kg';
export const HAMERSLINGEREN_4KG = 'Hamerslingeren 4kg';
export const DISCUSWERPEN = 'Discuswerpen';
export const SPEERWERPEN = 'Speerwerpen';
export const SPEERWERPEN_400G = 'Speerwerpen 400g';
export const SPEERWERPEN_500G = 'Speerwerpen 500g';
export const SPEERWERPEN_600G = 'Speerwerpen 600g';
export const VORTEX = 'Vortex';

export const DRIEKAMP = 'Driekamp';
export const VIERKAMP = 'Vierkamp';
export const VIJFKAMP = 'Vijfkamp';
export const ZESKAMP = 'Zeskamp';
export const ZEVENKAMP = 'Zevenkamp';
export const ACHTKAMP = 'Achtkamp';
export const NEGENKAMP = 'Negenkamp';
export const TIENKAMP = 'Tienkamp';
