const resultaten = 
[
  {
    "geslacht": "Mannen",
    "datum": "01-01-1921",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 100 m",
    "plaats": "?",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"00.00.47,8\""
    },
    "naam": "..."
  },
  {
    "geslacht": "Mannen",
    "datum": "01-01-1922",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "plaats": "?",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"00.00.12,10\""
    },
    "naam": "Cor Saeys"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-01-1922",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "plaats": "?",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"00.00.25,2\""
    },
    "naam": "Joop v/d Schaar"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-01-1922",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.02.21,80\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "Jan Moolenaars"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-01-1922",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,70\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Haarlem",
    "naam": "J. Roos"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-01-1923",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "110 m horden",
    "plaats": "?",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"00.00.18,30\""
    },
    "naam": "Arie Kaan"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-01-1923",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,32\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "?",
    "naam": "Rein Verwers"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-01-1923",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"35,15\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "?",
    "naam": "J. Douzon"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-01-1924",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "110 m horden",
    "plaats": "?",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"00.00.18,10\""
    },
    "naam": "Arie Kaan"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-01-1924",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"39,70\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "?",
    "naam": "Arie Hartman"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-01-1927",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13,95\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "?",
    "naam": "Steph van Musscher"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-01-1928",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.09.11,30\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "?",
    "handgeklokt": true,
    "naam": "Arie Klaase"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-01-1928",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.15.40,40\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "?",
    "handgeklokt": true,
    "naam": "Arie Klaase"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-01-1928",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"41,105\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "?",
    "naam": "Gerrit Eijsker"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-01-1929",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,73\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "?",
    "naam": "Kees Visser"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-01-1929",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"46,72\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "?",
    "naam": "Anne van der Zee"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-01-1930",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m horden",
    "plaats": "?",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"00.01.00,20\""
    },
    "naam": "Jaap Brandenburg"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-01-1931",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"51,07\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "?",
    "naam": "Kees Visser"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-01-1954",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.33.32,90\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 000 m",
    "plaats": "?",
    "handgeklokt": true,
    "naam": "Paul Verra"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": true,
    "datum": "01-01-1954",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.55.10,00\""
    },
    "einddatum": "",
    "categorie": "Wegatletiek",
    "onderdeel": "15 km",
    "plaats": "?",
    "naam": "Bas Fennis"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-01-1955",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"8.52,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Rotterdam",
    "handgeklokt": true,
    "naam": "Paul Verra"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": true,
    "datum": "01-01-1957",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"55.04,0\""
    },
    "einddatum": "",
    "categorie": "Wegatletiek",
    "onderdeel": "15 km",
    "plaats": "?",
    "naam": "Bas Fennis"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": true,
    "datum": "01-01-1970",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"56.15,0\""
    },
    "einddatum": "",
    "categorie": "Wegatletiek",
    "onderdeel": "10 Engelse mijl",
    "plaats": "?",
    "naam": "Hans de Rijk"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "01-01-1982",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "50 m",
    "plaats": "?",
    "naam": "Nilo Emerenciana"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "01-01-1990",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.50,1\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "600 m",
    "plaats": "?",
    "naam": "Marjan Witteman"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-02-1921",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 100 m",
    "plaats": "?",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"47,6\""
    },
    "naam": "..."
  },
  {
    "geslacht": "Mannen",
    "datum": "01-02-1931",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"52,90\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "?",
    "naam": "Kees Visser"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-02-1954",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"32.05,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 000 m",
    "plaats": "?",
    "handgeklokt": true,
    "naam": "Paul Verra"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-02-2020",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1.50,24\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "link": "https://www.atletiek.nu/atleet/main/891306/",
    "plaats": "Apeldoorn",
    "naam": "Jurgen Wielart"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "01-03-1975",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "50 m",
    "plaats": "Groningen",
    "naam": "Jan Vollenga"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-05-1962",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"43,97\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Wolfsburg (D)",
    "naam": "Rein Knol"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-05-1962",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"58,49\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Wolfsburg (D)",
    "naam": "Jeltjo Doornbosch"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "01-05-1988",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"59,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Haarlem",
    "naam": "Jannie Sloot"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "01-05-1988",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"35,36\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Haarlem",
    "naam": "Marlies Koopman"
  },
  {
    "geslacht": "Mannen",
    "evenement": " Competitie 2e Divisie Poule 3 R1 [2022]",
    "handgeklokt": false,
    "datum": "01-05-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.55,17\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Zweedse estafette",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/37005/Zweedse/",
    "plaats": "Gouda",
    "naam": "Jurgen Wielart, Vester van Heijningen, Roeland Heitkönig, Stefan Scholte"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": " Competitie 2e Divisie Poule 3 R1 [2022]",
    "handgeklokt": false,
    "datum": "01-05-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"32,86\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "link": "https://www.atletiek.nu/wedstrijd/startlijst/461452/25/",
    "plaats": "Gouda",
    "naam": "Bente Elgersma"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": " Competitie 2e Divisie Poule 3 R1 [2022]",
    "handgeklokt": false,
    "datum": "01-05-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.20,74\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Zweedse estafette",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/37005/Zweedse/#263175_77",
    "plaats": "Gouda",
    "naam": "Albertine Vermeer, Vera Hijlkema, Femke Nuijten, Rosanna Tatti"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-06-1924",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"6,965\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Zandvoort",
    "naam": "Jaap Boot"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-06-1930",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14,13\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Haarlem",
    "naam": "Steph van Musscher"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-06-1930",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,62\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Haarlem",
    "naam": "Anne van der Zee"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-06-1974",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"9.32,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m steeplechase",
    "plaats": "Arnhem",
    "handgeklokt": true,
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-06-1979",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"46.53,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 km snelwandelen",
    "plaats": "Zaandam (weg)",
    "handgeklokt": true,
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-06-1984",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"29.43,46\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 000 m",
    "plaats": "Baton Rouge (VS)",
    "naam": "Stijn Jaspers"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-07-1924",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"10.28,9\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "?",
    "handgeklokt": true,
    "naam": "Jan Henneman"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-07-1926",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13,92\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "?",
    "naam": "Steph van Musscher"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-07-1934",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "110 m horden",
    "plaats": "Berlijn (D)",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"15,0\""
    },
    "naam": "Wim Kaan"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "01-07-1987",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"10.15,9\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "?",
    "naam": "Marianne v.d. Linde"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-08-1926",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"22,8\""
    },
    "naam": "Rinus v/d Berge"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-08-1926",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m horden",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"1.05,8\""
    },
    "naam": "Gerard Rinkel"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-08-1926",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.30,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 400 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "A. Paulen, R. v.d. Berge, Nooy, A. Hoogerwerf"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-08-1926",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.04,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "Ad Paulen"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-08-1926",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"11,31\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Haarlem",
    "naam": "Gerrit Eijsker"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-08-1926",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,40\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Haarlem",
    "naam": "Arie Hartman"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-08-1927",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,45\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "?",
    "naam": "Arie Hartman"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-08-1954",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15.18,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "?",
    "handgeklokt": true,
    "naam": "Paul Verra"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-08-1982",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"8.00,9\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Hechtel (B)",
    "handgeklokt": true,
    "naam": "Stijn Jaspers"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-09-1924",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,30\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Bloemendaal",
    "naam": "Arie Hartman"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-09-1926",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "110 m horden",
    "plaats": "?",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"16,2\""
    },
    "naam": "Arie Kaan"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-09-1926",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"16.00,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "?",
    "handgeklokt": true,
    "naam": "Arie Klaase"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-09-1929",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.14,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "?",
    "handgeklokt": true,
    "naam": "Andries Hoogerwerf"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-09-1946",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.03,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Zweedse estafette",
    "plaats": "Velsen",
    "handgeklokt": true,
    "naam": "C. Dekker, E. Scholten, B. Heyn, A. Schaap"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-09-1963",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.54,9\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Breda",
    "handgeklokt": true,
    "naam": "Adri Schaper"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-09-1979",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 100 m",
    "plaats": "Leiden",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"41,9\""
    },
    "naam": "M. Westbroek, H. Macnack, J.W. v.d. Wal, M. Bienfait"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-09-1979",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.20,3\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 400 m",
    "plaats": "Leiden ",
    "handgeklokt": true,
    "naam": "H. Macnack, K. Macnack, M. Bienfait, R. Sim"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-09-1979",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14,37\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Leiden",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "01-10-1987",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"35.27\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 000 m",
    "plaats": "Haarlem",
    "naam": "Marianne v.d. Linde"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "02-01-1971",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"58,7\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Leiden",
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "02-01-1971",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2.00,1\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Leiden",
    "naam": "Ron Koridon"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "02-02-1980",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"49,7\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Zwolle",
    "naam": "Mario Westbroek"
  },
  {
    "geslacht": "Mannen",
    "datum": "02-02-1985",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"7,43\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Maastricht",
    "naam": "Hein Macnack"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "02-02-1997",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1.33,92\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 200 m",
    "plaats": "Den Haag",
    "naam": "H. Baauw, G. Groskamp, E. v.d. Veen, G. Wessel"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "02-04-1967",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m",
    "plaats": "Amsterdam",
    "naam": "Gerard Kemp"
  },
  {
    "geslacht": "Mannen",
    "datum": "02-04-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.19,48\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "600 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/9814/600m/#86329_4",
    "plaats": "Haarlem",
    "naam": "Jurgen Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "02-07-1961",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"58,20\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Eindhoven",
    "naam": "Jeltjo Doornbosch"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "02-07-2023",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"26,34\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelslingeren",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/38716/Kogelsl/",
    "plaats": "Heerhugowaard",
    "naam": "Bente Elgersma"
  },
  {
    "geslacht": "Mannen",
    "datum": "02-08-1929",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14,05\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Amsterdam",
    "naam": "Steph van Musscher"
  },
  {
    "geslacht": "Mannen",
    "datum": "02-08-1931",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "110 m horden",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"15,5\""
    },
    "naam": "Wim Kaan"
  },
  {
    "geslacht": "Mannen",
    "datum": "02-09-1923",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "plaats": "Apeldoorn",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"11,2\""
    },
    "naam": "Frits Lamp"
  },
  {
    "geslacht": "Mannen",
    "datum": "02-09-1934",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,90\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Medemblik",
    "naam": "Anne van der Zee"
  },
  {
    "geslacht": "Mannen",
    "datum": "02-09-1961",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,84\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Haarlem",
    "naam": "Rein Knol"
  },
  {
    "geslacht": "Mannen",
    "datum": "02-09-1978",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"8.13,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Roosendaal",
    "handgeklokt": true,
    "naam": "Evert van Ravensburg"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "02-09-1979",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.40.13,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "20 km snelwandelen",
    "plaats": "Hove (GB) (weg)",
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "02-09-1984",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15,57\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Amsterdam (Ookmeer)",
    "naam": "Aafbrecht v.d. Veen"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "02-10-1986",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"44.55\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 000 m",
    "plaats": "Haarlem",
    "naam": "Sylvia de Krom"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "03-01-1971",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"55,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Leiden",
    "naam": "Hans van der Eem"
  },
  {
    "geslacht": "Mannen",
    "datum": "03-01-1971",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"11,35\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Leiden",
    "naam": "Jim Evelein"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "03-02-1980",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"49,37\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Zwolle",
    "naam": "Mario Westbroek"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "03-02-1991",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1.03,48\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Den Haag",
    "naam": "Chantal Bruyn"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "03-02-1991",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"10,65\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Den Haag",
    "naam": "Brenda Stoete"
  },
  {
    "geslacht": "Mannen",
    "datum": "03-05-1980",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4,70\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Haarlem",
    "naam": "Pim Göbel"
  },
  {
    "geslacht": "Mannen",
    "datum": "03-06-1923",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"6,13\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Zeist",
    "naam": "Rein Verwers"
  },
  {
    "geslacht": "Mannen",
    "datum": "03-06-1935",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,80\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Rijswijk",
    "naam": "Nees v.d. Klinkenberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "03-06-1956",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15.10,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Rotterdam",
    "handgeklokt": true,
    "naam": "Paul Verra"
  },
  {
    "geslacht": "Mannen",
    "datum": "03-06-1959",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m horden",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"55,2\""
    },
    "naam": "Joop Vissers"
  },
  {
    "geslacht": "Mannen",
    "datum": "03-07-1921",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,70\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Arnhem",
    "naam": "Steph van Duin"
  },
  {
    "geslacht": "Mannen",
    "datum": "03-07-1932",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "110 m horden",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"15,3\""
    },
    "naam": "Wim Kaan"
  },
  {
    "geslacht": "Mannen",
    "datum": "03-07-1932",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m horden",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"57,2\""
    },
    "naam": "Wim Kaan"
  },
  {
    "geslacht": "Mannen",
    "datum": "03-07-1966",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m horden",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"54,7\""
    },
    "naam": "Pim van Beek"
  },
  {
    "geslacht": "Mannen",
    "datum": "03-07-1966",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.53,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Groningen",
    "handgeklokt": true,
    "naam": "Wim Aaij"
  },
  {
    "geslacht": "Mannen",
    "datum": "03-07-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.46,75\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "link": "http://www.timetronics.eu/results/nacht2021/event033h01.html",
    "plaats": "Heusden (B)",
    "naam": "Jurgen Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "03-08-1924",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"32,90\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Amsterdam",
    "naam": "Gerrit Eijsker"
  },
  {
    "geslacht": "Mannen",
    "datum": "03-08-1924",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,89\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Amsterdam",
    "naam": "Antoon van Welsenes"
  },
  {
    "geslacht": "Mannen",
    "datum": "03-08-1930",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.12,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Groningen",
    "handgeklokt": true,
    "naam": "Wim Effern"
  },
  {
    "geslacht": "Mannen",
    "datum": "03-08-1930",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,85\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Groningen",
    "naam": "Gerrit Eijsker"
  },
  {
    "geslacht": "Mannen",
    "datum": "03-08-1930",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"47,42\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Groningen",
    "naam": "Kees Visser"
  },
  {
    "geslacht": "Mannen",
    "datum": "03-08-1977",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4,41\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Den Haag",
    "naam": "Charles Mathot"
  },
  {
    "geslacht": "Mannen",
    "datum": "03-08-1979",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"46.35,9\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 km snelwandelen",
    "plaats": "Zaandam (baan)",
    "handgeklokt": true,
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "03-10-1965",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"7.58,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 800 m",
    "plaats": "Delft",
    "handgeklokt": true,
    "naam": "G. Kamp, G. Zwart, A. Bosch, W. Aaij"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "03-10-1993",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.45,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "600 m",
    "plaats": "Haarlem",
    "naam": "Joanna Andrews"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "03-10-2010",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.09,9\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 000 m",
    "plaats": "Haarlem",
    "naam": "Larissa Scholten"
  },
  {
    "geslacht": "Mannen",
    "datum": "04-01-1981",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,81\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Dortmund (D)",
    "naam": "Jan Willem v.d. Wal"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "04-02-1979",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"51,68\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Zwolle",
    "naam": "Kenneth Macnack"
  },
  {
    "geslacht": "Mannen",
    "datum": "04-06-1922",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"24,55\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Haarlem",
    "naam": "Wim Hoppe"
  },
  {
    "geslacht": "Mannen",
    "datum": "04-06-1922",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"9,25\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Haarlem",
    "naam": "J. Roos"
  },
  {
    "geslacht": "Mannen",
    "datum": "04-06-1933",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"36,20\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelslingeren",
    "plaats": "Haarlem",
    "naam": "Bertus Gras"
  },
  {
    "geslacht": "Mannen",
    "datum": "04-06-1944",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"9.04,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "Dick Hagtingius"
  },
  {
    "geslacht": "Mannen",
    "datum": "04-06-1978",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,27\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Turijn (I)",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "04-06-1979",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Arnhem",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"48,5\""
    },
    "naam": "Mario Westbroek"
  },
  {
    "geslacht": "Mannen",
    "datum": "04-06-1983",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13.36,7\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Leuven (B)",
    "handgeklokt": true,
    "naam": "Stijn Jaspers"
  },
  {
    "geslacht": "Mannen",
    "datum": "04-06-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"63,50\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Hengelo",
    "naam": "Niels Terol"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "Fanny Blankers Koen Games",
    "handgeklokt": false,
    "datum": "04-06-2023",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"11,76\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "link": "",
    "plaats": "Hengelo",
    "naam": "Femke Nuijten"
  },
  {
    "geslacht": "Mannen",
    "datum": "04-07-1926",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"23,1\""
    },
    "naam": "Frits Lamp"
  },
  {
    "geslacht": "Mannen",
    "datum": "04-07-1980",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"47,14\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Den Haag",
    "naam": "Mario Westbroek"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "04-07-1993",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,1\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "plaats": "Santpoort-Noord",
    "naam": "Mieke van der Kolk"
  },
  {
    "geslacht": "Mannen",
    "datum": "04-07-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.48,46\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/33680/800m/#200560_6",
    "plaats": "Arnhem",
    "naam": "Jurgen Wielart"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "04-07-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.00,12\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 400 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/35653/4x400m/#236941_45",
    "plaats": "Alphen a/d Rijn",
    "naam": "Babs Koeman (B), Vera Hijlkema (A), Kristen Niedrach, Albertine Vermeer"
  },
  {
    "geslacht": "Mannen",
    "datum": "04-08-1946",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,975\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Amsterdam",
    "naam": "Cor Lamoree"
  },
  {
    "geslacht": "Mannen",
    "datum": "04-08-1957",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15.01,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "Paul Verra"
  },
  {
    "geslacht": "Mannen",
    "datum": "04-08-1963",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,88\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Amsterdam",
    "naam": "Rein Knol"
  },
  {
    "geslacht": "Mannen",
    "datum": "04-08-1974",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"10,62\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "plaats": "Arnhem",
    "naam": "Bert de Jager"
  },
  {
    "geslacht": "Mannen",
    "datum": "04-08-1974",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"9.28,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m steeplechase",
    "plaats": "Arnhem",
    "handgeklokt": true,
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "evenement": " De 54e Nationale D Spelen",
    "handgeklokt": false,
    "datum": "04-09-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,48 m\""
    },
    "einddatum": "",
    "categorie": "Junioren D",
    "onderdeel": "Kogelstoten",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagen/37729/293053/#losonderdeel_195_",
    "plaats": "Amsterdam",
    "naam": "Olliver Könst"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "04-10-1992",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.47,1\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "600 m",
    "plaats": "Haarlem",
    "naam": "Joanna Andrews"
  },
  {
    "geslacht": "Mannen",
    "datum": "05-02-1984",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"15,44\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Zuid-Laren",
    "naam": "Anne Jan v.d. Veen"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "05-02-1995",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"9,39\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Amsterdam",
    "naam": "Nanda Meijer"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "6e ITS Trackmeeting Sotra-Isokin",
    "handgeklokt": false,
    "datum": "05-02-2022",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2.11,44\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "link": "https://www.atletiek.nu/wedstrijd/startlijst/449379/6/",
    "plaats": "Apeldoorn",
    "naam": "Kristen Niedrach"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "05-05-1974",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"42,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "300 m",
    "plaats": "Amsterdam",
    "naam": "Ingrid de Jager"
  },
  {
    "geslacht": "Mannen",
    "datum": "05-05-1984",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"30.24,76\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 000 m",
    "plaats": "Lisse",
    "naam": "Pieter Fris"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "05-05-1984",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.30.14,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "20 km snelwandelen",
    "plaats": "Fana / Bergen (N) (baan)",
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "05-05-1984",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15,55\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Lisse",
    "naam": "Aafbrecht v.d. Veen"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "05-05-1993",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"6,07\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Sittard",
    "naam": "Mieke van der Kolk"
  },
  {
    "geslacht": "Mannen",
    "datum": "05-06-1921",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"52,2\""
    },
    "naam": "Ad Paulen"
  },
  {
    "geslacht": "Mannen",
    "datum": "05-06-1921",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,65\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Amsterdam",
    "naam": "Steph van Duin"
  },
  {
    "geslacht": "Mannen",
    "datum": "05-06-1932",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"55,14\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Haarlem",
    "naam": "Kees Visser"
  },
  {
    "geslacht": "Mannen",
    "datum": "05-06-1966",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.00,3\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Rotterdam",
    "handgeklokt": true,
    "naam": "Arnoud Bosch"
  },
  {
    "geslacht": "Mannen",
    "datum": "05-06-1966",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"59,44\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Rotterdam",
    "naam": "Jeltjo Doornbosch"
  },
  {
    "geslacht": "Mannen",
    "datum": "05-06-1968",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.53,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "Adri Schaper"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "05-06-1974",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.23,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Haarlem",
    "naam": "Ingrid de Jager"
  },
  {
    "geslacht": "Mannen",
    "datum": "05-06-1976",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.28,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 000 m",
    "plaats": "Dülken (D)",
    "handgeklokt": true,
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "05-06-1977",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,26\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Turijn (I)",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "05-06-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.66\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "Hoogspringen",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/34648/Hoog/#222291_13",
    "plaats": "Assendelft",
    "naam": "Babs Koeman (B)"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "05-06-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.66\""
    },
    "einddatum": "",
    "categorie": "Junioren B",
    "onderdeel": "Hoogspringen",
    "link": "https://www.atletiek.nu/atleet/main/1025264/",
    "plaats": "Assendelft",
    "naam": "Babs Koeman"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "05-06-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,66\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/34648/Hoog/#222291_13",
    "plaats": "Assendelft",
    "naam": "Babs Koeman (B)"
  },
  {
    "geslacht": "Mannen",
    "datum": "05-07-1930",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,73\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Londen (VK)",
    "naam": "Anne van der Zee"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "05-07-1936",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5.37.15,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "50 km snelwandelen",
    "plaats": "Bloemendaal (weg)",
    "naam": "G. Gaarthuis"
  },
  {
    "geslacht": "Mannen",
    "datum": "05-07-1942",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"17.02,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 1 500 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "W. v. Gog, J. Walgien, J. Brijs, H. de Roode"
  },
  {
    "geslacht": "Mannen",
    "datum": "05-09-1937",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"34.15,7\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 000 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "Arie Paap"
  },
  {
    "geslacht": "Mannen",
    "datum": "05-09-1979",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"16.22,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 1 500 m",
    "plaats": "Den Haag",
    "handgeklokt": true,
    "naam": "D. Portegijs, D. Bais, P. Fris, E. v. Ravensburg"
  },
  {
    "geslacht": "Mannen",
    "datum": "05-09-1979",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.27,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 400 m",
    "plaats": "Den Haag",
    "handgeklokt": true,
    "naam": "H. Macnack, M. Westbroek, J.W. v.d. Wal, M. Bienfait"
  },
  {
    "geslacht": "Mannen",
    "datum": "05-09-1981",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"7,28\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Haarlem",
    "naam": "Jan Willem v.d. Wal"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "05-09-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.64\""
    },
    "einddatum": "",
    "categorie": "Junioren B",
    "onderdeel": "Hoogspringen",
    "plaats": "Emmeloord",
    "naam": "Babs Koeman"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "05-09-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"100 m horden\",\n        \"prestatie\": \"15,10\",\n        \"punten\": \"(828)\"\n    },\n    {\n        \"onderdeel\": \"Hoogspringen\",\n        \"prestatie\": \"1.64\",\n        \"punten\": \"(783)\"\n    },\n    {\n        \"onderdeel\": \"Kogelstoten\",\n        \"prestatie\": \"9.07\",\n        \"punten\": \"(469)\"\n    },\n    {\n        \"onderdeel\": \"200 m\",\n        \"prestatie\": \"26.84\",\n        \"punten\": \"(725)\"\n    },\n    {\n        \"onderdeel\": \"Verspringen\",\n        \"prestatie\": \"5.30\",\n        \"punten\": \"(643)\"\n    },\n    {\n        \"onderdeel\": \"Speerwerpen\",\n        \"prestatie\": \"30.30\",\n        \"punten\": \"(482)\"\n    },\n    {\n        \"onderdeel\": \"800 m\",\n        \"prestatie\": \"2.27,48\",\n        \"punten\": \"(725)\"\n    },\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"4655 pnt\"\n    }\n]"
    },
    "einddatum": "06-09-2020",
    "categorie": "Junioren B",
    "onderdeel": "Zevenkamp",
    "plaats": "Emmeloord",
    "naam": "Babs Koeman"
  },
  {
    "geslacht": "Mannen",
    "datum": "05-10-1930",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.35,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 000 m",
    "plaats": "Parijs (F)",
    "handgeklokt": true,
    "naam": "Andries Hoogerwerf"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "06-01-1985",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"47,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Dortmund (D)",
    "naam": "Allen Ellsworth"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "06-02-1971",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"4.30,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Den Bosch",
    "naam": "Evert van Ravensburg"
  },
  {
    "geslacht": "Mannen",
    "datum": "06-02-1971",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,80\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Den Bosch",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "06-02-1980",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2,25\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Milaan (I)",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "06-02-1983",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"48,4\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Dortmund (D)",
    "naam": "Allen Ellsworth"
  },
  {
    "geslacht": "Mannen",
    "datum": "06-02-1983",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"7,16\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Dortmund (D)",
    "naam": "Jan Willem v.d. Wal"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "06-02-1988",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"7,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "50 m",
    "plaats": "Utrecht",
    "naam": "Berja Koning"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "06-02-1988",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2.10,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "600 m",
    "plaats": "Utrecht",
    "naam": "Aneska de Windt"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "06-02-2022",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"7,72\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/36318/60m/#255192_3",
    "plaats": "Assendelft",
    "naam": "Femke Nuijten"
  },
  {
    "geslacht": "Mannen",
    "datum": "06-03-1982",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"42,40\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelslingeren",
    "plaats": "Overveen",
    "naam": "Gosse van de Velde"
  },
  {
    "geslacht": "Mannen",
    "datum": "06-03-1982",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"41,18\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelslingeren",
    "plaats": "Overveen",
    "naam": "Gosse van de Velde"
  },
  {
    "geslacht": "Mannen",
    "datum": "06-04-1979",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"48.56,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 km snelwandelen",
    "plaats": "Zaandam (weg)",
    "handgeklokt": true,
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "06-05-1956",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"8.49,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Utrecht",
    "handgeklokt": true,
    "naam": "Paul Verra"
  },
  {
    "geslacht": "Mannen",
    "datum": "06-05-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"63,12\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Arnhem",
    "naam": "Cees van der Rhee"
  },
  {
    "geslacht": "Mannen",
    "datum": "06-06-1976",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,20\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Dortmund (D)",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "06-07-1941",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.06,3\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Rotterdam",
    "handgeklokt": true,
    "naam": "Han de Roode"
  },
  {
    "geslacht": "Mannen",
    "datum": "06-07-1952",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,80\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Velsen",
    "naam": "Ad van Zweeden"
  },
  {
    "geslacht": "Mannen",
    "datum": "06-07-1976",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.52,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Brussel (B)",
    "handgeklokt": true,
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "06-07-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"57,68\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/25285/400m/#144350_48",
    "plaats": "Hilversum",
    "naam": "Albertine Vermeer"
  },
  {
    "geslacht": "Mannen",
    "datum": "06-08-1977",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.18,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Alphen a/d Rijn",
    "handgeklokt": true,
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "06-08-1978",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.42.51,4\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "20 km snelwandelen",
    "plaats": "Groningen (baan)",
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "06-09-1925",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"38,42\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Haarlem",
    "naam": "Gerrit Eijsker"
  },
  {
    "geslacht": "Mannen",
    "datum": "06-09-1959",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"56,90\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Vlaardingen",
    "naam": "Jeltjo Doornbosch"
  },
  {
    "geslacht": "Mannen",
    "datum": "06-09-1964",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"8.02,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 800 m",
    "plaats": "Enschede",
    "handgeklokt": true,
    "naam": "G. Kamp, P. Klos, A. Bosch, C. Klaver"
  },
  {
    "geslacht": "Mannen",
    "datum": "06-09-1972",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"48,7\""
    },
    "naam": "Bert de Jager"
  },
  {
    "geslacht": "Mannen",
    "datum": "06-09-1981",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5.29,3\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "2 000 m",
    "plaats": "Utrecht",
    "handgeklokt": true,
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "06-09-1992",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.16,1\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Huizen",
    "naam": "Marianne v.d. Linde"
  },
  {
    "geslacht": "Mannen",
    "datum": "06-10-1979",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.17,3\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 400 m",
    "plaats": "Leiden",
    "handgeklokt": true,
    "naam": "H. Macnack, M. Westbroek, J.W. v.d. Wal, M. Bienfait"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "06-10-1991",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"42,20\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Haarlem",
    "naam": "Toos Stoete"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "Haarlemse clubkampioenschappen 2023",
    "handgeklokt": false,
    "datum": "06-10-2023",
    "locatie": "outdoor",
    "prestatie": {
      "code": "13.27,01"
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "3 000 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagen/39816/356368/#losonderdeel_62_",
    "plaats": "Haarlem",
    "naam": "Liesbeth Hannema (c)"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "07-01-1989",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,24\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "50 m",
    "plaats": "Eelde",
    "naam": "Oscar Soethout"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "07-03-1970",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2.02,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Groningen",
    "naam": "Ron Koridon"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-03-1970",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,10\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Groningen",
    "naam": "Rob Pauel"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-05-1967",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.58,3\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "Arnoud Bosch"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-05-1972",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,00\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Amsterdam",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-05-1977",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,21\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Milaan (I)",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-05-1978",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"9.02,4\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m steeplechase",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-05-1983",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"7,34\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Lisse",
    "naam": "Jan Willem v.d. Wal"
  },
  {
    "geslacht": "Mannen",
    "evenement": "Ter Specke Bokaal",
    "handgeklokt": false,
    "datum": "07-05-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.23,48\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 000 m",
    "link": "https://www.atletiek.nu/wedstrijd/startlijst/460731/9/",
    "plaats": "Lisse",
    "naam": "Jurgen Wielart"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": " Ter Specke Bokaal",
    "handgeklokt": false,
    "datum": "07-05-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"18,12\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "150 m",
    "link": "https://www.atletiek.nu/wedstrijd/startlijst/612075/29/",
    "plaats": "Lisse",
    "naam": "Femke Nuijten"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-06-1925",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 100 m",
    "plaats": "Essen (D)",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"44,0\""
    },
    "naam": "J. Boot, T. v. Welsenes, Verhagen, F. Lamp"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-06-1925",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"16.42,9\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Rotterdam",
    "handgeklokt": true,
    "naam": "Arie Klaase"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-06-1931",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"18.08,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 1 500 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "J. v.d. Booren, J. Buis, J. Brandenburg, W. Effern"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-06-1931",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13,37\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Haarlem",
    "naam": "Gerrit Eijsker"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-06-1942",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.06,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Zweedse estafette",
    "plaats": "Den Haag",
    "handgeklokt": true,
    "naam": "H. de Roode, C. Stolwijk, H. Schreuder, C. v.d. Kolk"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-07-1932",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"17.41,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 1 500 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "J. Brandenburg, J. v.d. Booren, W. Effern, J. Buis"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-07-1965",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.02,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Zweedse estafette",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "A. Schaper, J. Vissers, G. Kamp, A. v.d. Voort"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "07-07-2002",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.44,08\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "600 m",
    "plaats": "Barendrecht",
    "naam": "Jirina van Kesteren"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-08-1927",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"22,2\""
    },
    "naam": "Rinus v/d Berge"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-08-1927",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.28,4\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 400 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "R. v.d. Berge, G. Eijsker, A. Hoogerwerf, A. Paulen"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-08-1930",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,86\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Hilversum",
    "naam": "Anne van der Zee"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-08-1955",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"32.05,4\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 000 m",
    "plaats": "Rotterdam",
    "handgeklokt": true,
    "naam": "Paul Verra"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-08-1966",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "110 m horden",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"53,8\""
    },
    "naam": "Pim van Beek"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "07-08-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"25,76\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/33086/200m/#185958_",
    "plaats": "Utrecht",
    "naam": "Femke Nuijten"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-09-1924",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"17.32,1\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "Ge Hillebrand"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-09-1924",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"7,085\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Amsterdam",
    "naam": "Jaap Boot"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-09-1934",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "110 m horden",
    "plaats": "Turijn (I)",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"14,9\""
    },
    "naam": "Wim Kaan"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-10-1978",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"30.46,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 000 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-10-1978",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"49.13,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 km snelwandelen",
    "plaats": "Drachten (baan)",
    "handgeklokt": true,
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-10-1979",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.26,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 200 m",
    "plaats": "Leiden",
    "handgeklokt": true,
    "naam": "H. Macnack, M. Westbroek, J.W. v.d. Wal, M. Bienfait"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "07-11-1992",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"7,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "50 m",
    "plaats": "Amsterdam",
    "naam": "Marjanne v. Sambeek"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "08-01-1984",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1.56,4\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Dortmund (D)",
    "naam": "Han Baauw"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "08-03-1981",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"21,9\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "plaats": "Dortmund (D)",
    "naam": "Mike Bienfait"
  },
  {
    "geslacht": "Mannen",
    "datum": "08-03-1981",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"7,05\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Dortmund (D)",
    "naam": "Jan Willem v.d. Wal"
  },
  {
    "geslacht": "Mannen",
    "datum": "08-05-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"62,08\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Santpoort",
    "naam": "Niels Terol"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "08-05-2004",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.11,24\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m horden",
    "plaats": "Veenendaal",
    "naam": "Jirina van Kesteren"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "08-05-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"37.06\""
    },
    "einddatum": "",
    "categorie": "Junioren B",
    "onderdeel": "Speerwerpen",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/35397/Speer/#230860_39",
    "plaats": "Haarlem",
    "naam": "Babs Koeman"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "08-05-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5.51\""
    },
    "einddatum": "",
    "categorie": "Junioren B",
    "onderdeel": "Verspringen",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/35397/Ver/#230860_1",
    "plaats": "Haarlem",
    "naam": "Babs Koeman"
  },
  {
    "geslacht": "Mannen",
    "datum": "08-06-1924",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"11,11\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Assendelft",
    "naam": "Gerrit Eijsker"
  },
  {
    "geslacht": "Mannen",
    "datum": "08-06-1980",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Leiden",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"47,4\""
    },
    "naam": "Mario Westbroek"
  },
  {
    "geslacht": "Mannen",
    "datum": "08-06-1980",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"64,04\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Leiden",
    "naam": "Cees van der Rhee"
  },
  {
    "geslacht": "Mannen",
    "datum": "08-06-1983",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14,95\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Groningen",
    "naam": "Aafbrecht v.d. Veen"
  },
  {
    "geslacht": "Mannen",
    "datum": "08-06-1986",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15,69\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Breda",
    "naam": "Aafbrecht v.d. Veen"
  },
  {
    "geslacht": "Mannen",
    "datum": "08-07-1956",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,80\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Amsterdam",
    "naam": "Ton van Zijl"
  },
  {
    "geslacht": "Mannen",
    "datum": "08-07-1977",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14,15\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Sittard",
    "naam": "Jan van Dijk"
  },
  {
    "geslacht": "Mannen",
    "datum": "08-07-1981",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.50,9\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Arnhem",
    "handgeklokt": true,
    "naam": "Stijn Jaspers"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "08-07-2007",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5.42\""
    },
    "einddatum": "",
    "categorie": "Junioren B",
    "onderdeel": "Verspringen",
    "plaats": "Utrecht",
    "naam": "Tara van Schie"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "08-07-2007",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5.02,39\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Utrecht",
    "naam": "Larissa Scholten"
  },
  {
    "geslacht": "Mannen",
    "datum": "08-08-1926",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.58,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "Ad Paulen"
  },
  {
    "geslacht": "Mannen",
    "datum": "08-08-1971",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,95\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Uden",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "08-09-1957",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14.59,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Rijswijk",
    "handgeklokt": true,
    "naam": "Paul Verra"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "08-09-1991",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.47,7\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "600 m",
    "plaats": "Heerhugowaard",
    "naam": "Joanna Andrews"
  },
  {
    "geslacht": "Mannen",
    "datum": "08-11-1975",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2,12\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Keulen (D)",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "09-01-1977",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"4,00\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Dortmund (D)",
    "naam": "Charles Mathôt"
  },
  {
    "geslacht": "Mannen",
    "datum": "09-05-1976",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.00.03,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 km snelwandelen",
    "plaats": "Schoonhoven (baan)",
    "handgeklokt": true,
    "naam": "Frank van Ravensburg"
  },
  {
    "geslacht": "Mannen",
    "datum": "09-06-1979",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"48,34\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Haarlem",
    "naam": "Mario Westbroek"
  },
  {
    "geslacht": "Mannen",
    "datum": "09-07-1978",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"49.29,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 km snelwandelen",
    "plaats": "Zaandam (baan)",
    "handgeklokt": true,
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "09-07-1982",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Den Haag",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"47,0\""
    },
    "naam": "Mario Westbroek"
  },
  {
    "geslacht": "Mannen",
    "datum": "09-08-1925",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,40\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Arnhem",
    "naam": "Piet v.d. Klinkenberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "09-08-1977",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14.52,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Brussel (B)",
    "handgeklokt": true,
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "09-08-1981",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.37.30,4\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "20 km snelwandelen",
    "plaats": "Utrecht (baan)",
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "09-09-1928",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.17,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Nijmegen",
    "handgeklokt": true,
    "naam": "Arie Klaase"
  },
  {
    "geslacht": "Mannen",
    "datum": "09-09-1928",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,085\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Nijmegen",
    "naam": "Gerrit Eijsker"
  },
  {
    "geslacht": "Mannen",
    "datum": "09-09-1928",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,605\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Nijmegen",
    "naam": "Anne van der Zee"
  },
  {
    "geslacht": "Mannen",
    "datum": "09-09-1978",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.11,1\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Sittard",
    "handgeklokt": true,
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "09-09-1981",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.50,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "Stijn Jaspers"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "09-09-1988",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"9.43,9\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Amsterdam",
    "naam": "Marianne v.d. Linde"
  },
  {
    "geslacht": "Mannen",
    "datum": "09-09-1990",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"61,72\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Hengelo",
    "naam": "Rob Buntsma"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "09-09-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,60\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/24816/Polshoog/#134520_49",
    "plaats": "Amersfoort",
    "naam": "Mette Elgersma (B)"
  },
  {
    "geslacht": "Mannen",
    "datum": "09-10-1971",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"40,00\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelslingeren",
    "plaats": "Overveen",
    "naam": "Ben Rebel"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "09-12-2018",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2,50\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/25116/Polshoog/#141837_49",
    "plaats": "Assendelft",
    "naam": "Mette Elgersma (B)"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "10-01-1971",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "50 m",
    "plaats": "Amsterdam",
    "naam": "Jim Evelein"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "10-02-1973",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m",
    "plaats": "Leiden",
    "naam": "Bert de Jager"
  },
  {
    "geslacht": "Mannen",
    "datum": "10-02-1973",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2,05\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Leiden",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "10-02-1973",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"9,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m horden",
    "plaats": "Leiden",
    "naam": "Anja Smits (A)"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "10-02-1973",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"5,56\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Leiden",
    "naam": "Anja Smits (A)"
  },
  {
    "geslacht": "Mannen",
    "datum": "10-05-1925",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 100 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"45,2\""
    },
    "naam": "J. Boot, T. v. Welsenes, Verhagen, F. Lamp"
  },
  {
    "geslacht": "Mannen",
    "datum": "10-05-1959",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,815\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Leiden",
    "naam": "Ton van Zijl"
  },
  {
    "geslacht": "Mannen",
    "datum": "10-05-1959",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,815\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Leiden",
    "naam": "Harry Joacim"
  },
  {
    "geslacht": "Mannen",
    "datum": "10-05-1981",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"47,12\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Haarlem",
    "naam": "Les Brown"
  },
  {
    "geslacht": "Mannen",
    "datum": "10-06-1979",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"46.52,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 km snelwandelen",
    "plaats": "Cadenezzo (CH) (weg)",
    "handgeklokt": true,
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "10-06-1979",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.23,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 400 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "P. Rezelman, K. Macnack, M. Westbroek, R. Sim"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "Gouden Spike 2023",
    "handgeklokt": false,
    "datum": "10-06-2023",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"24,55\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagen/39291/347296/#losonderdeel_52_",
    "plaats": "Leiden",
    "naam": "Femke Nuijten"
  },
  {
    "geslacht": "Mannen",
    "datum": "10-07-1927",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"11,75\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Den Haag",
    "naam": "Gerrit Eijsker"
  },
  {
    "geslacht": "Mannen",
    "datum": "10-07-1927",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,40\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Den Haag",
    "naam": "Anne van der Zee"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "10-07-1988",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"31,12\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Hilversum",
    "naam": "Renée Ferment"
  },
  {
    "geslacht": "Mannen",
    "datum": "10-07-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.46,12\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/34718/800m/",
    "plaats": "Kortrijk (B)",
    "naam": "Jurgen Wielart"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "10-07-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.07,18\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/34718/800m/#236679_6",
    "plaats": "Kortrijk (B)",
    "naam": "Kristen Niedrach"
  },
  {
    "geslacht": "Mannen",
    "datum": "10-08-1930",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13,01\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Zwolle",
    "naam": "Gerrit Eijsker"
  },
  {
    "geslacht": "Mannen",
    "datum": "10-08-1976",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14.52,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Den Haag",
    "handgeklokt": true,
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "10-09-1933",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13,92\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Amsterdam",
    "naam": "Gerrit Eijsker"
  },
  {
    "geslacht": "Mannen",
    "datum": "10-09-1961",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"8.11,9\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 800 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "J. Kan, J. Vissers, C. Faas, J. Kiewiet"
  },
  {
    "geslacht": "Mannen",
    "datum": "10-10-1965",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.29,9\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 000 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "Adri Schaper"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "10-10-1981",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.38.40,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "30 km snelwandelen",
    "plaats": "Leiden (baan)",
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "11-02-1979",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"51,13\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Rotterdam",
    "naam": "Kenneth Macnack"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "11-02-1984",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1.56,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Zuid-Laren",
    "naam": "Han Baauw"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "11-05-2008",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"100 m horden\",\n        \"prestatie\": \"16,68\",\n        \"punten\": \"(633)\"\n    },\n    {\n        \"onderdeel\": \"Hoogspringen\",\n        \"prestatie\": \"1.54\",\n        \"punten\": \"(666)\"\n    },\n    {\n        \"onderdeel\": \"Kogelstoten\",\n        \"prestatie\": \"9.72\",\n        \"punten\": \"(511)\"\n    },\n    {\n        \"onderdeel\": \"200 m\",\n        \"prestatie\": \"26,31\",\n        \"punten\": \"(770)\"\n    },\n    {\n        \"onderdeel\": \"Verspringen\",\n        \"prestatie\": \"5.36\",\n        \"punten\": \"(660)\"\n    },\n    {\n        \"onderdeel\": \"Speerwerpen\",\n        \"prestatie\": \"26.99\",\n        \"punten\": \"(420)\"\n    },\n    {\n        \"onderdeel\": \"800 m\",\n        \"prestatie\": \"2.46,43\",\n        \"punten\": \"(501)\"\n    },\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"4161 pnt\"\n    }\n]"
    },
    "einddatum": "12-05-2008",
    "categorie": "Junioren A",
    "onderdeel": "Zevenkamp",
    "plaats": "Sittard",
    "naam": "Tara van Schie"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "11-05-2008",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"100 m horden\",\n        \"prestatie\": \"16,68\",\n        \"punten\": \"(633)\"\n    },\n    {\n        \"onderdeel\": \"Hoogspringen\",\n        \"prestatie\": \"1.54\",\n        \"punten\": \"(666)\"\n    },\n    {\n        \"onderdeel\": \"Kogelstoten\",\n        \"prestatie\": \"9.72\",\n        \"punten\": \"(511)\"\n    },\n    {\n        \"onderdeel\": \"200 m\",\n        \"prestatie\": \"26,31\",\n        \"punten\": \"(770)\"\n    },\n    {\n        \"onderdeel\": \"Verspringen\",\n        \"prestatie\": \"5.36\",\n        \"punten\": \"(660)\"\n    },\n    {\n        \"onderdeel\": \"Speerwerpen\",\n        \"prestatie\": \"26.99\",\n        \"punten\": \"(420)\"\n    },\n    {\n        \"onderdeel\": \"800 m\",\n        \"prestatie\": \"2.46,42\",\n        \"punten\": \"(501)\"\n    },\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"4161 pnt\"\n    }\n]\n"
    },
    "einddatum": "12-05-2008",
    "categorie": "Senioren",
    "onderdeel": "Zevenkamp",
    "plaats": "Sittard",
    "naam": "Tara van Schie (A)"
  },
  {
    "geslacht": "Mannen",
    "datum": "11-06-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,09\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Kleef (D)",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "11-06-1987",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12.20,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Haarlem",
    "naam": "Sylvia de Krom"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "Gouden Spike 2022",
    "handgeklokt": false,
    "datum": "11-06-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"11,94\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/37404/100m/#279905_12",
    "plaats": "Leiden",
    "naam": "Femke Nuijten"
  },
  {
    "geslacht": "Mannen",
    "datum": "11-07-1954",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.30,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Zaandam",
    "handgeklokt": true,
    "naam": "Henk Meijboom"
  },
  {
    "geslacht": "Mannen",
    "datum": "11-07-1954",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15.23,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Den Haag",
    "handgeklokt": true,
    "naam": "Paul Verra"
  },
  {
    "geslacht": "Mannen",
    "datum": "11-07-1971",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"10,4\""
    },
    "naam": "Bert de Jager"
  },
  {
    "geslacht": "Mannen",
    "datum": "11-07-1971",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"9.32,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m steeplechase",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "Hans de Rijk"
  },
  {
    "geslacht": "Mannen",
    "datum": "11-08-1946",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.06,1\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Rotterdam",
    "handgeklokt": true,
    "naam": "Wim van Gog"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "11-08-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m horden",
    "plaats": "Arnhem",
    "naam": "Anja Smits"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "11-08-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "plaats": "Arnhem",
    "naam": "Anja Smits"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "11-09-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,68\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "Hoogspringen",
    "link": "https://www.atletiek.nu/wedstrijd/startlijst/420255/218/",
    "plaats": "Amersfoort",
    "naam": "Babs Koeman (B)"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "11-09-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,68\""
    },
    "einddatum": "",
    "categorie": "Junioren B",
    "onderdeel": "Hoogspringen",
    "link": "https://www.atletiek.nu/wedstrijd/startlijst/420255/218/",
    "plaats": "Amersfoort",
    "naam": "Babs Koeman"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "11-09-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,68\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "link": "https://www.atletiek.nu/wedstrijd/startlijst/420255/218/",
    "plaats": "Amersfoort",
    "naam": "Babs Koeman (B)"
  },
  {
    "geslacht": "Mannen",
    "datum": "11-10-1946",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"8.55,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Beverwijk",
    "handgeklokt": true,
    "naam": "Wim van Gog"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "11-10-1987",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.51,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 000 m",
    "plaats": "Haarlem",
    "naam": "Sylvia de Krom"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "12-04-1992",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"11,16\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Haarlem",
    "naam": "Toos Stoete"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "12-05-1991",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"41,62\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Haarlem",
    "naam": "Toos Stoete"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "12-05-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"36.39\""
    },
    "einddatum": "",
    "categorie": "Junioren B",
    "onderdeel": "Speerwerpen",
    "plaats": "Zaandam",
    "naam": "Babs Koeman (C)"
  },
  {
    "geslacht": "Mannen",
    "datum": "12-06-1932",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "110 m horden",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"15,4\""
    },
    "naam": "Wim Kaan"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "12-06-1988",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"10.00,3\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Haarlem",
    "naam": "Marianne v.d. Linde"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "12-06-1988",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"51,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 100 m",
    "plaats": "Haarlem",
    "naam": "J. Capellen, J. Sloot, C. v.d. Klei, J. Konermann"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "12-06-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"31.10\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "Speerwerpen",
    "plaats": "Alphen a/d Rijn",
    "naam": "Ingeborg Zandbergen (B)"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "12-06-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,10\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/34572/100m/#220798_12",
    "plaats": "Leiden",
    "naam": "Femke Nuijten"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "12-06-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"25,51\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/33086/200m/#185958_",
    "plaats": "Leiden",
    "naam": "Femke Nuijten"
  },
  {
    "geslacht": "Mannen",
    "datum": "12-07-1925",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"34.25,3\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 000 m",
    "plaats": "Utrecht",
    "handgeklokt": true,
    "naam": "Arie Klaase"
  },
  {
    "geslacht": "Mannen",
    "datum": "12-07-1925",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "110 m horden",
    "plaats": "Utrecht",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"17,6\""
    },
    "naam": "Arie Kaan"
  },
  {
    "geslacht": "Mannen",
    "datum": "12-07-1925",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13,55\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Utrecht",
    "naam": "Steph van Musscher"
  },
  {
    "geslacht": "Mannen",
    "datum": "12-07-1925",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"32,92\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelslingeren",
    "plaats": "Utrecht",
    "naam": "Jan Moolenaars"
  },
  {
    "geslacht": "Mannen",
    "datum": "12-07-1983",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15,29\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Hengelo",
    "naam": "Aafbrecht v.d. Veen"
  },
  {
    "geslacht": "Mannen",
    "datum": "12-09-1943",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.05,7\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Zweedse estafette",
    "plaats": "Hilversum",
    "handgeklokt": true,
    "naam": "C. Dekker, R. Bastin, J.W. Wagenaar, J. v.d. Waa"
  },
  {
    "geslacht": "Mannen",
    "datum": "12-09-1965",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.24,9\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 400 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "P. v. Beek, J. Vissers, W. Aaij, A. Schaper"
  },
  {
    "geslacht": "Mannen",
    "datum": "12-10-1980",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.27,1\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 000 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "Stijn Jaspers"
  },
  {
    "geslacht": "Mannen",
    "datum": "13-02-1977",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2,18\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Keulen (D)",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "13-02-1983",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"3.58,73\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Rotterdam",
    "naam": "Paul Jaspers"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "13-02-1983",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"48,53\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Rotterdam",
    "naam": "Allen Ellsworth"
  },
  {
    "geslacht": "Mannen",
    "datum": "13-02-1983",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"13,75\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Zwolle",
    "naam": "Les Brown"
  },
  {
    "geslacht": "Mannen",
    "datum": "13-03-1977",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2,22\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "San Sebastián (E)",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "13-04-1989",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.13,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 000 m",
    "plaats": "Haarlem",
    "naam": "Marianne v.d. Linde"
  },
  {
    "geslacht": "Mannen",
    "datum": "13-05-1956",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"8.34,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Rotterdam",
    "handgeklokt": true,
    "naam": "Paul Verra"
  },
  {
    "geslacht": "Mannen",
    "datum": "13-05-1978",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"9.00,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m steeplechase",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "13-05-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,50\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/17042/Polshoog/",
    "plaats": "Edam",
    "naam": "Mette Elgersma (B)"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "13-05-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,12\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/34572/100m/#220798_12",
    "plaats": "Vught",
    "naam": "Femke Nuijten"
  },
  {
    "geslacht": "Mannen",
    "datum": "13-06-1976",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"59.18,4\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 km snelwandelen",
    "plaats": "Vlaardingen (baan)",
    "handgeklokt": true,
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "13-06-1997",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"64,08\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Haarlem",
    "naam": "Niels Terol"
  },
  {
    "geslacht": "Mannen",
    "datum": "13-07-1929",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"7,195\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Amsterdam",
    "naam": "Toon van Welsenes"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "13-07-1980",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.38.52,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "20 km snelwandelen",
    "plaats": "Yverdon (CH) (weg)",
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "13-07-1982",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5.08,09\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "2 000 m",
    "plaats": "Ansberg (D)",
    "naam": "Stijn Jaspers"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "13-08-2003",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5.25,81\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Haarlem",
    "naam": "Jirina van Kesteren"
  },
  {
    "geslacht": "Mannen",
    "datum": "13-09-1980",
    "locatie": "outdoor",
    "prestatie": {
      "code": " \"16.12,45\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 1 500 m",
    "plaats": "Den Haag",
    "naam": "R. Nieuwenhuizen, P. Fris, S. Jaspers, E. v. Ravensberg"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "13-09-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15,05\""
    },
    "einddatum": "",
    "categorie": "Junioren B",
    "onderdeel": "100 m horden",
    "plaats": "Amersfoort",
    "naam": "Babs Koeman"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "13-12-1980",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "50 m",
    "plaats": "Utrecht",
    "naam": "Luke Prins"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "13-12-1980",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "50 m",
    "plaats": "Utrecht",
    "naam": "Jan Willem v.d. Wal"
  },
  {
    "geslacht": "Mannen",
    "datum": "13-12-1980",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,66\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Utrecht",
    "naam": "Jan Willem v.d. Wal"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "14-01-1978",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "50 m",
    "plaats": "Groningen",
    "naam": "Mario Westbroek"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "14-01-1983",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"8.04,1?\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Tennessee (VS)",
    "naam": "Stijn Jaspers"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": true,
    "datum": "14-04-1957",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"53.49,0\""
    },
    "einddatum": "",
    "categorie": "Wegatletiek",
    "onderdeel": "15 km",
    "plaats": "Den Haag",
    "naam": "Bas Fennis"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "14-04-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "plaats": "Haarlem",
    "naam": "Marga van Dijk"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "14-04-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.25,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "600 m",
    "plaats": "Haarlem",
    "naam": "Karin de Jong"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "14-04-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.11,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Haarlem",
    "naam": "Tineke Sanders"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "14-04-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"6,43\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Haarlem",
    "naam": "Willie Sanders"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "14-04-1991",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,4\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "plaats": "Haarlem",
    "naam": "Brenda Stoete"
  },
  {
    "geslacht": "Mannen",
    "datum": "14-05-1961",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"42,85\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Den Haag",
    "naam": "Rein Knol"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "14-05-2004",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.11,10\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m horden",
    "plaats": "Amsterdam",
    "naam": "Jirina van Kesteren"
  },
  {
    "geslacht": "Mannen",
    "datum": "14-06-1925",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13,045\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Haarlem",
    "naam": "Steph van Musscher"
  },
  {
    "geslacht": "Mannen",
    "datum": "14-06-1925",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"41,78\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Haarlem",
    "naam": "Frits Lamp"
  },
  {
    "geslacht": "Mannen",
    "datum": "14-06-1931",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.31,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 200 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "A. Paulen, R. v.d. Berge, D. Benz, C. Berger"
  },
  {
    "geslacht": "Mannen",
    "datum": "14-06-1981",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14,99\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "110 m horden",
    "plaats": "Den Haag",
    "naam": "Luke Prins"
  },
  {
    "geslacht": "Mannen",
    "datum": "14-06-1981",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"64,40\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Den Haag",
    "naam": "Cees van der Rhee"
  },
  {
    "geslacht": "Mannen",
    "datum": "14-06-1986",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"47,54\""
    },
    "einddatum": "",
    "categorie": "Junioren C",
    "onderdeel": "4 x 100 m",
    "plaats": "Beverwijk",
    "naam": "Patrick Been, Marvin Isselt, Edward Leter, Tony v/d Prijt"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "14-07-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.08,93\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/16788/800m/#106011_6",
    "plaats": "Grootebroek",
    "naam": "Kristen Niedrach"
  },
  {
    "geslacht": "Mannen",
    "datum": "14-08-1921",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,30\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Amsterdam",
    "naam": "Steph van Duin"
  },
  {
    "geslacht": "Mannen",
    "datum": "14-08-1927",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"10,6\""
    },
    "naam": "Rinus v/d Berge"
  },
  {
    "geslacht": "Mannen",
    "datum": "14-08-1932",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"42,77\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Haarlem",
    "naam": "Gerrit Eijsker"
  },
  {
    "geslacht": "Mannen",
    "datum": "14-08-1965",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.54,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Groningen",
    "handgeklokt": true,
    "naam": "Adri Schaper"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "14-08-1986",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5.26,3\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Haarlem",
    "naam": "Marianne v.d. Linde"
  },
  {
    "geslacht": "Mannen",
    "datum": "14-09-1930",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.08,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "Andries Hoogerwerf"
  },
  {
    "geslacht": "Mannen",
    "datum": "14-09-1930",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.32,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 200 m",
    "plaats": "Brussel (B)",
    "handgeklokt": true,
    "naam": "R. v.d. Berge, A. Paulen, D. Benz, C. Berger"
  },
  {
    "geslacht": "Mannen",
    "datum": "14-09-1963",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,94\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Osnabrück (D)",
    "naam": "Rein Knol"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "14-09-1974",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"22,49\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Heiloo",
    "naam": "Renée Ferment (A/B)"
  },
  {
    "geslacht": "Mannen",
    "datum": "15-01-1977",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,22\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Groningen",
    "naam": "Jan van Dijk"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "15-01-1989",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"27,1\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "plaats": "Dortmund (D)",
    "naam": "Brenda Stoete"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "15-01-2022",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2.12,20\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/36286/800m/#254753_6",
    "plaats": "Gent (B)",
    "naam": "Kristen Niedrach"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "15-03-1964",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"10.31,4\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Hoorn",
    "naam": "Krijn Kroezen"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "15-04-1974",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.43,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Haarlem",
    "naam": "Ingrid de Jager"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "15-04-1974",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"16,36\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Haarlem",
    "naam": "Marjan Zwier"
  },
  {
    "geslacht": "Mannen",
    "datum": "15-05-1977",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4,30\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Arnhem",
    "naam": "Charles Mathot"
  },
  {
    "geslacht": "Mannen",
    "datum": "15-05-1983",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"45.19,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 km snelwandelen",
    "plaats": "Arboga (S) (baan)",
    "handgeklokt": true,
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "15-05-1983",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14,71\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Amsterdam",
    "naam": "Les Brown"
  },
  {
    "geslacht": "Mannen",
    "datum": "15-06-1930",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"21,34 (21,1)\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "200 m",
    "plaats": "Amsterdam",
    "naam": "Chris Berger"
  },
  {
    "geslacht": "Mannen",
    "datum": "15-06-1930",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"21,1\""
    },
    "naam": "Chris Berger"
  },
  {
    "geslacht": "Mannen",
    "datum": "15-06-1930",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 100 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"42,6\""
    },
    "naam": "C. Berger, D. Benz, G. Rinkel, R. v.d. Berge"
  },
  {
    "geslacht": "Mannen",
    "datum": "15-06-1941",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,80\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Haarlem",
    "naam": "Cor Lamoree"
  },
  {
    "geslacht": "Mannen",
    "datum": "15-06-1975",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"9.12,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m steeplechase",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "15-06-1980",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4,81\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Amstelveen",
    "naam": "Pim Göbel"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "15-06-2005",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.08,82\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m horden",
    "plaats": "Delft",
    "naam": "Jirina van Kesteren"
  },
  {
    "geslacht": "Mannen",
    "datum": "15-07-1923",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"10,32\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Amsterdam",
    "naam": "Gerrit Eijsker"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": true,
    "datum": "15-07-1967",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.08.47,0\""
    },
    "einddatum": "",
    "categorie": "Wegatletiek",
    "onderdeel": "10 Engelse mijl",
    "plaats": "Gorinchem",
    "naam": "Garmt Zuidema"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "15-07-1989",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.50,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "600 m",
    "plaats": "Krommenie",
    "naam": "Meraud de Rooij"
  },
  {
    "geslacht": "Mannen",
    "datum": "15-08-1926",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "plaats": "Arnhem",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"10,8\""
    },
    "naam": "Frits Lamp"
  },
  {
    "geslacht": "Mannen",
    "datum": "15-08-1926",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"9.17,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Arnhem",
    "handgeklokt": true,
    "naam": "Arie Klaase"
  },
  {
    "geslacht": "Mannen",
    "datum": "15-08-1948",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.02,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "Wim van Gog"
  },
  {
    "geslacht": "Mannen",
    "datum": "15-08-1965",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.53,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Groningen",
    "handgeklokt": true,
    "naam": "Adri Schaper"
  },
  {
    "geslacht": "Mannen",
    "datum": "15-08-1987",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "110 m horden",
    "plaats": "Den Haag",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"14,6\""
    },
    "naam": "Hans van Enkhuyzen"
  },
  {
    "geslacht": "Mannen",
    "datum": "15-09-1946",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.31,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Hilversum",
    "handgeklokt": true,
    "naam": "Wim van Gog"
  },
  {
    "geslacht": "Mannen",
    "datum": "15-09-1957",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,80\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Leiden",
    "naam": "Peter Krans"
  },
  {
    "geslacht": "Mannen",
    "datum": "15-09-1985",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"53,90\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m horden",
    "plaats": "Roosendaal",
    "handtijd": "",
    "naam": "Henk Kraaijenhof"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "15-10-1972",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4,00\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Haarlem",
    "naam": "Tineke Sanders"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "16-02-1969",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"9.20,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Haarlem",
    "naam": "Hans de Rijk"
  },
  {
    "geslacht": "Mannen",
    "datum": "16-02-1974",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"12,95\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Arnhem",
    "naam": "Robbert van Schendel"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "16-02-1980",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,88\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m",
    "plaats": "Rotterdam",
    "naam": "Sammy Monsels"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "16-02-1991",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1.54,74\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Den Haag",
    "naam": "Jan de Ruiter"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "16-02-1991",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1.02,75\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Den Haag",
    "naam": "Chantal Bruyn"
  },
  {
    "geslacht": "Mannen",
    "datum": "16-05-1932",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"53,81\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Amsterdam",
    "naam": "Kees Visser"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "16-05-1993",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"10,56\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Amsterdam",
    "naam": "Brenda Stoete"
  },
  {
    "geslacht": "Mannen",
    "datum": "16-06-1923",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.48,7\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "Eduard van Musscher"
  },
  {
    "geslacht": "Mannen",
    "datum": "16-06-1923",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"23,7\""
    },
    "naam": "Frits Lamp"
  },
  {
    "geslacht": "Mannen",
    "datum": "16-06-1968",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.50,9\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Leiden",
    "handgeklokt": true,
    "naam": "Adri Schaper"
  },
  {
    "geslacht": "Mannen",
    "datum": "16-06-1973",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Arnhem",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"48,6\""
    },
    "naam": "Bert de Jager"
  },
  {
    "geslacht": "Mannen",
    "datum": "16-06-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,11\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Wilhelmshafen (D)",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "16-06-1982",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"49,68\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Purmerend",
    "naam": "Les Brown"
  },
  {
    "geslacht": "Mannen",
    "datum": "16-06-1991",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"54,74\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelslingeren",
    "plaats": "Utrecht",
    "naam": "Schelto Scheltens"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "16-06-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"57,72\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/25458/400m/#147611_48",
    "plaats": "Veenendaal",
    "naam": "Albertine Vermeer"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "16-06-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"22,51\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelslingeren",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/25458/Kogelsl/",
    "plaats": "Veenendaal",
    "naam": "Bente Elgersma"
  },
  {
    "geslacht": "Mannen",
    "datum": "16-07-1933",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "110 m horden",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"15,1\""
    },
    "naam": "Wim Kaan"
  },
  {
    "geslacht": "Mannen",
    "datum": "16-07-1955",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"8.51,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Hoorn",
    "handgeklokt": true,
    "naam": "Paul Verra"
  },
  {
    "geslacht": "Mannen",
    "datum": "16-07-1969",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.24,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Lisse",
    "handgeklokt": true,
    "naam": "Rob Strik"
  },
  {
    "geslacht": "Mannen",
    "datum": "16-08-1925",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "110 m horden",
    "plaats": "Nijmegen",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"16,8\""
    },
    "naam": "Arie Kaan"
  },
  {
    "geslacht": "Mannen",
    "datum": "16-08-1931",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13,61\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Schiedam",
    "naam": "Gerrit Eijsker"
  },
  {
    "geslacht": "Mannen",
    "datum": "16-08-1964",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.27,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 400 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "G. Kamp, R. Folmer, C. Klaver, W. Krijnen"
  },
  {
    "geslacht": "Mannen",
    "datum": "16-08-1979",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"47,59\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Bydgoszcz (PL)",
    "naam": "Mario Westbroek"
  },
  {
    "geslacht": "Mannen",
    "datum": "16-09-1962",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"45,08\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Vlissingen",
    "naam": "Rein Knol"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "16-09-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"30,82\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Haarlem",
    "naam": "Anja Smits (A)"
  },
  {
    "geslacht": "Mannen",
    "datum": "16-09-1983",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.26,3\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 000 m",
    "plaats": "Leiden",
    "handgeklokt": true,
    "naam": "Paul Jaspers"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "16-11-1991",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"7,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "50 m",
    "plaats": "Amsterdam",
    "naam": "Marjanne v. Sambeek"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "17-02-1974",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"4.12,1\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Arnhem",
    "naam": "Jan van Son"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "17-02-1980",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,87\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m",
    "plaats": "Rotterdam",
    "naam": "Sammy Monsels"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "17-02-1991",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"26,61\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "plaats": "Den Haag",
    "naam": "Brenda Stoete"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-01-2022",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,66\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "Hoogspringen",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/36449/Hoog/#258288_13",
    "plaats": "Amsterdam",
    "naam": "Babs Koeman"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-04-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"61,78\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Haarlem",
    "naam": "Niels Terol"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-05-1925",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.26,7\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "Arie Klaase"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-05-1970",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"46,44\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Heiloo",
    "naam": "Rein Knol"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-05-1981",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.47,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Nijmegen",
    "handgeklokt": true,
    "naam": "Stijn Jaspers"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-05-1992",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"55,76\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelslingeren",
    "plaats": "Den Haag",
    "naam": "Schelto Scheltens"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-05-1992",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"16,77\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Den Haag",
    "naam": "Rob Bakker"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-06-1923",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"11,6\""
    },
    "naam": "Frits Lamp"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-06-1979",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"48,27\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Groningen",
    "handtijd": "",
    "naam": "Mario Westbroek"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-07-1932",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"41,89\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Hilversum",
    "naam": "Gerrit Eijsker"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-07-1932",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,875\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Hilversum",
    "naam": "Anne van der Zee"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": true,
    "datum": "17-07-1971",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"54.36,0\""
    },
    "einddatum": "",
    "categorie": "Wegatletiek",
    "onderdeel": "10 Engelse mijl",
    "plaats": "Gorinchem",
    "naam": "Hans de Rijk"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "17-07-1992",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"16.27,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Amsterdam",
    "naam": "Marianne v.d. Linde"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-07-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.45,96\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagen/35027/225228/#losonderdeel_6_",
    "plaats": "Ninove (B)",
    "naam": "Jurgen Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-08-1924",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.39,9\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "Ge Hillebrand"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-08-1924",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"23,2\""
    },
    "naam": "Frits Lamp"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-08-1924",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.11,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "Cor Saeys"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-08-1924",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,94\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Haarlem",
    "naam": "Antoon van Welsenes"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-08-1924",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"32,30\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelslingeren",
    "plaats": "Haarlem",
    "naam": "Jan Moolenaars"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-08-1924",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,30\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Haarlem",
    "naam": "Piet v.d. Klinkenberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-08-1930",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"10,74 (10,5)\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "100 m",
    "plaats": "Hilversum",
    "naam": "Chris Berger"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-08-1930",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "plaats": "Hilversum",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"10,5\""
    },
    "naam": "Chris Berger"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-08-1930",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "110 m horden",
    "plaats": "Hilversum",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"15,8\""
    },
    "naam": "Arie Kaan"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-08-1930",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"48,44\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Hilversum",
    "naam": "Kees Visser"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-08-1947",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.04,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Bazel (CH)",
    "handgeklokt": true,
    "naam": "Wim van Gog"
  },
  {
    "geslacht": "Mannen",
    "datum": "17-08-1975",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,17\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Utrecht",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "18-03-1990",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"3.34,3\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 000 m",
    "plaats": "Den Haag",
    "naam": "Joanna Andrews"
  },
  {
    "geslacht": "Mannen",
    "datum": "18-04-1960",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"57,28\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Den Haag",
    "naam": "Jeltjo Doornbosch"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": true,
    "datum": "18-05-1968",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.07.17,0\""
    },
    "einddatum": "",
    "categorie": "Wegatletiek",
    "onderdeel": "10 Engelse mijl",
    "plaats": "Scheveningen",
    "naam": "Garmt Zuidema"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "Harry Schulting Games",
    "handgeklokt": false,
    "datum": "18-05-2023",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"11,85\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "link": "https://www.atletiek.nu/wedstrijd/startlijst/837157/87/#uitslagen",
    "plaats": "Vught",
    "naam": "Femke Nuijten"
  },
  {
    "geslacht": "Mannen",
    "datum": "18-06-1939",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"9.08,4\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Rotterdam",
    "handgeklokt": true,
    "naam": "Jan Koopman"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "18-06-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"58,88\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/8256/400m/#92005_48",
    "plaats": "Amsterdam",
    "naam": "Albertine Vermeer"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "18-06-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"22,46\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelslingeren",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/8256/Kogelsl/#92005_163",
    "plaats": "Amsterdam",
    "naam": "Iris Komen"
  },
  {
    "geslacht": "Mannen",
    "datum": "18-07-1926",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 100 m",
    "plaats": "Rotterdam",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"43,9\""
    },
    "naam": "R. v.d. Berge, J. Boot, T. v. Welsenes, Verhagen"
  },
  {
    "geslacht": "Mannen",
    "datum": "18-07-1952",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14,42\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Haarlem",
    "naam": "Ad van Zweeden"
  },
  {
    "geslacht": "Mannen",
    "datum": "18-07-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.47,69\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagen/33681/200562/#losonderdeel_6_",
    "plaats": "Arnhem",
    "naam": "Jurgen Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "18-08-1965",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"16.35,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 1 500 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "A. Bosch, C. Faas, R. Folmer, A. Schaper"
  },
  {
    "geslacht": "Mannen",
    "datum": "18-08-1976",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.21,1\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "18-08-2012",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"46,65\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Kessel-Lo (B)",
    "naam": "Jurgen Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "18-09-1960",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.27,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 400 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "J. Doornbosch, P. v.d. Werff, J. Kan, C. Lagendijk"
  },
  {
    "geslacht": "Mannen",
    "datum": "18-09-1960",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,83\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Amsterdam",
    "naam": "Ton van Zijl"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": true,
    "datum": "18-10-1975",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"50.37,0\""
    },
    "einddatum": "",
    "categorie": "Wegatletiek",
    "onderdeel": "10 Engelse mijl",
    "plaats": "Amsterdam",
    "naam": "Rob ter Poorten"
  },
  {
    "geslacht": "Mannen",
    "datum": "19-02-1977",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"14,10\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Zwolle",
    "naam": "Jan van Dijk"
  },
  {
    "geslacht": "Mannen",
    "datum": "19-02-1977",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,60\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Zwolle",
    "naam": "Jan van Dijk"
  },
  {
    "geslacht": "Mannen",
    "datum": "19-02-1983",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"13,89\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Zuid-Laren",
    "naam": "Les Brown"
  },
  {
    "geslacht": "Mannen",
    "datum": "19-02-1983",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"13,87\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Zuid-Laren",
    "naam": "Nico Hellendoorn"
  },
  {
    "geslacht": "Mannen",
    "datum": "19-02-1983",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"7,17\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Zuid-Laren",
    "naam": "Jan Willem v.d. Wal"
  },
  {
    "geslacht": "Mannen",
    "datum": "19-05-1968",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"36,30\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelslingeren",
    "plaats": "Haarlem",
    "naam": "Rein Knol"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "19-05-1974",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"56,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 100 m",
    "plaats": "Heiloo",
    "naam": "..."
  },
  {
    "geslacht": "Mannen",
    "datum": "19-05-1986",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15,64\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Krommenie",
    "naam": "Aafbrecht v.d. Veen"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "19-05-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"57,74\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/16775/400m/#105399_48",
    "plaats": "Lisse",
    "naam": "Albertine Vermeer"
  },
  {
    "geslacht": "Mannen",
    "datum": "19-06-1932",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m horden",
    "plaats": "Antwerpen (B)",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"59,4\""
    },
    "naam": "Wim Kaan"
  },
  {
    "geslacht": "Mannen",
    "datum": "19-06-1932",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"41,57\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Alkmaar",
    "naam": "Gerrit Eijsker"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "19-06-1974",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"55,9\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 100 m",
    "plaats": "Amsterdam",
    "naam": "R. Ferment, W. Wollenstern, M. Keizer, I. de Jager"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "19-06-1974",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"16,64\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Amsterdam",
    "naam": "Ellen Vreenegoor (A/B)"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "19-06-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15,14\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m horden",
    "plaats": "Santpoort-Noord",
    "naam": "Brenda Stoete"
  },
  {
    "geslacht": "Mannen",
    "datum": "19-07-1981",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"8.09,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Nijmegen",
    "handgeklokt": true,
    "naam": "Stijn Jaspers"
  },
  {
    "geslacht": "Mannen",
    "datum": "19-08-1923",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.14,7\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "Jan Moolenaars"
  },
  {
    "geslacht": "Mannen",
    "datum": "19-12-1971",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,95\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Groningen",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "20-01-2019",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2,50\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/25197/Polshoog/#143264_49",
    "plaats": "Assendelft",
    "naam": "Mette Elgersma (B)"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "20-02-1971",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1.57,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Rotterdam",
    "naam": "Ron Koridon"
  },
  {
    "geslacht": "Mannen",
    "datum": "20-02-1982",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"14,49\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Rotterdam",
    "naam": "Jan Willem v.d. Wal"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "20-02-1983",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"23.06,79\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 km snelwandelen",
    "plaats": "Zuid-Laren",
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "20-03-1971",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,85\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Leiden",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "20-04-1975",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14.57,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "Rob ter Poorten"
  },
  {
    "geslacht": "Mannen",
    "datum": "20-05-1924",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.56,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 000 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "Jan Henneman"
  },
  {
    "geslacht": "Mannen",
    "datum": "20-05-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,07\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Amsterdam",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "20-05-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15,24\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "100 m horden",
    "plaats": "Arnhem",
    "naam": "Anja Smits"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "20-05-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,65\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "Hoogspringen",
    "plaats": "Arnhem",
    "naam": "Anja Smits"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "20-05-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m horden",
    "plaats": "Arnhem",
    "naam": "Anja Smits"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "20-05-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"25,9\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "plaats": "Arnhem",
    "naam": "Anja Smits"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "20-05-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,65\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Arnhem",
    "naam": "Anja Smits (A)"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "20-05-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"10,50\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Arnhem",
    "naam": "Anja Smits"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "20-05-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5,73\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Arnhem",
    "naam": "Anja Smits"
  },
  {
    "geslacht": "Mannen",
    "datum": "20-05-1974",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,14\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Bonn (D)",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "20-05-1990",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"59,4\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Amsterdam",
    "naam": "Carla van der Klei"
  },
  {
    "geslacht": "Mannen",
    "datum": "20-06-1926",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13,56\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Alkmaar",
    "naam": "Steph van Musscher"
  },
  {
    "geslacht": "Mannen",
    "datum": "20-06-1931",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13,42\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Middelburg",
    "naam": "Gerrit Eijsker"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "20-06-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "plaats": "Haarlem",
    "naam": "Renée Ferment"
  },
  {
    "geslacht": "Mannen",
    "datum": "20-06-1978",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.49,4\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Den Haag",
    "handgeklokt": true,
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "20-06-1981",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.36.16,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "20 km snelwandelen",
    "plaats": "Sønder Omme (DK) (weg)",
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "20-06-1982",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14,63\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Haarlem",
    "naam": "Jan Willem v.d. Wal"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "20-06-1993",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"6,09\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Hengelo",
    "naam": "Mieke van der Kolk"
  },
  {
    "geslacht": "Mannen",
    "datum": "20-07-1929",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"7,27\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Haarlem",
    "naam": "Toon van Welsenes"
  },
  {
    "geslacht": "Mannen",
    "datum": "20-07-1941",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"17.02,3\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 1 500 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "W. v. Gog, D. Hagtingius, J. Koopman, H. de Roode"
  },
  {
    "geslacht": "Mannen",
    "datum": "20-07-1946",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,91\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Londen (VK)",
    "naam": "Cor Lamoree"
  },
  {
    "geslacht": "Mannen",
    "datum": "20-07-1976",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"8.16,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Brussel (B)",
    "handgeklokt": true,
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "20-08-1922",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"30,42\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Womerveer",
    "naam": "Arie Kaan"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "20-12-1991",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,13\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "50 m",
    "plaats": "Den Haag",
    "naam": "Guus Groskamp"
  },
  {
    "geslacht": "Mannen",
    "datum": "21-01-1978",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2,23\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Madrid (E)",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "21-01-1978",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"4,30\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Groningen",
    "naam": "Charles Mathôt"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "21-01-1990",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1.50,1\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "600 m",
    "plaats": "Den Haag",
    "naam": "Marjan Witteman"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "21-01-1990",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,45\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Den Haag",
    "naam": "Wendy Haan"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "21-02-1971",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"8,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m horden",
    "plaats": "Rotterdam",
    "naam": "Jos Fielmich"
  },
  {
    "geslacht": "Mannen",
    "datum": "21-02-1971",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,80\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Rotterdam",
    "naam": "Jos Fielmich"
  },
  {
    "geslacht": "Mannen",
    "datum": "21-02-1982",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"13,27\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Rotterdam",
    "naam": "Les Brown"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "21-02-1988",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"9.34,38\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Den Haag",
    "naam": "Marianne v/d Linde"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "21-03-1971",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"52,1\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Leiden",
    "naam": "Mark Beekes"
  },
  {
    "geslacht": "Mannen",
    "datum": "21-05-1972",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,03\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Kleef (D)",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "21-05-1980",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"21,1\""
    },
    "naam": "Mario Westbroek"
  },
  {
    "geslacht": "Mannen",
    "datum": "21-06-1925",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13,11\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Haarlem",
    "naam": "Steph van Musscher"
  },
  {
    "geslacht": "Mannen",
    "datum": "21-06-1978",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"8.52,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m steeplechase",
    "plaats": "Roosendaal",
    "handgeklokt": true,
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "21-06-1981",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"47,44\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Amstelveen",
    "naam": "Les Brown"
  },
  {
    "geslacht": "Mannen",
    "datum": "21-06-1987",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"54,56\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Utrecht",
    "naam": "Cees van der Rhee"
  },
  {
    "geslacht": "Mannen",
    "datum": "21-06-1987",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"7,36\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Utrecht",
    "naam": "Hein Macnack"
  },
  {
    "geslacht": "Mannen",
    "datum": "21-07-1935",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "110 m horden",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"14,8\""
    },
    "naam": "Wim Kaan"
  },
  {
    "geslacht": "Mannen",
    "datum": "21-07-1978",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14.23,3\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Sittard",
    "handgeklokt": true,
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "21-08-1966",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 100 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"42,5\""
    },
    "naam": "F. Lamie, H. Vlam, C. v.d. Meulen, G. Kamp"
  },
  {
    "geslacht": "Mannen",
    "datum": "21-08-1981",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.44,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Den Haag",
    "handgeklokt": true,
    "naam": "Stijn Jaspers"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "21-10-1978",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.54.36,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "30 km snelwandelen",
    "plaats": "Haarlem (baan)",
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "21-10-1978",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5.22.57,9\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "50 km snelwandelen",
    "plaats": "Haarlem (baan)",
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "21-12-1980",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"4,60\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Beverwijk",
    "naam": "Pim Göbel"
  },
  {
    "geslacht": "Mannen",
    "datum": "22-01-1972",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2,00\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Utrecht",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "22-01-1983",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"13,73\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Zwolle",
    "naam": "Nico Hellendoorn"
  },
  {
    "geslacht": "Mannen",
    "datum": "22-03-1924",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"10.44,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "Jan Henneman"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "22-03-1964",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"4.35,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Amsterdam",
    "naam": "Peter Klos"
  },
  {
    "geslacht": "Mannen",
    "datum": "22-03-1964",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,73\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Amsterdam",
    "naam": "Rein Knol"
  },
  {
    "geslacht": "Mannen",
    "datum": "22-03-1964",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,73\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Amsterdam",
    "naam": "Ton van Zijl"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "22-04-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"57,87\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/16952/400m/#111509_48",
    "plaats": "Gouda",
    "naam": "Albertine Vermeer"
  },
  {
    "geslacht": "Mannen",
    "datum": "22-05-1921",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,20\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Den Haag",
    "naam": "Steph van Duin"
  },
  {
    "geslacht": "Mannen",
    "datum": "22-05-1921",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,20\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Den Haag",
    "naam": "Coen Hoppe"
  },
  {
    "geslacht": "Mannen",
    "datum": "22-05-1981",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"21,19\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "plaats": "Leiden",
    "naam": "Sammy Monsels"
  },
  {
    "geslacht": "Mannen",
    "evenement": " Competitie 2e Divisie Poule 3 R2 [2022]",
    "handgeklokt": false,
    "datum": "22-05-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.00,00\""
    },
    "einddatum": "",
    "categorie": "Junioren B",
    "onderdeel": "400 m horden",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/37008/400mH/",
    "plaats": "Alphen aan de Rijn",
    "naam": "Derk Hoefman"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": " Competitie 2e Divisie Poule 3 R2 [2022]",
    "handgeklokt": false,
    "datum": "22-05-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"24,81\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/37008/200m/#263193_52",
    "plaats": "Alphen a/d Rijn",
    "naam": "Femke Nuijten"
  },
  {
    "geslacht": "Mannen",
    "datum": "22-06-1924",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.44,7\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "Jan Henneman"
  },
  {
    "geslacht": "Mannen",
    "datum": "22-06-1966",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"9.44,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m steeplechase",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "Arnoud Bosch"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "22-06-2002",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.10,64\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 000 m",
    "plaats": "Utrecht",
    "naam": "Larissa Scholten"
  },
  {
    "geslacht": "Mannen",
    "datum": "22-07-1923",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 100 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"45,9\""
    },
    "naam": "van Duyn, A. Brouwer, R. Verwers, F. Lamp"
  },
  {
    "geslacht": "Mannen",
    "datum": "22-07-1923",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"27,705\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelslingeren",
    "plaats": "?",
    "naam": "Jan Moolenaars"
  },
  {
    "geslacht": "Mannen",
    "datum": "22-07-1975",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,16\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Kerkrade",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "22-07-1981",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "plaats": "Den Haag",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"20,7\""
    },
    "naam": "Sammy Monsels"
  },
  {
    "geslacht": "Mannen",
    "datum": "22-09-1963",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"8.06,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 800 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "C. Faas, M. Labeur, R. Folmer, A. Schaper"
  },
  {
    "geslacht": "Mannen",
    "datum": "23-01-1983",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"7,14\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Zwolle",
    "naam": "Jan Willem v.d. Wal"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "23-01-1993",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,12\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "50 m",
    "plaats": "Zwolle",
    "naam": "Oscar Soethout"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "23-04-1988",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.36,9\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 000 m",
    "plaats": "Haarlem",
    "naam": "Berja Koning"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "23-04-1988",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.56,7\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "600 m",
    "plaats": "Haarlem",
    "naam": "Aneska de Windt"
  },
  {
    "geslacht": "Mannen",
    "datum": "23-05-1954",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15.27,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Vlaardingen",
    "handgeklokt": true,
    "naam": "Paul Verra"
  },
  {
    "geslacht": "Mannen",
    "datum": "23-05-1968",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.56,9\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "Adri Schaper"
  },
  {
    "geslacht": "Mannen",
    "datum": "23-05-1978",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"8.59,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m steeplechase",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "23-06-1946",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,90\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Brussel (B)",
    "naam": "Cor Lamoree"
  },
  {
    "geslacht": "Mannen",
    "datum": "23-06-1968",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.52,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Rotterdam",
    "handgeklokt": true,
    "naam": "Adri Schaper"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "23-06-1974",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"41,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "300 m",
    "plaats": "Vught",
    "naam": "Ingrid de Jager"
  },
  {
    "geslacht": "Mannen",
    "datum": "23-06-1985",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"43,06\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelslingeren",
    "plaats": "Amsterdam",
    "naam": "Gosse van de Velde"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "23-06-1988",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"36,66\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Santpoort-Noord",
    "naam": "Marlies Koopman"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "23-06-1989",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"25,7\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "plaats": "Santpoort-Noord",
    "naam": "Brenda Stoete"
  },
  {
    "geslacht": "Mannen",
    "datum": "23-07-1985",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"43,54\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelslingeren",
    "plaats": "Amsterdam",
    "naam": "Gosse van de Velde"
  },
  {
    "geslacht": "Mannen",
    "datum": "23-08-1929",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.56,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "Andries Hoogerwerf"
  },
  {
    "geslacht": "Mannen",
    "datum": "23-08-1953",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15.30,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "Paul Verra"
  },
  {
    "geslacht": "Mannen",
    "datum": "23-08-1977",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14.45,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Roosendaal",
    "handgeklokt": true,
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "23-09-1962",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.00,4\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Den Haag",
    "handgeklokt": true,
    "naam": "Co Faas"
  },
  {
    "geslacht": "Mannen",
    "datum": "24-02-1973",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2,11\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Leiden",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "24-02-1973",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"8,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m",
    "plaats": "Leiden",
    "naam": "Anja Smits"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "24-02-1973",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"5,56\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Leiden",
    "naam": "Anja Smits (A)"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "Belgisch Studenten Kampioenschap",
    "handgeklokt": false,
    "datum": "24-02-2023",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"07,63\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m",
    "link": "https://www.atletiek.nu/atleet/main/1556759/",
    "plaats": "Gent",
    "naam": "Femke Nuijten"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "24-03-1968",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2.04,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Haarlem",
    "naam": "Rob Strik"
  },
  {
    "geslacht": "Mannen",
    "datum": "24-03-1968",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,75\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Haarlem",
    "naam": "Jos Fielmich"
  },
  {
    "geslacht": "Mannen",
    "datum": "24-03-1968",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"3,50\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Haarlem",
    "naam": "Aad Beun"
  },
  {
    "geslacht": "Mannen",
    "datum": "24-04-1977",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4,20\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Amsterdam",
    "naam": "Charles Mathot"
  },
  {
    "geslacht": "Mannen",
    "datum": "24-06-1923",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"9,975\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Haarlem",
    "naam": "Gerrit Eijsker"
  },
  {
    "geslacht": "Mannen",
    "datum": "24-06-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,12\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Amsterdam",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "24-06-2005",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.07,75\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m horden",
    "plaats": "Stiphout",
    "naam": "Jirina van Kesteren"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "ASICS NK Atletiek 2022",
    "handgeklokt": false,
    "datum": "24-06-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"11,86\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "link": "https://www.atletiek.nu/wedstrijd/startlijst/616513/12/#uitslagen",
    "plaats": "Apeldoorn",
    "naam": "Femke Nuijten"
  },
  {
    "geslacht": "Mannen",
    "datum": "24-07-1921",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"11,225\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Haarlem",
    "naam": "Steph van Duin"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "24-07-1988",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "plaats": "Krommenie",
    "naam": "Brenda Stoete"
  },
  {
    "geslacht": "Mannen",
    "datum": "24-08-1930",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.31,4\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Rijswijk",
    "handgeklokt": true,
    "naam": "Andries Hoogerwerf"
  },
  {
    "geslacht": "Mannen",
    "datum": "24-08-1930",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"49,31\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Rijswijk",
    "naam": "Anne van der Zee"
  },
  {
    "geslacht": "Mannen",
    "datum": "24-09-1961",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"9.45,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m steeplechase",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "Co Faas"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "24-10-1976",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.50.25,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "20 km snelwandelen",
    "plaats": "Rotterdam (weg)",
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "25-01-1981",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"8.58\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m horden",
    "plaats": "Zwolle",
    "naam": "Luke Prins"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "25-01-1981",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"8,4\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m horden",
    "plaats": "Zwolle",
    "naam": "Luke Prins"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "25-02-1979",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"4.02,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Dortmund (D)",
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "25-02-1979",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"23,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "plaats": "Dortmund (D)",
    "naam": "Mario Westbroek"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "25-02-1979",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"8.50,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Dortmund (D)",
    "naam": "Pieter Fris"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "25-02-1979",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"50,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Dortmund (D)",
    "naam": "Mario Westbroek"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "25-03-1962",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"7,4\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m",
    "plaats": "Amsterdam",
    "naam": "Bob Steenbergen"
  },
  {
    "geslacht": "Mannen",
    "datum": "25-05-1924",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"10,52\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Amsterdam",
    "naam": "Gerrit Eijsker"
  },
  {
    "geslacht": "Mannen",
    "datum": "25-05-1986",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"52,10\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Amsterdam",
    "naam": "Cees van der Rhee"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "25-05-1991",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"10,42\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Amsterdam",
    "naam": "Brenda Stoete"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "25-05-2012",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.39,07\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Utrecht",
    "naam": "Larissa Scholten"
  },
  {
    "geslacht": "Mannen",
    "datum": "25-05-2017",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.48,89\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Vught",
    "naam": "Jurgen Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "25-06-1972",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,05\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Amsterdam",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "25-06-1976",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"8.29,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Dülken (D)",
    "handgeklokt": true,
    "naam": "Evert van Ravensburg"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "25-06-1989",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"50,3\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 100 m",
    "plaats": "Hoorn",
    "naam": "C. v.d. Klei, S. Klesser, J. Konermann, B. Stoete"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "25-06-2004",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.08,82\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m horden",
    "plaats": "Sittard",
    "naam": "Jirina van Kesteren"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "25-06-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,01\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/35336/100m_hf/#229603_86",
    "plaats": "Breda",
    "naam": "Femke Nuijten"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "ASICS NK Atletiek 2022",
    "handgeklokt": false,
    "datum": "25-06-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"24,61\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "link": "https://www.atletiek.nu/wedstrijd/startlijst/616516/52/",
    "plaats": "Apeldoorn",
    "naam": "Femke Nuijten"
  },
  {
    "geslacht": "Mannen",
    "datum": "25-07-1926",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Groningen",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"49,2\""
    },
    "naam": "Ad Paulen"
  },
  {
    "geslacht": "Mannen",
    "datum": "25-07-1926",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.39,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 400 m",
    "plaats": "Groningen",
    "handgeklokt": true,
    "naam": "G. Rinkel, Nooy, A. Hoogerwerf, A. Paulen"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "25-07-1993",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"11,63\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Amsterdam",
    "naam": "Mieke van der Kolk"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "25-07-1993",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,11\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Amsterdam",
    "naam": "Mieke van der Kolk"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "25-07-1993",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"11,46\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Amsterdam",
    "naam": "Mieke van der Kolk"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "25-07-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"57,35\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/31082/400m/#164788_48",
    "plaats": "Den Haag",
    "naam": "Albertine Vermeer"
  },
  {
    "geslacht": "Mannen",
    "datum": "25-08-1929",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.36,4\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 000 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "Andries Hoogerwerf"
  },
  {
    "geslacht": "Mannen",
    "datum": "25-08-1929",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.16,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "Arie Klaase"
  },
  {
    "geslacht": "Mannen",
    "datum": "25-08-1929",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"22,0\""
    },
    "naam": "Rinus v/d Berge"
  },
  {
    "geslacht": "Mannen",
    "datum": "25-08-1957",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"10.09,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m steeplechase",
    "plaats": "Rotterdam",
    "handgeklokt": true,
    "naam": "Paul Verra"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "25-08-1988",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"22.20,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Haarlem",
    "naam": "Conny Witteman"
  },
  {
    "geslacht": "Mannen",
    "datum": "25-09-1966",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 100 m",
    "plaats": "Utrecht",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"42,0\""
    },
    "naam": "F. Lamie, H. Vlam, C. v.d. Meulen, G. Kamp"
  },
  {
    "geslacht": "Mannen",
    "datum": "26-01-1974",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"12,80\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Arnhem",
    "naam": "Peter Guiking"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "26-01-1992",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"8,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m",
    "plaats": "Den Haag",
    "naam": "Brenda Stoete"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "26-01-1992",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"7,99\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m",
    "plaats": "Den Haag",
    "naam": "Brenda Stoete"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "26-02-1972",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"4.18,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Leiden",
    "naam": "Evert van Ravensburg"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": " NK Indoor Senioren 2022",
    "handgeklokt": false,
    "datum": "26-02-2022",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"7,67\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m",
    "link": "https://www.atletiek.nu/wedstrijd/startlijst/445307/92/",
    "plaats": "Apeldoorn",
    "naam": "Femke Nuijten"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": true,
    "datum": "26-04-1975",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"51.15,6\""
    },
    "einddatum": "",
    "categorie": "Wegatletiek",
    "onderdeel": "10 Engelse mijl",
    "plaats": "Edam",
    "naam": "Rob ter Poorten"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "26-04-1981",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.40.07,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "30 km snelwandelen",
    "plaats": "Schoonhoven (weg)",
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "26-05-1923",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"11,7\""
    },
    "naam": "Ab Brouwer"
  },
  {
    "geslacht": "Mannen",
    "datum": "26-05-1923",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 100 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"47,0\""
    },
    "naam": "..."
  },
  {
    "geslacht": "Mannen",
    "datum": "26-05-1923",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"30,73\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Haarlem",
    "naam": "Wim Hoppe"
  },
  {
    "geslacht": "Mannen",
    "datum": "26-05-1923",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,68\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Haarlem",
    "naam": "Rein Verwers"
  },
  {
    "geslacht": "Mannen",
    "datum": "26-05-1923",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"9,62\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Haarlem",
    "naam": "Gerrit Eijsker"
  },
  {
    "geslacht": "Mannen",
    "datum": "26-05-1982",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.41,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Fontainebleau (F)",
    "handgeklokt": true,
    "naam": "Stijn Jaspers"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "26-05-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,70\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/25453/Polshoog/#147591_49",
    "plaats": "Grootebroek",
    "naam": "Mette Elgersma (B)"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": " Harry Schulting Games",
    "handgeklokt": false,
    "datum": "26-05-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"11,96\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/37339/100m_f/#277454_87",
    "plaats": "Vught",
    "naam": "Femke Nuijten"
  },
  {
    "geslacht": "Mannen",
    "datum": "26-06-1921",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5,87\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Zaandam",
    "naam": "Marius Bley"
  },
  {
    "geslacht": "Mannen",
    "datum": "26-06-1932",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"55,30\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Amsterdam",
    "naam": "Kees Visser"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "26-06-1993",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"6,21\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Leiden",
    "naam": "Mieke van der Kolk"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "26-06-2016",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"22,45\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelslingeren",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/5670/Kogelsl/#46599_163",
    "plaats": "Heerhugowaard",
    "naam": "Iris Komen"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "26-06-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"25,27\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "link": "https://www.atletiek.nu/wedstrijd/startlijst/366742/52/&1624714217.298572#series",
    "plaats": "Breda",
    "naam": "Femke Nuijten"
  },
  {
    "geslacht": "Mannen",
    "datum": "26-07-1925",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.45,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 400 m",
    "plaats": "Nijmegen",
    "handgeklokt": true,
    "naam": "C. Saeys, v. Welsenes, G. Rinkel, G. Eysker"
  },
  {
    "geslacht": "Mannen",
    "datum": "26-07-1925",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"46,30\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Nijmegen",
    "naam": "Frits Lamp"
  },
  {
    "geslacht": "Mannen",
    "datum": "26-07-1933",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,78\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Haarlem",
    "naam": "Nees v.d. Klinkenberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "26-07-1964",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.54,7\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Rotterdam",
    "handgeklokt": true,
    "naam": "Adri Schaper"
  },
  {
    "geslacht": "Mannen",
    "datum": "26-08-1981",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.43,02\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Koblenz (D)",
    "naam": "Stijn Jaspers"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "27-01-1980",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,95\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m",
    "plaats": "Zwolle",
    "naam": "Sammy Monsels"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "27-01-1980",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,7\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m",
    "plaats": "Zwolle",
    "naam": "Sammy Monsels"
  },
  {
    "geslacht": "Mannen",
    "datum": "27-03-1966",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"13,22\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Amsterdam",
    "naam": "Henk Kluft"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "27-03-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.47,49\""
    },
    "einddatum": "",
    "categorie": "Junioren D",
    "onderdeel": "600 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagen/36999/263079/#losonderdeel_4_",
    "plaats": "Haarlem",
    "naam": "Max Jonges"
  },
  {
    "geslacht": "Mannen",
    "evenement": " Openingswedstrijd AV Haarlem/KAV Holland",
    "handgeklokt": false,
    "datum": "27-03-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.17,54\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "600 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/36999/600m/#263094_4",
    "plaats": "Haarlem",
    "naam": "Jurgen Wielart"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": " Openingswedstrijd AV Haarlem/KAV Holland",
    "handgeklokt": false,
    "datum": "27-03-2022",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.34,70\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "600 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/36999/600m/#263095_4",
    "plaats": "Haarlem",
    "naam": "Kristen Niedrach"
  },
  {
    "geslacht": "Mannen",
    "datum": "27-04-1980",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4,60\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Den Haag",
    "naam": "Pim Göbel"
  },
  {
    "geslacht": "Mannen",
    "datum": "27-05-1962",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14,43\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Beverwijk",
    "naam": "Jeltjo Doornbosch"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "27-05-1988",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"23,26\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Beverwijk",
    "naam": "Brenda Stoete"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "27-05-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,60\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/17032/Polshoog/",
    "plaats": "Alkmaar",
    "naam": "Mette Elgersma (B)"
  },
  {
    "geslacht": "Mannen",
    "datum": "27-06-1926",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"16.13,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Dordrecht",
    "handgeklokt": true,
    "naam": "Arie Klaase"
  },
  {
    "geslacht": "Mannen",
    "datum": "27-06-1943",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"8.14,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 800 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "J. Soomer, T. Wernik, W. v. Gog, H. de Roode"
  },
  {
    "geslacht": "Mannen",
    "datum": "27-07-1924",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "plaats": "Dordrecht",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"10,9\""
    },
    "naam": "Jaap Boot"
  },
  {
    "geslacht": "Mannen",
    "datum": "27-07-1924",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 100 m",
    "plaats": "Dordrecht",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"45,5\""
    },
    "naam": "F. Lamp, G. Eijsker, Stelling, J. Boot"
  },
  {
    "geslacht": "Mannen",
    "datum": "27-07-1930",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3,815\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Amsterdam",
    "naam": "Anne van der Zee"
  },
  {
    "geslacht": "Mannen",
    "datum": "27-07-1960",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,829\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Brüggen (D)",
    "naam": "Hans Sieffers"
  },
  {
    "geslacht": "Mannen",
    "evenement": "",
    "handgeklokt": true,
    "datum": "27-07-1968",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.04.41,0\""
    },
    "einddatum": "",
    "categorie": "Wegatletiek",
    "onderdeel": "10 Engelse mijl",
    "plaats": "Gorinchem",
    "naam": "Bas Fennis"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "27-07-1990",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"16.36,7\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Amsterdam",
    "naam": "Marianne v.d. Linde"
  },
  {
    "geslacht": "Mannen",
    "datum": "27-08-1944",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"9.02,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Hilversum",
    "handgeklokt": true,
    "naam": "Han de Roode"
  },
  {
    "geslacht": "Mannen",
    "datum": "27-08-1982",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"7.53,87\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Brussel (B)",
    "naam": "Stijn Jaspers"
  },
  {
    "geslacht": "Mannen",
    "datum": "27-08-1983",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15,46\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Roosendaal",
    "naam": "Aafbrecht v.d. Veen"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "27-08-2010",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.54,36\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Utrecht",
    "naam": "Larissa Scholten"
  },
  {
    "geslacht": "Mannen",
    "datum": "27-09-1981",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"46.25,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 km snelwandelen",
    "plaats": "Rotterdam (baan)",
    "handgeklokt": true,
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "28-01-1979",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"51,7\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Zwolle",
    "naam": "Kenneth Macnack"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "28-01-1979",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,99\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m",
    "plaats": "Zwolle",
    "naam": "Mario Westbroek"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "28-01-1979",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m",
    "plaats": "Zwolle",
    "naam": "Mario Westbroek"
  },
  {
    "geslacht": "Mannen",
    "datum": "28-04-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"62,60\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Arnhem",
    "naam": "Cees van der Rhee"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "28-04-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,70\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/25810/Polshoog/",
    "plaats": "Haarlem",
    "naam": "Mette Elgersma (B)"
  },
  {
    "geslacht": "Mannen",
    "datum": "28-06-1931",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 100 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"42,5\""
    },
    "naam": "R. v.d. Berge, T. v. Welsenes, D. Benz, C. Berger"
  },
  {
    "geslacht": "Mannen",
    "datum": "28-06-1956",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"31.06,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 000 m",
    "plaats": "Rotterdam",
    "handgeklokt": true,
    "naam": "Paul Verra"
  },
  {
    "geslacht": "Mannen",
    "datum": "28-07-1929",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.40,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 000 m",
    "plaats": "Den Haag",
    "handgeklokt": true,
    "naam": "Andries Hoogerwerf"
  },
  {
    "geslacht": "Mannen",
    "datum": "28-07-1929",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,51\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Den Haag",
    "naam": "Gerrit Eijsker"
  },
  {
    "geslacht": "Mannen",
    "datum": "28-07-1976",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"9.02,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m steeplechase",
    "plaats": "Hechtel (B)",
    "handgeklokt": true,
    "naam": "Evert van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "28-08-1976",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"52.53,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 km snelwandelen",
    "plaats": "Sønder Omme (DK) (baan)",
    "handgeklokt": true,
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "28-08-1979",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14,24\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Roosendaal",
    "naam": "Jan Willem v.d. Wal"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "28-08-2005",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5.13,30\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Lisse",
    "naam": "Jirina van Kesteren"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "28-08-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.23,21\""
    },
    "einddatum": "",
    "categorie": "Junioren B",
    "onderdeel": "800 m",
    "plaats": "Beverwijk",
    "naam": "Babs Koeman"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "23-06-1990",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"34.20,90\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 000 m",
    "plaats": "Kapfenberg (D)",
    "naam": "Marianne v.d. Linde"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "29-01-1989",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"26,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "plaats": "Dortmund (D)",
    "naam": "Brenda Stoete"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "29-01-1989",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"8,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m",
    "plaats": "Dortmund (D)",
    "naam": "Brenda Stoete"
  },
  {
    "geslacht": "Mannen",
    "evenement": "Limiet wedstrijden",
    "handgeklokt": false,
    "datum": "29-01-2022",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1.47,02\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "link": "https://youtu.be/Bi5uUA10EJ0",
    "plaats": "Apeldoorn",
    "naam": "Jurgen Wielart"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "",
    "handgeklokt": false,
    "datum": "29-01-2022",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,66\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/36449/Hoog/#258288_13",
    "plaats": "Amsterdam",
    "naam": "Babs Koeman (A)"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "29-02-1992",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"7,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "50 m",
    "plaats": "Utrecht",
    "naam": "Marjanne v. Sambeek"
  },
  {
    "geslacht": "Mannen",
    "datum": "29-05-1924",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"30,88\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelslingeren",
    "plaats": "Amsterdam",
    "naam": "Jan Moolenaars"
  },
  {
    "geslacht": "Mannen",
    "datum": "29-05-1924",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"6,86\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Amsterdam",
    "naam": "Jaap Boot"
  },
  {
    "geslacht": "Mannen",
    "datum": "29-05-1932",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,734\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Assendelft",
    "naam": "Wim Kaan"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "29-05-1988",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"52,4\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 100 m",
    "plaats": "Santpoort",
    "naam": "L. de Graaff, N. Zwaan, J. Capellen, J. Sloot"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "29-05-1988",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"30,20\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Santpoort-Noord",
    "naam": "Renée Ferment"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "29-05-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.13,48\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m horden",
    "plaats": "Utrecht",
    "naam": "Carla van der Klei"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "29-05-2004",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.09,36\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m horden",
    "plaats": "Den Haag",
    "naam": "Jirina van Kesteren"
  },
  {
    "geslacht": "Mannen",
    "datum": "29-05-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.46,80\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/34571/800m/",
    "plaats": "Nijmegen",
    "naam": "Jurgen Wielart"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "T-meeting 2023",
    "handgeklokt": false,
    "datum": "29-05-2023",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"11,83\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/39326/100m_f/",
    "plaats": "Tilburg",
    "naam": "Femke Nuijten"
  },
  {
    "geslacht": "Mannen",
    "datum": "29-06-1930",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "plaats": "Rotterdam",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"10,6\""
    },
    "naam": "Chris Berger"
  },
  {
    "geslacht": "Mannen",
    "datum": "29-06-1941",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.34,1\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 000 m",
    "plaats": "Den Haag",
    "handgeklokt": true,
    "naam": "Han de Roode"
  },
  {
    "geslacht": "Mannen",
    "datum": "29-06-1962",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,86\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Londen (VK)",
    "naam": "Rein Knol"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "29-06-1974",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.16,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "plaats": "Arnhem",
    "naam": "Ingrid de Jager"
  },
  {
    "geslacht": "Mannen",
    "datum": "29-07-1964",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.24,6\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 Engelse mijl",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "Arnoud Bosch"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "29-07-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.07,22\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "400 m horden",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/33864/400mH/#207899_",
    "plaats": "Den Haag",
    "naam": "Babs Koeman (B)"
  },
  {
    "geslacht": "Mannen",
    "datum": "29-08-1926",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "110 m horden",
    "plaats": "Rijswijk",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"16,3\""
    },
    "naam": "Arie Kaan"
  },
  {
    "geslacht": "Mannen",
    "datum": "29-08-1926",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 100 m",
    "plaats": "Rijswijk",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"42,8\""
    },
    "naam": "J. Boot, T. v. Welsenes, F. Lamp, R. v.d. Berge"
  },
  {
    "geslacht": "Mannen",
    "datum": "29-08-1926",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"16.08,7\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Rijswijk",
    "handgeklokt": true,
    "naam": "Arie Klaase"
  },
  {
    "geslacht": "Mannen",
    "datum": "29-08-1981",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.41,55\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Leiden",
    "naam": "Stijn Jaspers"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "29-08-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,21\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/33240/100m_hf/#190158_",
    "plaats": "Utrecht",
    "naam": "Femke Nuijten"
  },
  {
    "geslacht": "Mannen",
    "datum": "29-09-1946",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.33,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 000 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "Wim van Gog"
  },
  {
    "geslacht": "Mannen",
    "datum": "29-09-1984",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"42,88\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelslingeren",
    "plaats": "Amsterdam",
    "naam": "Gosse van de Velde"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "29-09-1988",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"35.15\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 000 m",
    "plaats": "Haarlem",
    "naam": "Marianne v.d. Linde"
  },
  {
    "geslacht": "Mannen",
    "datum": "30-01-1976",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2,14\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Arnhem",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "30-01-1977",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"2,16\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Zwolle",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "30-01-1977",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"4,11\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Zwolle",
    "naam": "Charles Mathôt"
  },
  {
    "geslacht": "Mannen",
    "datum": "30-01-1977",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"49.47,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 km snelwandelen",
    "plaats": "Rotterdam (weg)",
    "handgeklokt": true,
    "naam": "Frank van Ravensberg"
  },
  {
    "geslacht": "Mannen",
    "datum": "30-03-1963",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,70\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Amsterdam",
    "naam": "Ton van Zijl"
  },
  {
    "geslacht": "Mannen",
    "datum": "30-03-1963",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1,70\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Amsterdam",
    "naam": "Wim Krijnen"
  },
  {
    "geslacht": "Mannen",
    "datum": "30-03-1963",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"3,30\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Amsterdam",
    "naam": "Wim Krijnen"
  },
  {
    "geslacht": "Mannen",
    "datum": "30-04-1970",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14,71\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "plaats": "Heiloo",
    "naam": "Rein Knol"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "30-04-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5,39\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Amsterdam",
    "naam": "Anja Smits"
  },
  {
    "geslacht": "Mannen",
    "datum": "30-04-1981",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"30.37,3\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 000 m",
    "plaats": "Nijmegen",
    "handgeklokt": true,
    "naam": "Pieter Fris"
  },
  {
    "geslacht": "Mannen",
    "datum": "30-04-1989",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"59,34\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Haarlem",
    "naam": "Erik de Busser"
  },
  {
    "geslacht": "Mannen",
    "datum": "30-05-1954",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"32.05,5\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 000 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "Paul Verra"
  },
  {
    "geslacht": "Mannen",
    "datum": "30-05-1957",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15.06,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Dordrecht",
    "handgeklokt": true,
    "naam": "Paul Verra"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "30-05-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14,98\""
    },
    "einddatum": "",
    "categorie": "Junioren B",
    "onderdeel": "100 m horden",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/35557/100mH/#233779_149",
    "plaats": "Haarlem",
    "naam": "Babs Koeman"
  },
  {
    "geslacht": "Mannen",
    "datum": "30-06-1923",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"11,4\""
    },
    "naam": "Frits Lamp"
  },
  {
    "geslacht": "Mannen",
    "datum": "30-06-1923",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 100 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"46,5\""
    },
    "naam": "van Duyn, C. Saeys, v.d. Schaar, A. Paulen"
  },
  {
    "geslacht": "Mannen",
    "datum": "30-06-1923",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"6,41\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Amsterdam",
    "naam": "Rein Verwers"
  },
  {
    "geslacht": "Mannen",
    "datum": "30-06-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,13\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Brussel (B)",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "30-06-2007",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.06,29\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m horden",
    "plaats": "Amsterdam",
    "naam": "Jirina van Kesteren"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "30-06-2007",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.07,65\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m horden",
    "plaats": "Amsterdam",
    "naam": "Jirina van Kesteren"
  },
  {
    "geslacht": "Mannen",
    "datum": "30-07-1922",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"24,8\""
    },
    "naam": "Ad Paulen"
  },
  {
    "geslacht": "Mannen",
    "datum": "30-07-1922",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"25,25\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelslingeren",
    "plaats": "Haarlem",
    "naam": "Jan Moolenaars"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "30-07-2010",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4.57,96\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Utrecht",
    "naam": "Larissa Scholten"
  },
  {
    "geslacht": "Mannen",
    "datum": "30-08-1922",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"3.52,2\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 400 m",
    "plaats": "Haarlem",
    "handgeklokt": true,
    "naam": "v.d. Schaar, J. Moolenaars, H. Saeys, C. Saeys"
  },
  {
    "geslacht": "Mannen",
    "datum": "30-08-1972",
    "locatie": "outdoor",
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "prestatie": {
      "code": "\"48,9\""
    },
    "naam": "Bert de Jager"
  },
  {
    "geslacht": "Mannen",
    "datum": "30-08-1972",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,06\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "plaats": "Amsterdam",
    "naam": "Ruud Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "30-09-1972",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.30,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 200 m",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "R. Wielart, M. Beekes, W. Wielart, B. de Jager"
  },
  {
    "geslacht": "Vrouwen",
    "evenement": "Haarlemse clubkampioenschappen 2023",
    "handgeklokt": false,
    "datum": "30-09-2023",
    "locatie": "outdoor",
    "prestatie": {
      "code": "13.27,01"
    },
    "einddatum": "",
    "categorie": "Junioren B",
    "onderdeel": "3 000 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagen/39816/356368/#losonderdeel_62_",
    "plaats": "Haarlem",
    "naam": "Liesbeth Hannema (c)"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "30-11-1991",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"7,1\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "50 m",
    "plaats": "Utrecht",
    "naam": "Marjanne v. Sambeek"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "31-01-1981",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"8,43\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m horden",
    "plaats": "Zwolle",
    "naam": "Luke Prins"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "31-01-1981",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"8,3\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m horden",
    "plaats": "Zwolle",
    "naam": "Luke Prins"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "31-01-1993",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1.35,88\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 200 m",
    "plaats": "Den Haag",
    "naam": "N. Treep, R. Schlüter, G. Wessel, W. Gebe"
  },
  {
    "geslacht": "Mannen",
    "datum": "31-05-1971",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"9.44,0\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m steeplechase",
    "plaats": "Amsterdam",
    "handgeklokt": true,
    "naam": "Hans de Rijk"
  },
  {
    "geslacht": "Mannen",
    "datum": "31-05-1980",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"4,75\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "plaats": "Steinkjer (N)",
    "naam": "Pim Göbel"
  },
  {
    "geslacht": "Mannen",
    "datum": "31-08-1924",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"18.11,7\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Arnhem",
    "handgeklokt": true,
    "naam": "Ge Hillebrand"
  },
  {
    "geslacht": "Mannen",
    "datum": "31-08-1924",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"33,42\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Arnhem",
    "naam": "Gerrit Eijsker"
  },
  {
    "geslacht": "Mannen",
    "datum": "31-08-1983",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"13.24,64\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Koblenz (D)",
    "naam": "Stijn Jaspers"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "31-08-1990",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"9.25,3\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "3 000 m",
    "plaats": "Amsterdam",
    "naam": "Marianne v.d. Linde"
  },
  {
    "onderdeel": "1 000 m",
    "naam": "Stein Metten",
    "prestatie": {
      "code": "\"2.54,19\""
    },
    "plaats": "Gent (B)",
    "datum": "27-02-2005",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "1 500 m",
    "naam": "Paul Jaspers",
    "prestatie": {
      "code": "\"3.51,0\""
    },
    "plaats": "Dortmund (D)",
    "datum": "29-01-1984",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "08-03-1981",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"22,3\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "200 m",
    "plaats": "Dortmund (D)",
    "naam": "Allen Ellsworth"
  },
  {
    "onderdeel": "3 000 m",
    "naam": "Paul Jaspers",
    "prestatie": {
      "code": "\"8.18,84\""
    },
    "plaats": "Zuid-Laren",
    "datum": "11-02-1984",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "3 km snelwandelen",
    "naam": "Frank van Ravensberg",
    "prestatie": {
      "code": "\"14.18,5\""
    },
    "plaats": "Rotterdam",
    "datum": "10-02-1979",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "13-02-1983",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"48,53\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "400 m",
    "plaats": "Rotterdam",
    "naam": "Allen Ellsworth"
  },
  {
    "onderdeel": "50 m",
    "naam": "Mario Westbroek",
    "prestatie": {
      "code": "\"6,24\""
    },
    "plaats": "Groningen",
    "datum": "14-01-1978",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "60 m horden",
    "naam": "Chris van der Werff",
    "prestatie": {
      "code": "\"8,61\""
    },
    "plaats": "Zwolle",
    "datum": "17-01-1988",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "60 m",
    "naam": "Mario Westbroek",
    "prestatie": {
      "code": "\"6,99\""
    },
    "plaats": "Zwolle",
    "datum": "28-01-1979",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "800 m",
    "naam": "Ron Koridon",
    "prestatie": {
      "code": "\"1.57,5\""
    },
    "plaats": "Rotterdam",
    "datum": "20-02-1971",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "geslacht": "Mannen",
    "datum": "03-02-1985",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"14,75\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Maastricht",
    "naam": "Hein Macnack"
  },
  {
    "onderdeel": "Hoogspringen",
    "naam": "Ruud Wielart",
    "prestatie": {
      "code": "\"2,11\""
    },
    "plaats": "Leiden",
    "datum": "24-02-1973",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Kogelstoten",
    "naam": "Martijn Spruit",
    "prestatie": {
      "code": "\"14,71\""
    },
    "plaats": "Den Haag",
    "datum": "27-02-1994",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Polsstokhoogspringen",
    "naam": "Kenneth Portanger",
    "prestatie": {
      "code": "\"4,20\""
    },
    "plaats": "Parijs (F)",
    "datum": "22-12-1982",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "geslacht": "Mannen",
    "datum": "02-02-1985",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"7,43\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "Verspringen",
    "plaats": "Maastricht",
    "naam": "Hein Macnack"
  },
  {
    "onderdeel": "Zevenkamp",
    "naam": "Stein Metten",
    "datum": "04/05-02-2006",
    "plaats": "Gent (B)",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"60 m\",\n        \"prestatie\": \"7,77\",\n        \"punten\": \"(628)\"\n    },\n    {\n        \"onderdeel\": \"Verspringen\",\n        \"prestatie\": \"6,00\",\n        \"punten\": \"(587)\"\n    },\n    {\n        \"onderdeel\": \"Kogelstoten\",\n        \"prestatie\": \"12,39\",\n        \"punten\": \"(630)\"\n    },\n    {\n        \"onderdeel\": \"Hoogspringen\",\n        \"prestatie\": \"1,62\",\n        \"punten\": \"(480)\"\n    },\n    {\n        \"onderdeel\": \"60 m horden\",\n        \"prestatie\": \"9,67\",\n        \"punten\": \"(605)\"\n    },\n    {\n        \"onderdeel\": \"Polsstokhoogspringen\",\n        \"prestatie\": \"3,40\",\n        \"punten\": \"(457)\"\n    },\n    {\n        \"onderdeel\": \"1 000 m\",\n        \"prestatie\": \"3.06,44\",\n        \"punten\": \"(603)\"\n    },\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"3990\"\n    }\n]"
    },
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "1 000 m",
    "naam": "Stein Metten",
    "prestatie": {
      "code": "\"2.54,19\""
    },
    "plaats": "Gent (B)",
    "datum": "27-02-2005",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "1 500 m",
    "naam": "Paul Jaspers",
    "prestatie": {
      "code": "\"4.11,43\""
    },
    "plaats": "Zuid-Laren",
    "datum": "13-02-1982",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "geslacht": "Mannen",
    "datum": "23-12-1984",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"23,14 (22,9)\""
    },
    "einddatum": "",
    "categorie": "Junioren B",
    "onderdeel": "200 m",
    "plaats": "Dortmund (D)",
    "naam": "Hein Macnack"
  },
  {
    "onderdeel": "3 000 m",
    "naam": "Paul Jaspers",
    "prestatie": {
      "code": "\"9.06,7\""
    },
    "plaats": "Zuid-Laren",
    "datum": "20-12-1981",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "30 m",
    "naam": "Daniël de Vries",
    "prestatie": {
      "code": "\"4,89\""
    },
    "plaats": "Heerde",
    "datum": "23-11-2019",
    "link": "https://www.atletiek.nu/atleet/main/879963/",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "3 km snelwandelen",
    "naam": "Frank van Ravensberg",
    "prestatie": {
      "code": "\"14.50,8\""
    },
    "plaats": "Rotterdam",
    "datum": "15-02-1977",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "400 m",
    "naam": "Pim Pauel",
    "prestatie": {
      "code": "\"54,21\""
    },
    "plaats": "Apeldoorn",
    "datum": "24-01-2015",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "geslacht": "Mannen",
    "datum": "14-01-1983",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,0\""
    },
    "einddatum": "",
    "categorie": "Junioren B",
    "onderdeel": "50 m",
    "plaats": "Groningen",
    "handgeklokt": true,
    "naam": "Mario Westbroek"
  },
  {
    "onderdeel": "60 m horden",
    "naam": "Walter Kruk",
    "prestatie": {
      "code": "\"8,37\""
    },
    "plaats": "Zwolle",
    "datum": "25-01-1987",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "geslacht": "Mannen",
    "datum": "23-12-1984",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"7,04 (6,8)\""
    },
    "einddatum": "",
    "categorie": "Junioren B",
    "onderdeel": "60 m",
    "plaats": "Dortmund (D)",
    "naam": "Hein Macnack"
  },
  {
    "onderdeel": "800 m",
    "naam": "Frans Capellen",
    "prestatie": {
      "code": "\"2.03,4\""
    },
    "plaats": "Arnhem",
    "datum": "26-01-1975",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Hinkstapspringen",
    "naam": "Arjan Warmerdam",
    "prestatie": {
      "code": "\"13,18\""
    },
    "plaats": "Düsseldorf (D)",
    "datum": "03-02-1985",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Hoogspringen",
    "naam": "Walter Kruk",
    "prestatie": {
      "code": "\"2,01\""
    },
    "plaats": "Dortmund (D)",
    "datum": "28-12-1986",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Kogelstoten",
    "naam": "Stein Metten",
    "prestatie": {
      "code": "\"13,12\""
    },
    "plaats": "Gent (B)",
    "datum": "26-02-2005",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Polsstokhoogspringen",
    "naam": "Kenneth Portanger",
    "prestatie": {
      "code": "\"3,80\""
    },
    "plaats": "Beverwijk",
    "datum": "22-02-1981",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "geslacht": "Mannen",
    "datum": "22-01-1984",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"7,09\""
    },
    "einddatum": "",
    "categorie": "Junioren B",
    "onderdeel": "Verspringen",
    "plaats": "Zwolle",
    "naam": "Hein Macnack"
  },
  {
    "onderdeel": "Zevenkamp",
    "naam": "Stein Metten",
    "datum": "26/27-02-2005",
    "plaats": "Gent (B)",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"60 m\",\n        \"prestatie\": \"7,76\",\n        \"punten\": \"(631)\"\n    },\n    {\n        \"onderdeel\": \"Verspringen\",\n        \"prestatie\": \"5,67\",\n        \"punten\": \"(516)\"\n    },\n    {\n        \"onderdeel\": \"Kogelstoten\",\n        \"prestatie\": \"13,12\",\n        \"punten\": \"(675)\"\n    },\n    {\n        \"onderdeel\": \"Hoogspringen\",\n        \"prestatie\": \"1,60\",\n        \"punten\": \"(464)\"\n    },\n    {\n        \"onderdeel\": \"60 m horden\",\n        \"prestatie\": \"8,85\",\n        \"punten\": \"(779)\"\n    },\n    {\n        \"onderdeel\": \"Polsstokhoogspringen\",\n        \"prestatie\": \"3,40\",\n        \"punten\": \"(457)\"\n    },\n    {\n        \"onderdeel\": \"1 000 m\",\n        \"prestatie\": \"2.54,19\",\n        \"punten\": \"(722)\"\n    },\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"4244\"\n    }\n]"
    },
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "1 000 m",
    "naam": "Peter Markwat",
    "prestatie": {
      "code": "\"3.22,4\""
    },
    "plaats": "Den Haag",
    "datum": "25-11-1990",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "30 m",
    "naam": "Finn Damhoff",
    "prestatie": {
      "code": "\"5,03\""
    },
    "plaats": "Heerde",
    "datum": "24-11-2018",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "35 m",
    "naam": "Erick Könst",
    "prestatie": {
      "code": "\"5,4\""
    },
    "plaats": "Den Bosch",
    "datum": "28-01-2018",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "40 m",
    "naam": "Erick Könst",
    "prestatie": {
      "code": "\"6,26\""
    },
    "plaats": "Waalwijk",
    "datum": "25-02-2018",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "50 m",
    "naam": "Jason Mensingh",
    "prestatie": {
      "code": "\"6,84 (6,6)\""
    },
    "plaats": "Amsterdam",
    "datum": "13-11-1993",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "600 m",
    "naam": "Vincent van der Lans",
    "prestatie": {
      "code": "\"1.49,0\""
    },
    "plaats": "Den Haag",
    "datum": "29-11-1992",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "60 m horden",
    "naam": "Jason Mensingh",
    "prestatie": {
      "code": "\"9,37\""
    },
    "plaats": "Den Haag",
    "datum": "13-03-1994",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "60 m",
    "naam": "Kan Dinkla",
    "prestatie": {
      "code": "\"7,66\""
    },
    "plaats": "Zuidbroek",
    "datum": "25-01-2004",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "800 m",
    "naam": "Roberto Slierings",
    "prestatie": {
      "code": "\"2.12,06\""
    },
    "plaats": "Den Haag",
    "datum": "18-02-1995",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Hoogspringen",
    "naam": "Erick Könst",
    "prestatie": {
      "code": "\"1,73\""
    },
    "plaats": "Dordrecht",
    "datum": "27-10-2018",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Kogelstoten",
    "naam": "Dennis Kruithof",
    "prestatie": {
      "code": "\"13,33\""
    },
    "plaats": "Amsterdam",
    "datum": "??-02-1980",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Verspringen",
    "naam": "Pim Pauel",
    "prestatie": {
      "code": "\"5,29\""
    },
    "plaats": "Zoetermeer",
    "datum": "02-03-2013",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "1 000 m",
    "naam": "Peter Markwat",
    "prestatie": {
      "code": "\"3.22,4\""
    },
    "plaats": "Den Haag",
    "datum": "25-11-1990",
    "categorie": "Junioren D",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "35 m",
    "naam": "Tymo Bouman",
    "prestatie": {
      "code": "\"5,5\""
    },
    "plaats": "Epe",
    "datum": "12-03-2017",
    "categorie": "Junioren D",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "40 m horden",
    "naam": "Tymo Bouman",
    "prestatie": {
      "code": "\"7,3\""
    },
    "plaats": "Doetichem",
    "datum": "25-02-2018",
    "categorie": "Junioren D",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "40 m",
    "naam": "Tymo Bouman",
    "prestatie": {
      "code": "\"6,2\""
    },
    "plaats": "Doetichem",
    "datum": "25-02-2018",
    "categorie": "Junioren D",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "50 m",
    "naam": "Tymo Bouman",
    "prestatie": {
      "code": "\"7,49\""
    },
    "plaats": "Heerenveen",
    "datum": "17-03-2018",
    "categorie": "Junioren D",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "600 m",
    "naam": "Vincent van der Lans",
    "prestatie": {
      "code": "\"1.49,0\""
    },
    "plaats": "Den Haag",
    "datum": "29-11-1992",
    "categorie": "Junioren D",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "60 m horden",
    "naam": "Derk Hoefman",
    "prestatie": {
      "code": "\"10,30\""
    },
    "plaats": "Enschede",
    "datum": "28-10-2018",
    "categorie": "Junioren D",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "60 m",
    "naam": "Ivo van Kempen",
    "prestatie": {
      "code": "\"8,51\""
    },
    "plaats": "Den Haag",
    "datum": "05-03-1995",
    "categorie": "Junioren D",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Hoogspringen",
    "naam": "Erick Könst, Tymo Bouman",
    "prestatie": {
      "code": "\"1,40\""
    },
    "plaats": "Dongen / Uden",
    "datum": "17-01-2016 / 16-12-2017",
    "categorie": "Junioren D",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "29-10-2022",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"12,53\""
    },
    "einddatum": "",
    "categorie": "Junioren D",
    "onderdeel": "Kogelstoten",
    "link": "https://www.atletiek.nu/atleet/main/1487663/",
    "plaats": "Assendelft",
    "naam": "Olliver Konst"
  },
  {
    "onderdeel": "Verspringen",
    "naam": "Pim Pauel",
    "prestatie": {
      "code": "\"4,52\""
    },
    "plaats": "Amsterdam",
    "datum": "20-11-2010",
    "categorie": "Junioren D",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "1 500 m",
    "naam": "Stijn Jaspers",
    "prestatie": {
      "code": "\"3.44,8\""
    },
    "plaats": "Amerika",
    "datum": "11-02-1983",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "200 m",
    "naam": "Jurgen Wielart",
    "prestatie": {
      "code": "\"21,99\""
    },
    "plaats": "Apeldoorn",
    "datum": "05-01-2013",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "3 000 m",
    "naam": "Paul Jaspers",
    "prestatie": {
      "code": "\"8.04,01\""
    },
    "plaats": "Den Haag",
    "datum": "04-02-1989",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "02-02-1985",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"47,90\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "plaats": "Maastricht",
    "naam": "Allen Ellsworth"
  },
  {
    "onderdeel": "4 x 200 m",
    "naam": "Niels Terol, Roel van Ree, Erik van der Veen, Ger Wessel",
    "prestatie": {
      "code": "\"1.32,82\""
    },
    "plaats": "Den Haag",
    "datum": "08-02-1998",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "50 m",
    "naam": "Guus Groskamp",
    "prestatie": {
      "code": "\"6,08\""
    },
    "plaats": "Zwolle",
    "datum": "20-01-1996",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "5 km snelwandelen",
    "naam": "Frank van Ravensberg",
    "prestatie": {
      "code": "\"22.04,9\""
    },
    "plaats": "Zuid-Laren",
    "datum": "12-02-1984",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "60 m horden",
    "naam": "Marc Kok",
    "prestatie": {
      "code": "\"8,06\""
    },
    "plaats": "Den Haag",
    "datum": "05-02-1989",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "60 m",
    "naam": "Mario Westbroek",
    "prestatie": {
      "code": "\"6,82\""
    },
    "plaats": "Rotterdam",
    "datum": "21-02-1982",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "geslacht": "Mannen",
    "datum": "20-02-2021",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"1.48,16\""
    },
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/34431/800m_f/",
    "plaats": "Apeldoorn",
    "naam": "Jurgen Wielart"
  },
  {
    "onderdeel": "Hinkstapspringen",
    "naam": "Anne Jan van der Veen",
    "prestatie": {
      "code": "\"15,51\""
    },
    "plaats": "Zuid-Laren",
    "datum": "12-02-1984",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Hoogspringen",
    "naam": "Ruud Wielart",
    "prestatie": {
      "code": "\"2,26\""
    },
    "plaats": "Rotterdam",
    "datum": "17-02-1980",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Kogelstoten",
    "naam": "Rob Bakker",
    "prestatie": {
      "code": "\"16,98\""
    },
    "plaats": "Den Haag",
    "datum": "31-01-1993",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Polsstokhoogspringen",
    "naam": "Pim Göbel",
    "prestatie": {
      "code": "\"4,86\""
    },
    "plaats": "Zwolle",
    "datum": "31-01-1981",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "geslacht": "Mannen",
    "datum": "07-02-1987",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"7,43\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Den Haag",
    "naam": "Hein Macnack"
  },
  {
    "geslacht": "Mannen",
    "datum": "21/22-01-1984",
    "locatie": "indoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"60 m\",\n        \"prestatie\": \"7,64\",\n        \"punten\": \"(668)\"\n    },\n    {\n        \"onderdeel\": \"Verspringen\",\n        \"prestatie\": \"5,74\",\n        \"punten\": \"(531)\"\n    },\n    {\n        \"onderdeel\": \"Kogelstoten\",\n        \"prestatie\": \"13,59\",\n        \"punten\": \"(703)\"\n    },\n    {\n        \"onderdeel\": \"Hoogspringen\",\n        \"prestatie\": \"1,80\",\n        \"punten\": \"(627)\"\n    },\n    {\n        \"onderdeel\": \"60 m horden\",\n        \"prestatie\": \"9,34\",\n        \"punten\": \"(673)\"\n    },\n    {\n        \"onderdeel\": \"Polsstokhoogspringen\",\n        \"prestatie\": \"3,40\",\n        \"punten\": \"(457)\"\n    },\n    {\n        \"onderdeel\": \"1 000 m\",\n        \"prestatie\": \"3.07,1\",\n        \"punten\": \"(596)\"\n    },\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"4255\"\n    }\n]"
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Zevenkamp",
    "plaats": "Zwolle",
    "naam": "Les Brown"
  },
  {
    "onderdeel": "Zweedse estafette",
    "naam": "Job Wiltink, Daan Oppenhuis, Dik de Groot, Stefan Scholte",
    "prestatie": {
      "code": "\"2.11,51\""
    },
    "plaats": "Apeldoorn",
    "datum": "10-03-2018",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "1 000 m",
    "naam": "Paul Jaspers",
    "prestatie": {
      "code": "\"2.26,3\""
    },
    "plaats": "Leiden",
    "datum": "16-09-1983",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Mannen",
    "datum": "26-08-1978",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"10,74\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "100 m",
    "plaats": "Merksem (B)",
    "naam": "Mario Westbroek"
  },
  {
    "onderdeel": "10 km snelwandelen",
    "naam": "Frank van Ravensberg",
    "prestatie": {
      "code": "\"47.20,4\""
    },
    "plaats": "Drachten",
    "datum": "06-10-1979",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "110 m horden",
    "naam": "Ruud Wielart",
    "prestatie": {
      "code": "\"15,64 (15,4)\""
    },
    "plaats": "Amsterdam",
    "datum": "25-06-1972",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "1 500 m",
    "naam": "Paul Jaspers",
    "prestatie": {
      "code": "\"3.43,8\""
    },
    "plaats": "Neerpelt (B)",
    "datum": "02-08-1984",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "1 uur",
    "naam": "Frank van Ravensberg",
    "prestatie": {
      "code": "\"12.837,85m\""
    },
    "plaats": "Zaandam",
    "datum": "03-08-1979",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "2 000 m steeplechase",
    "naam": "Paul Jaspers",
    "prestatie": {
      "code": "\"6.09,2\""
    },
    "plaats": "Veldhoven",
    "datum": "20-05-1982",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Mannen",
    "datum": "21-05-1980",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"21,34\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "200 m",
    "plaats": "Haarlem",
    "naam": "Mario Westbroek"
  },
  {
    "onderdeel": "3 000 m",
    "naam": "Paul Jaspers",
    "prestatie": {
      "code": "\"7.57,62\""
    },
    "plaats": "Hechtel (B)",
    "datum": "14-08-1984",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "3 000 m steeplechase",
    "naam": "Evert van Ravensberg",
    "prestatie": {
      "code": "\"10.20,6\""
    },
    "plaats": "Haarlem",
    "datum": "02-07-1972",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "400 m horden",
    "naam": "Rob Strik",
    "prestatie": {
      "code": "\"56,04 (55,9)\""
    },
    "plaats": "Haarlem",
    "datum": "09-07-1967",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "400 m",
    "naam": "Mario Westbroek",
    "prestatie": {
      "code": "\"47,14\""
    },
    "plaats": "Den Haag",
    "datum": "04-07-1980",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": true,
    "datum": "09-10-1983",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"42,4\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "4 x 100 m",
    "plaats": "Haarlem",
    "naam": "Kenneth Portanger, Hein Macnack, René Moesman, Allen Ellsworth"
  },
  {
    "onderdeel": "4 x 400 m",
    "naam": "Daan van Rijsbergen, Stijn Jaspers, Bart van Rijsbergen, Raymond Hoefman",
    "prestatie": {
      "code": "\"3.22,90\""
    },
    "plaats": "Den Haag",
    "datum": "14-09-1980",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "4 x 800 m",
    "naam": "Bart van Rijsbergen, Raymond Hoefman, Roland de Ruig, Stijn Jaspers",
    "prestatie": {
      "code": "\"8.16,5\""
    },
    "plaats": "Den Haag",
    "datum": "29-08-1980",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "5 000 m",
    "naam": "Paul Jaspers",
    "prestatie": {
      "code": "\"14.34,38\""
    },
    "plaats": "Amsterdam",
    "datum": "02-09-1984",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "800 m",
    "naam": "Stijn Jaspers",
    "prestatie": {
      "code": "\"1.51,3\""
    },
    "plaats": "Sittard",
    "datum": "10-08-1980",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Discuswerpen",
    "naam": "Martijn Spruit",
    "prestatie": {
      "code": "\"47,28\""
    },
    "plaats": "Den Haag",
    "datum": "26-06-1994",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Mannen",
    "datum": "26-08-1984",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15,02\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Utrecht",
    "naam": "Hein Macnack (B)"
  },
  {
    "onderdeel": "Hoogspringen",
    "naam": "Ruud Wielart",
    "prestatie": {
      "code": "\"2,13\""
    },
    "plaats": "Brussel (B)",
    "datum": "30-06-1973",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Kogelslingeren",
    "naam": "Martijn Spruit",
    "prestatie": {
      "code": "\"34,44\""
    },
    "plaats": "Groningen",
    "datum": "27-06-1993",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Kogelstoten",
    "naam": "Leo van der Veer",
    "prestatie": {
      "code": "\"14,66\""
    },
    "plaats": "Brugge (B)",
    "datum": "02-10-1966",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Polsstokhoogspringen",
    "naam": "Arno van Vugt",
    "prestatie": {
      "code": "\"4,40\""
    },
    "plaats": "Haarlem",
    "datum": "29-05-1982",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Speerwerpen",
    "naam": "Niels Terol",
    "prestatie": {
      "code": "\"63,50\""
    },
    "plaats": "Hengelo",
    "datum": "04-06-1994",
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Tienkamp",
    "naam": "Niels Terol",
    "plaats": "Haarlem",
    "datum": "01/02-10-1994",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"100 m\",\n        \"prestatie\": \"11,64\",\n        \"punten\": \"(723)\"\n    },\n    {\n        \"onderdeel\": \"Verspringen\",\n        \"prestatie\": \"6,34\",\n        \"punten\": \"(661)\"\n    },\n    {\n        \"onderdeel\": \"Kogelstoten\",\n        \"prestatie\": \"11,66\",\n        \"punten\": \"(586)\"\n    },\n    {\n        \"onderdeel\": \"Hoogspringen\",\n        \"prestatie\": \"1,72\",\n        \"punten\": \"(560)\"\n    },\n    {\n        \"onderdeel\": \"400 m\",\n        \"prestatie\": \"57,74\",\n        \"punten\": \"(493)\"\n    },\n    {\n        \"onderdeel\": \"110 m horden\",\n        \"prestatie\": \"17,04\",\n        \"punten\": \"(620)\"\n    },\n    {\n        \"onderdeel\": \"Discuswerpen\",\n        \"prestatie\": \"37,64\",\n        \"punten\": \"(617)\"\n    },\n    {\n        \"onderdeel\": \"Polsstokhoogspringen\",\n        \"prestatie\": \"3,80\",\n        \"punten\": \"(562)\"\n    },\n    {\n        \"onderdeel\": \"Speerwerpen\",\n        \"prestatie\": \"56,12\",\n        \"punten\": \"(680)\"\n    },\n    {\n        \"onderdeel\": \"1 500 m\",\n        \"prestatie\": \"5.13,4\",\n        \"punten\": \"(485)\"\n    },\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"5987 pnt\"\n    }\n]"
    },
    "categorie": "Junioren A",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Mannen",
    "datum": "01-09-1985",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"7,33\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "Verspringen",
    "plaats": "Utrecht",
    "naam": "Hein Macnack"
  },
  {
    "onderdeel": "1 000 m",
    "naam": "Jan Kiewit",
    "prestatie": {
      "code": "\"2.41,2\""
    },
    "plaats": "Iserlohn (D)",
    "datum": "09-06-1957",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "100 m",
    "naam": "Mario Westbroek",
    "prestatie": {
      "code": "\"10,74 (10,5)\""
    },
    "plaats": "Merksem (B)",
    "datum": "26-08-1978",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "10 km snelwandelen",
    "naam": "Frank van Ravensberg",
    "prestatie": {
      "code": "\"49.47,2\""
    },
    "plaats": "Rotterdam",
    "datum": "30-01-1977",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "110 m horden",
    "naam": "Walter Kruk",
    "prestatie": {
      "code": "\"14,78\""
    },
    "plaats": "Vught",
    "datum": "28-06-1987",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "1 500 m",
    "naam": "Paul Jaspers",
    "prestatie": {
      "code": "\"4.00,5\""
    },
    "plaats": "Den Haag",
    "datum": "24-09-1982",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "1 500 m steeplechase",
    "naam": "Paul Jaspers",
    "prestatie": {
      "code": "\"4.24,7\""
    },
    "plaats": "Nijmegen",
    "datum": "05-06-1982",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "1 uur",
    "naam": "Frank van Ravensberg",
    "prestatie": {
      "code": "\"11.444,60 m\""
    },
    "plaats": "Rotterdam",
    "datum": "17-10-1976",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "2 000 m steeplechase",
    "naam": "Paul Jaspers",
    "prestatie": {
      "code": "\"6.09,2\""
    },
    "plaats": "Veldhoven",
    "datum": "20-05-1982",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "200 m",
    "naam": "Mario Westbroek",
    "prestatie": {
      "code": "\"22,44 (22,2)\""
    },
    "plaats": "Den Haag",
    "datum": "20-08-1978",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "3 000 m",
    "naam": "Paul Jaspers",
    "prestatie": {
      "code": "\"8.37,83\""
    },
    "plaats": "Haarlem",
    "datum": "29-05-1982",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "300 m horden",
    "naam": "Chris van der Werff",
    "prestatie": {
      "code": "\"39,04 (38,8)\""
    },
    "plaats": "Beverwijk",
    "datum": "04-07-1987",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "400 m horden",
    "naam": "Sjoerd Lether",
    "prestatie": {
      "code": "\"61,02\""
    },
    "plaats": "Amsterdam",
    "datum": "14-05-2004",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "400 m",
    "naam": "Casper Sloos",
    "prestatie": {
      "code": "\"49,64 (49,5)\""
    },
    "plaats": "Den Haag",
    "datum": "06-06-1982",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "4 x 100 m",
    "naam": "Tony v/d Prijt, Chris van der Werff, Jerry Brouwer, Walter Kruk",
    "prestatie": {
      "code": "\"44,64 (44,5)\""
    },
    "plaats": "Spijkenisse",
    "datum": "26-09-1987",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "5 km snelwandelen",
    "naam": "Frank van Ravensberg",
    "prestatie": {
      "code": "\"24.40,0\""
    },
    "plaats": "Sonder-Omme (DK)",
    "datum": "25-06-1977",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "800 m",
    "naam": "Paul Jaspers",
    "prestatie": {
      "code": "\"1.56,9\""
    },
    "plaats": "Arnhem",
    "datum": "31-05-1982",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Discuswerpen",
    "naam": "Dennis Kruithof",
    "prestatie": {
      "code": "\"44,58\""
    },
    "plaats": "Amsterdam",
    "datum": "27-06-1982",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Mannen",
    "datum": "26-08-1984",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15,02\""
    },
    "einddatum": "",
    "categorie": "Junioren B",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Utrecht",
    "naam": "Hein Macnack"
  },
  {
    "onderdeel": "Hoogspringen",
    "naam": "Walter Kruk",
    "prestatie": {
      "code": "\"2,01\""
    },
    "plaats": "Vught",
    "datum": "28-06-1987",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Kogelslingeren",
    "naam": "Martijn Spruit",
    "prestatie": {
      "code": "\"33,72\""
    },
    "plaats": "Utrecht",
    "datum": "27-09-1992",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Kogelstoten",
    "naam": "Leo van der Veer",
    "prestatie": {
      "code": "\"15,55\""
    },
    "plaats": "Leiden",
    "datum": "13-06-1965",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Negenkamp",
    "naam": "Walter Kruk",
    "plaats": "Vught",
    "datum": "27/28-06-1987",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"100 m\",\n        \"prestatie\": \"11,54\",\n        \"punten\": \"(744)\"\n    },\n    {\n        \"onderdeel\": \"Verspringen\",\n        \"prestatie\": \"6,49\",\n        \"punten\": \"(695)\"\n    },\n    {\n        \"onderdeel\": \"Kogelstoten\",\n        \"prestatie\": \"11,64\",\n        \"punten\": \"(585)\"\n    },\n    {\n        \"onderdeel\": \"Hoogspringen\",\n        \"prestatie\": \"2,01\",\n        \"punten\": \"(813)\"\n    },\n    {\n        \"onderdeel\": \"110 m horden\",\n        \"prestatie\": \"14,78\",\n        \"punten\": \"(876)\"\n    },\n    {\n        \"onderdeel\": \"Discuswerpen\",\n        \"prestatie\": \"29,49\",\n        \"punten\": \"(454)\"\n    },\n    {\n        \"onderdeel\": \"Polsstokhoogspringen\",\n        \"prestatie\": \"3,40\",\n        \"punten\": \"(457)\"\n    },\n    {\n        \"onderdeel\": \"Speerwerpen\",\n        \"prestatie\": \"47,06\",\n        \"punten\": \"(545)\"\n    },\n    {\n        \"onderdeel\": \"1500 m\",\n        \"prestatie\": \"4.50,63\",\n        \"punten\": \"(615)\"\n    },\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"5784 pnt\"\n    }\n]"
    },
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Polsstokhoogspringen",
    "naam": "Kenneth Portanger",
    "prestatie": {
      "code": "\"3,85\""
    },
    "plaats": "Haarlem",
    "datum": "23-05-1981",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Speerwerpen",
    "naam": "Niels Terol",
    "prestatie": {
      "code": "\"56,86\""
    },
    "plaats": "Breda",
    "datum": "28-06-1992",
    "categorie": "Junioren B",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Mannen",
    "datum": "24-09-1983",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"7,27\""
    },
    "einddatum": "",
    "categorie": "Junioren B",
    "onderdeel": "Verspringen",
    "plaats": "Den Haag",
    "naam": "Hein Macnack"
  },
  {
    "onderdeel": "1 000 m",
    "naam": "Vincent van der Lans",
    "prestatie": {
      "code": "\"2.47,2\""
    },
    "plaats": "Haarlem",
    "datum": "06-10-1996",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "100 m horden (84 cm)*",
    "naam": "Kan Dinkla",
    "prestatie": {
      "code": "\"14,43\""
    },
    "plaats": "Emmeloord",
    "datum": "26-09-2004",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "100 m horden (91,4 cm)*",
    "naam": "Chris van der Werff",
    "prestatie": {
      "code": "\"15,14 (14,9)\""
    },
    "plaats": "Heerhugowaard",
    "datum": "17-08-1985",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "100 m",
    "naam": "Kan Dinkla",
    "prestatie": {
      "code": "\"11,76\""
    },
    "plaats": "Emmeloord",
    "datum": "25-09-2004",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "1 500 m",
    "naam": "Walter Salzmann",
    "prestatie": {
      "code": "\"4.29,7\""
    },
    "plaats": "Amsterdam",
    "datum": "02-09-1973",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "150 m",
    "naam": "Sven Koolmoes",
    "prestatie": {
      "code": "\"19,14\""
    },
    "plaats": "Amsterdam",
    "datum": "25-05-2019",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "300 m horden",
    "naam": "Chris van der Werff",
    "prestatie": {
      "code": "\"41,74 (41,5)\""
    },
    "plaats": "Amsterdam",
    "datum": "25-08-1985",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Mannen",
    "datum": "13-06-1987",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"47,54\""
    },
    "einddatum": "",
    "categorie": "Junioren C",
    "onderdeel": "4 x 100 m",
    "plaats": "Beverwijk",
    "naam": "Patrick Been, Stefan van Keulen, Martijn te Velden, Ricardo Wielingen"
  },
  {
    "onderdeel": "4 x 200 m",
    "naam": "Tymo Bouman, Sven Koolmoes, Chivaro Friendwijk, Jarno Enthoven",
    "prestatie": {
      "code": "\"1.45,55\""
    },
    "plaats": "Haarlem",
    "datum": "05-07-2019",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Mannen",
    "datum": "31-03-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.37,28\""
    },
    "categorie": "Junioren C",
    "onderdeel": "600 m",
    "link": "https://www.atletiek.nu/atleet/main/737347/",
    "plaats": "Haarlem",
    "naam": "Jeppe Mul"
  },
  {
    "onderdeel": "800 m",
    "naam": "Vincent van der Lans",
    "prestatie": {
      "code": "\"2.05,5\""
    },
    "plaats": "Haarlem",
    "datum": "18-09-1996",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Achtkamp",
    "naam": "Alex Overkamp",
    "plaats": "Schoonhoven",
    "datum": "01/02-07-2006",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"100 m\",\n        \"prestatie\": \"12,44\",\n        \"punten\": \"(753)\"\n    },\n    {\n        \"onderdeel\": \"Verspringen\",\n        \"prestatie\": \"5,68\",\n        \"punten\": \"(751)\"\n    },\n    {\n        \"onderdeel\": \"Kogelstoten\",\n        \"prestatie\": \"10,84\",\n        \"punten\": \"(562)\"\n    },\n    {\n        \"onderdeel\": \"Hoogspringen\",\n        \"prestatie\": \"1,65\",\n        \"punten\": \"(741)\"\n    },\n    {\n        \"onderdeel\": \"100 m horden\",\n        \"prestatie\": \"15,14\",\n        \"punten\": \"(712)\"\n    },\n    {\n        \"onderdeel\": \"Discuswerpen\",\n        \"prestatie\": \"32,03\",\n        \"punten\": \"(505)\"\n    },\n    {\n        \"onderdeel\": \"Speerwerpen\",\n        \"prestatie\": \"39,94\",\n        \"punten\": \"(639)\"\n    },\n    {\n        \"onderdeel\": \"1 000 m\",\n        \"prestatie\": \"3.00,9\",\n        \"punten\": \"(692)\"\n    },\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"5355 pnt\"\n    }\n]"
    },
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Discuswerpen",
    "naam": "Dennis Kruithof",
    "prestatie": {
      "code": "\"46,50\""
    },
    "plaats": "Haarlem",
    "datum": "21-06-1980",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Gewichtwerpen",
    "naam": "Finn Damhoff",
    "prestatie": {
      "code": "\"11,75\""
    },
    "plaats": "Rotterdam",
    "datum": "20-04-2019",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Hoogspringen",
    "naam": "Niels Pijnaker",
    "prestatie": {
      "code": "\"1,80\""
    },
    "plaats": "Krommenie",
    "datum": "04-09-2004",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Kogelslingeren",
    "naam": "Finn Damhoff",
    "prestatie": {
      "code": "\"27,86\""
    },
    "plaats": "Rotterdam",
    "datum": "20-04-2019",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Kogelstoten",
    "naam": "Eric Noom",
    "prestatie": {
      "code": "\"14,11\""
    },
    "plaats": "Amsterdam",
    "datum": "28-08-1983",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Polsstokhoogspringen",
    "naam": "Olivier Barreveld",
    "prestatie": {
      "code": "\"3,10\""
    },
    "plaats": "Hoorn",
    "datum": "19-09-1992",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Speerwerpen",
    "naam": "Dennis Kruithof",
    "prestatie": {
      "code": "\"50,76\""
    },
    "plaats": "Haarlem",
    "datum": "23-08-1980",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Verspringen",
    "naam": "Eldridge Isselt",
    "prestatie": {
      "code": "\"6,34\""
    },
    "plaats": "Amsterdam",
    "datum": "25-08-1991",
    "categorie": "Junioren C",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "1 000 m",
    "naam": "Peter Markwat",
    "prestatie": {
      "code": "\"3.04,3\""
    },
    "plaats": "Haarlem",
    "datum": "06-10-1991",
    "categorie": "Junioren D",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "20-09-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12.23,00\""
    },
    "einddatum": "",
    "categorie": "Junioren D",
    "onderdeel": "3 000 m",
    "plaats": "Haarlem",
    "naam": "Ryan Eelman"
  },
  {
    "onderdeel": "4 x 80 m",
    "naam": "Tymo Bouman, Chivaro Friendwijk, Derk Hoefman, Sven Koolmoes",
    "prestatie": {
      "code": "\"41,12\""
    },
    "plaats": "Amsterdam",
    "datum": "30-06-2018",
    "categorie": "Junioren D",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Mannen",
    "handgeklokt": false,
    "datum": "20-09-2018",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"21.09,70\""
    },
    "einddatum": "",
    "categorie": "Junioren D",
    "onderdeel": "5 000 m",
    "plaats": "Haarlem",
    "naam": "Derk Hoefman"
  },
  {
    "onderdeel": "80 m horden",
    "naam": "Derk Hoefman",
    "prestatie": {
      "code": "\"13,13\""
    },
    "plaats": "Leiden",
    "datum": "19-09-2018",
    "categorie": "Junioren D",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "80 m",
    "naam": "Michael Parkinson",
    "prestatie": {
      "code": "\"10,44 (10,2)\""
    },
    "plaats": "Haarlem",
    "datum": "06-08-1988",
    "categorie": "Junioren D",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Balwerpen",
    "naam": "Dennis Kruithof",
    "prestatie": {
      "code": "\"60,0\""
    },
    "plaats": "Haarlem",
    "datum": "13-10-1977",
    "categorie": "Junioren D",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Discuswerpen",
    "naam": "Dennis Kruithof",
    "prestatie": {
      "code": "\"35,32\""
    },
    "plaats": "Haarlem",
    "datum": "01-07-1978",
    "categorie": "Junioren D",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Hoogspringen",
    "naam": "Marvin Isselt",
    "prestatie": {
      "code": "\"1,60\""
    },
    "plaats": "Amstelveen",
    "datum": "02-06-1984",
    "categorie": "Junioren D",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Kogelstoten",
    "naam": "Dennis Kruithof",
    "prestatie": {
      "code": "\"12,30\""
    },
    "plaats": "Zaandam",
    "datum": "20-05-1978",
    "categorie": "Junioren D",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Speerwerpen",
    "naam": "Thomas Wouterse",
    "prestatie": {
      "code": "\"42,00\""
    },
    "plaats": "Alkmaar",
    "datum": "12-05-2001",
    "categorie": "Junioren D",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Verspringen",
    "naam": "Edward Leter",
    "prestatie": {
      "code": "\"5,40\""
    },
    "plaats": "Beverwijk",
    "datum": "01-09-1984",
    "categorie": "Junioren D",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Mannen",
    "datum": "02/03-07-2011",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"80 m\",\n        \"prestatie\": \"11,14\",\n        \"punten\": \"(596)\"\n    },\n    {\n        \"onderdeel\": \"Verspringen\",\n        \"prestatie\": \"4,70\",\n        \"punten\": \"(560)\"\n    },\n    {\n        \"onderdeel\": \"Kogelstoten\",\n        \"prestatie\": \"9,82\",\n        \"punten\": \"(514)\"\n    },\n    {\n        \"onderdeel\": \"80 m horden\",\n        \"prestatie\": \"14,24\",\n        \"punten\": \"(466)\"\n    },\n    {\n        \"onderdeel\": \"Hoogspringen\",\n        \"prestatie\": \"1,45\",\n        \"punten\": \"(582)\"\n    },\n    {\n        \"onderdeel\": \"Speerwerpen\",\n        \"prestatie\": \"32,18\",\n        \"punten\": \"(529)\"\n    },\n    {\n        \"onderdeel\": \"1 000 m\",\n        \"prestatie\": \"3.18,8\",\n        \"punten\": \"(554)\"\n    },\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"3801 pnt\"\n    }\n]"
    },
    "categorie": "Junioren D",
    "onderdeel": "Zevenkamp",
    "link": "https://www.avantri.nl/uitslagen/2011/avantri-meerkampen/",
    "plaats": "Schoonhoven",
    "naam": "Pim Pauel"
  },
  {
    "onderdeel": "1 000 m",
    "naam": "Jurgen Wielart",
    "prestatie": {
      "code": "\"2.24,85\""
    },
    "plaats": "Lisse",
    "datum": "18-05-2019",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "100 m",
    "naam": "Sammy Monsels",
    "prestatie": {
      "code": "\"10,49\""
    },
    "plaats": "Nijmegen",
    "datum": "19-07-1981",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "10 000 m",
    "naam": "Tom Wiggers",
    "prestatie": {
      "code": "\"28.33,74\""
    },
    "plaats": "Leiden",
    "datum": "14-06-2014",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Mannen",
    "datum": "25-03-1984",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"44.38,4\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "10 km snelwandelen",
    "plaats": "Rotterdam (baan)",
    "handgeklokt": true,
    "naam": "Frank van Ravensberg"
  },
  {
    "onderdeel": "110 m horden",
    "naam": "Marc Kok",
    "prestatie": {
      "code": "\"14,73\""
    },
    "plaats": "Krommenie",
    "datum": "25-06-1989",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "1 500 m",
    "naam": "Stijn Jaspers",
    "prestatie": {
      "code": "\"3.40,29\""
    },
    "plaats": "Hengelo",
    "datum": "12-07-1983",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "1 Engelse mijl",
    "naam": "Stijn Jaspers",
    "prestatie": {
      "code": "\"3.57,28\""
    },
    "plaats": "Koblenz (D)",
    "datum": "25-08-1982",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "2 000 m",
    "naam": "Stijn Jaspers",
    "prestatie": {
      "code": "\"5.05,9\""
    },
    "plaats": "Arnhem",
    "datum": "17-08-1983",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "20 km snelwandelen",
    "naam": "Frank van Ravensberg",
    "prestatie": {
      "code": "\"1.29.37,5\""
    },
    "plaats": "Sittard",
    "datum": "24-06-1984",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "3 000 m",
    "naam": "Stijn Jaspers",
    "prestatie": {
      "code": "\"7.47,40\""
    },
    "plaats": "Brussel (B)",
    "datum": "24-08-1984",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "3 000 m steeplechase",
    "naam": "Evert van Ravensberg",
    "prestatie": {
      "code": "\"8.48,0\""
    },
    "plaats": "Roosendaal",
    "datum": "28-08-1979",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor",
    "datum": "09-08-2013",
    "naam": "Jurgen Wielart",
    "onderdeel": "300 m",
    "prestatie": {
      "code": "\"33,28\""
    },
    "plaats": "Utrecht"
  },
  {
    "onderdeel": "30 km snelwandelen",
    "naam": "Frank van Ravensberg",
    "prestatie": {
      "code": "\"2.34.11\""
    },
    "plaats": "Stiphout",
    "datum": "24-10-1982",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "400 m horden",
    "naam": "Frank Versteeg",
    "prestatie": {
      "code": "\"53,88\""
    },
    "plaats": "Hengelo",
    "datum": "21-05-1988",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "400 m",
    "naam": "Jurgen Wielart",
    "prestatie": {
      "code": "\"45,83\""
    },
    "plaats": "Amsterdam",
    "datum": "21-07-2013",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Mannen",
    "datum": "13-09-1980",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"41,67\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 100 m",
    "plaats": "Den Haag",
    "naam": "Sammy Monsels, Mario Westbroek, Henk Macnack, Mike Bienfait"
  },
  {
    "onderdeel": "4 x 1 500 m",
    "naam": "Dan Portegijs, Pieter Fris, Evert van Ravensberg, Stijn Jaspers",
    "prestatie": {
      "code": "\"15.56,73\""
    },
    "plaats": "Vught",
    "datum": "12-09-1981",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "4 x 200 m",
    "naam": "Jan Willen v.d. Wal, Nilo Emerenciana, Mike Bienfait, Mario Westbroek",
    "prestatie": {
      "code": "\"1.25,29\""
    },
    "plaats": "Zwolle",
    "datum": "23-05-1982",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "4 x 400 m",
    "naam": "Nilo Emerenciana, Allen Ellsworth, Mike Bienfait, Mario Westbroek",
    "prestatie": {
      "code": "\"3.14,1\""
    },
    "plaats": "Haarlem",
    "datum": "14-07-1982",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "4 x 800 m",
    "naam": "Richard Cabri, Dan Portegijs, Evert van Ravensberg, Michel Hulsman",
    "prestatie": {
      "code": "\"7.50,1\""
    },
    "plaats": "Den Haag",
    "datum": "01-07-1978",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "5 000 m",
    "naam": "Stijn Jaspers",
    "prestatie": {
      "code": "\"13.24,46\""
    },
    "plaats": "Leuven (B)",
    "datum": "01-06-1984",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "50 km snelwandelen",
    "naam": "Frank van Ravensberg",
    "prestatie": {
      "code": "\"4.47.05\""
    },
    "plaats": "Stiphout",
    "datum": "24-10-1982",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor",
    "datum": "31-03-2019",
    "naam": "Jurgen Wielart",
    "onderdeel": "600 m",
    "prestatie": {
      "code": "\"1.17,93\""
    },
    "plaats": "Haarlem"
  },
  {
    "geslacht": "Mannen",
    "datum": "29-05-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.46,80\""
    },
    "categorie": "Senioren",
    "onderdeel": "800 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/34571/800m/",
    "plaats": "Nijmegen",
    "naam": "Jurgen Wielart"
  },
  {
    "geslacht": "Mannen",
    "datum": "11-07-1982",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"50,02\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Krommenie",
    "naam": "Les Brown"
  },
  {
    "onderdeel": "Hinkstapspringen",
    "naam": "Aafbrecht vd Veen",
    "prestatie": {
      "code": "\"16,11\""
    },
    "plaats": "Vught",
    "datum": "03-08-1986",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Hoogspringen",
    "naam": "Ruud Wielart",
    "prestatie": {
      "code": "\"2,28\""
    },
    "plaats": "Leiden",
    "datum": "01-09-1979",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Kogelslingeren",
    "naam": "Schelto Scheltens",
    "prestatie": {
      "code": "\"56,18\""
    },
    "plaats": "Lisse",
    "datum": "07-05-1994",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Kogelstoten",
    "naam": "Rob Bakker",
    "prestatie": {
      "code": "\"17,01\""
    },
    "plaats": "Zoetermeer",
    "datum": "04-06-1992",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Polsstokhoogspringen",
    "naam": "Pim Göbel",
    "prestatie": {
      "code": "\"4,90\""
    },
    "plaats": "Merksem (B)",
    "datum": "22-06-1980",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Speerwerpen",
    "naam": "Niels Terol",
    "prestatie": {
      "code": "\"67,08\""
    },
    "plaats": "Haarlem",
    "datum": "13-06-1997",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Tienkamp",
    "naam": "Jeltjo Doornbosch",
    "plaats": "Vlissingen",
    "datum": "15/16-09-1962",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"100 m\",\n        \"prestatie\": \"11,44\",\n        \"punten\": \"(765)\"\n    },\n    {\n        \"onderdeel\": \"Verspringen\",\n        \"prestatie\": \"6,68\",\n        \"punten\": \"(739)\"\n    },\n    {\n        \"onderdeel\": \"Kogelstoten\",\n        \"prestatie\": \"13,64\",\n        \"punten\": \"(706)\"\n    },\n    {\n        \"onderdeel\": \"Hoogspringen\",\n        \"prestatie\": \"1,65\",\n        \"punten\": \"(504)\"\n    },\n    {\n        \"onderdeel\": \"400 m\",\n        \"prestatie\": \"52,74\",\n        \"punten\": \"(693)\"\n    },\n    {\n        \"onderdeel\": \"110 m horden\",\n        \"prestatie\": \"15,94\",\n        \"punten\": \"(740)\"\n    },\n    {\n        \"onderdeel\": \"Discuswerpen\",\n        \"prestatie\": \"39,30\",\n        \"punten\": \"(650)\"\n    },\n    {\n        \"onderdeel\": \"Polsstokhoogspringen\",\n        \"prestatie\": \"3,25\",\n        \"punten\": \"(418)\"\n    },\n    {\n        \"onderdeel\": \"Speerwerpen\",\n        \"prestatie\": \"54,90\",\n        \"punten\": \"(661)\"\n    },\n    {\n        \"onderdeel\": \"1500 m\",\n        \"prestatie\": \"4.41,8\",\n        \"punten\": \"(669)\"\n    },\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"6545 pnt\"\n    }\n]"
    },
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Mannen",
    "datum": "29-05-1988",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"7,56\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Amsterdam",
    "naam": "Hein Macnack"
  },
  {
    "onderdeel": "Zweedse estafette",
    "naam": "Jurgen Wielart, Vester van Heijningen, Roeland Heitkönig, Stefan Scholte",
    "prestatie": {
      "code": "\"1.56,93\""
    },
    "plaats": "Hilversum",
    "datum": "08-09-2019",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/32178/Zweedse/#170331_",
    "categorie": "Senioren",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "100 km",
    "naam": "Wim Kluyskens",
    "prestatie": {
      "code": "\"7.39.53\""
    },
    "datum": "24-09-1977",
    "plaats": "Winschoten",
    "categorie": "Wegatletiek",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "10 Engelse mijl",
    "naam": "Tom Wiggers",
    "prestatie": {
      "code": "\"47.24\""
    },
    "datum": "06-10-2013",
    "plaats": "Bergen",
    "categorie": "Wegatletiek",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "10 km",
    "naam": "Tom Wiggers",
    "prestatie": {
      "code": "\"29.05\""
    },
    "datum": "12-02-2012",
    "plaats": "Schoorl",
    "categorie": "Wegatletiek",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "10 x 10 km",
    "naam": "Daan, Niels, Viktor, Frank, Marco, Boudewijn, Adrie, Cor, Sander, Gerlies",
    "prestatie": {
      "code": "\"7.24.54\""
    },
    "datum": "10-09-2016",
    "plaats": "Winschoten",
    "categorie": "Wegatletiek",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "15 km",
    "naam": "Tom Wiggers",
    "prestatie": {
      "code": "\"44.42\""
    },
    "datum": "17-11-2013",
    "plaats": "Nijmegen",
    "categorie": "Wegatletiek",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "1 uur",
    "naam": "Kees van Dommele",
    "prestatie": {
      "code": "\"18,262 km\""
    },
    "datum": "04-06-1990",
    "plaats": "Dordrecht",
    "categorie": "Wegatletiek",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "20 km",
    "naam": "Kees van Dommele",
    "prestatie": {
      "code": "\"1.03.55\""
    },
    "datum": "30-04-1991",
    "plaats": "Beverwijk",
    "categorie": "Wegatletiek",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "25 km",
    "naam": "Kees van Dommele",
    "prestatie": {
      "code": "\"1.21.08\""
    },
    "datum": "23-09-1989",
    "plaats": "Zevenbergen",
    "categorie": "Wegatletiek",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "30 km",
    "naam": "Kees van Dommele",
    "prestatie": {
      "code": "\"1.42.42\""
    },
    "datum": "01-04-1990",
    "plaats": "Spaarnwoude",
    "categorie": "Wegatletiek",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "35 km",
    "naam": "Krijn Kroezen",
    "prestatie": {
      "code": "\"2.23.13\""
    },
    "datum": "20-06-1964",
    "plaats": "Vught",
    "categorie": "Wegatletiek",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Halve marathon",
    "naam": "Tom Wiggers",
    "prestatie": {
      "code": "\"1.03,40\""
    },
    "datum": "13-01-2013",
    "plaats": "Egmond",
    "categorie": "Wegatletiek",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Marathon",
    "naam": "Kees van Dommele",
    "prestatie": {
      "code": "\"2.25.02\""
    },
    "datum": "03-02-1990",
    "plaats": "Apeldoorn",
    "categorie": "Wegatletiek",
    "geslacht": "Mannen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "1 500 m",
    "naam": "Larissa Scholten",
    "prestatie": {
      "code": "\"5.16,60\""
    },
    "plaats": "Groningen",
    "datum": "28-01-2007",
    "categorie": "Junioren A",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "400 m",
    "naam": "Chantal Bruijn",
    "prestatie": {
      "code": "\"62,75\""
    },
    "plaats": "Den Haag",
    "datum": "16-02-1991",
    "categorie": "Junioren A",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "50 m",
    "naam": "Marjanne van Sambeek (B)",
    "prestatie": {
      "code": "\"7,04\""
    },
    "plaats": "Amsterdam",
    "datum": "05-02-1995",
    "categorie": "Junioren A",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "24-02-1973",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"9,1\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "60 m horden",
    "plaats": "Leiden",
    "naam": "Anja Smits"
  },
  {
    "onderdeel": "60 m",
    "naam": "Anja Smits",
    "prestatie": {
      "code": "\"8,24\""
    },
    "plaats": "Leiden",
    "datum": "24-02-1973",
    "categorie": "Junioren A",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "800 m",
    "naam": "Jirina van Kesteren",
    "prestatie": {
      "code": "\"2.32,41\""
    },
    "plaats": "Zuidbroek",
    "datum": "25-01-2004",
    "categorie": "Junioren A",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Hoogspringen",
    "naam": "Tara van Schie",
    "prestatie": {
      "code": "\"1.56\""
    },
    "plaats": "Gent (B)",
    "datum": "03-02-2008",
    "categorie": "Junioren A",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Kogelstoten",
    "naam": "Wendy IJzerman",
    "prestatie": {
      "code": "\"11.20\""
    },
    "plaats": "Den Haag",
    "datum": "26-01-1997",
    "categorie": "Junioren A",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "10-02-1973",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"5,56\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "Verspringen",
    "plaats": "Leiden",
    "naam": "Anja Smits"
  },
  {
    "onderdeel": "Vijfkamp",
    "naam": "Tara van Schie",
    "plaats": "Gent (B)",
    "datum": "03-02-2008",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"60 m horden\",\n        \"prestatie\": \"9,72\",\n        \"punten\": \"(756)\"\n    },\n    {\n        \"onderdeel\": \"Hoogspringen\",\n        \"prestatie\": \"1.56\",\n        \"punten\": \"(689)\"\n    },\n    {\n        \"onderdeel\": \"Kogelstoten\",\n        \"prestatie\": \"9.72\",\n        \"punten\": \"(511)\"\n    },\n    {\n        \"onderdeel\": \"Verspringen\",\n        \"prestatie\": \"5.38\",\n        \"punten\": \"(665)\"\n    },\n    {\n        \"onderdeel\": \"800 m\",\n        \"prestatie\": \"3.07,09\",\n        \"punten\": \"(302)\"\n    },\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"2932\"\n    }\n]"
    },
    "categorie": "Junioren A",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "400 m",
    "naam": "Jirina van Kesteren",
    "prestatie": {
      "code": "\"63,45\""
    },
    "plaats": "Zuidbroek",
    "datum": "25-01-2004",
    "categorie": "Junioren B",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "50 m",
    "naam": "Marjanne van Sambeek",
    "prestatie": {
      "code": "\"7,04\""
    },
    "plaats": "Amsterdam",
    "datum": "05-02-1995",
    "categorie": "Junioren B",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "60 m horden",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "\"9,37\""
    },
    "plaats": "Assendelft",
    "datum": "24-11-2019",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/32242/60mH_f/#171003_",
    "categorie": "Junioren B",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "60 m",
    "naam": "Marjanne van Sambeek (C)",
    "prestatie": {
      "code": "\"8,34\""
    },
    "plaats": "Den Haag",
    "datum": "29-11-1992",
    "categorie": "Junioren B",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "800 m",
    "naam": "Jirina van Kesteren",
    "prestatie": {
      "code": "\"2.32,41\""
    },
    "plaats": "Zuidbroek",
    "datum": "25-01-2004",
    "categorie": "Junioren B",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Hoogspringen",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "\"1.51\""
    },
    "plaats": "Assendelft",
    "datum": "15-12-2019",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/32243/Hoog/#171013_",
    "categorie": "Junioren B",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Kogelstoten",
    "naam": "Wendy IJzerman",
    "prestatie": {
      "code": "\"12.37\""
    },
    "plaats": "Den Haag",
    "datum": "26-02-1995",
    "categorie": "Junioren B",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Verspringen",
    "naam": "Tara van Schie",
    "prestatie": {
      "code": "\"5.12\""
    },
    "plaats": "Groningen",
    "datum": "29-01-2006",
    "categorie": "Junioren B",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Vijfkamp",
    "naam": "Babs Koeman",
    "plaats": "Apeldoorn",
    "datum": "09-02-2020",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"60 m horden\",\n        \"prestatie\": \"9,59\",\n        \"punten\": \"(791)\"\n    },\n    {\n        \"onderdeel\": \"Hoogspringen\",\n        \"prestatie\": \"1.51\",\n        \"punten\": \"(632)\"\n    },\n    {\n        \"onderdeel\": \"Kogelstoten\",\n        \"prestatie\": \"9.77\",\n        \"punten\": \"(514)\"\n    },\n    {\n        \"onderdeel\": \"Verspringen\",\n        \"prestatie\": \"5.04\",\n        \"punten\": \"(570)\"\n    },\n    {\n        \"onderdeel\": \"800 m\",\n        \"prestatie\": \"2.32,72\",\n        \"punten\": \"(659)\"\n    },\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"3166\"\n    }\n]"
    },
    "link": "https://www.atletiek.nu/wedstrijd/vereniging/32514/48/",
    "categorie": "Junioren B",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "1 000 m",
    "naam": "Joanna Andrews",
    "prestatie": {
      "code": "\"3.34,3\""
    },
    "plaats": "Den Haag",
    "datum": "18-03-1990",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "200 m",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "\"28,08\""
    },
    "plaats": "Apeldoorn",
    "datum": "23-12-2018",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "50 m",
    "naam": "Marjanne van Sambeek",
    "prestatie": {
      "code": "\"7,24\""
    },
    "plaats": "Utrecht",
    "datum": "07-11-1992",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "600 m",
    "naam": "Marjan Witteman",
    "prestatie": {
      "code": "\"1.50,1\""
    },
    "plaats": "Den Haag",
    "datum": "21-01-1990",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "60 m horden",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "\"9,69\""
    },
    "plaats": "Utrecht",
    "datum": "10-11-2018",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "60 m",
    "naam": "Marjanne van Sambeek",
    "prestatie": {
      "code": "\"8,34\""
    },
    "plaats": "Den Haag",
    "datum": "29-11-1992",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Hoogspringen",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "\"1.50\""
    },
    "plaats": "Apeldoorn",
    "datum": "21-01-2018",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Kogelstoten",
    "naam": "Sanna Piscaer",
    "prestatie": {
      "code": "\"10.45\""
    },
    "plaats": "Den Haag",
    "datum": "03-03-1996",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Polsstokhoogspringen",
    "naam": "Mette Elgersma",
    "prestatie": {
      "code": "\"2.30\""
    },
    "plaats": "Apeldoorn",
    "datum": "22-01-2017",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Verspringen",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "\"5.15\""
    },
    "plaats": "Apeldoorn",
    "datum": "23-12-2018",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "1 000 m",
    "naam": "Joanna Andrews",
    "prestatie": {
      "code": "\"3.34,3\""
    },
    "plaats": "Den Haag",
    "datum": "18-03-1990",
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "50 m",
    "naam": "Marjanne van Sambeek",
    "prestatie": {
      "code": "\"7,24\""
    },
    "plaats": "Utrecht",
    "datum": "29-02-1992",
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "600 m",
    "naam": "Marjan Witteman",
    "prestatie": {
      "code": "\"1.50,1\""
    },
    "plaats": "Den Haag",
    "datum": "21-01-1990",
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "60 m horden",
    "naam": "Baltine de Wit",
    "prestatie": {
      "code": "\"9,60\""
    },
    "plaats": "Den Haag",
    "datum": "06-03-1994",
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "60 m",
    "naam": "Marjanne van Sambeek",
    "prestatie": {
      "code": "\"8,64\""
    },
    "plaats": "Den Haag",
    "datum": "10-03-1991",
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Hoogspringen",
    "naam": "Joanna Andrews",
    "prestatie": {
      "code": "\"1.45\""
    },
    "plaats": "Zaandam",
    "datum": "15-02-1992",
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Kogelstoten",
    "naam": "Sanna Piscaer",
    "prestatie": {
      "code": "\"10.45\""
    },
    "plaats": "Den Haag",
    "datum": "06-03-1994",
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Verspringen",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "\"4.67\""
    },
    "plaats": "Zoetermeer",
    "datum": "17-12-2016",
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "200 m",
    "naam": "Brenda Stoete",
    "prestatie": {
      "code": "\"26,61\""
    },
    "plaats": "Den Haag",
    "datum": "13-01-1991",
    "categorie": "Senioren",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "3 000 m",
    "naam": "Marianne van de Linde",
    "prestatie": {
      "code": "\"9.32,21\""
    },
    "plaats": "Den Haag",
    "datum": "16-02-1992",
    "categorie": "Senioren",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "400 m",
    "naam": "Kristel van Es",
    "prestatie": {
      "code": "\"61,66\""
    },
    "plaats": "Apeldoorn",
    "datum": "13-02-2010",
    "categorie": "Senioren",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "05-02-1995",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"6,8\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "50 m",
    "plaats": "Amsterdam",
    "naam": "Marjanne van Sambeek (B)"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "24-02-1973",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"9,1\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "60 m horden",
    "plaats": "Leiden",
    "naam": "Anja Smits (A)"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "20-02-2021",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"7,87\""
    },
    "categorie": "Senioren",
    "onderdeel": "60 m",
    "link": "https://www.atletiek.nu/wedstrijd/startlijst/343017/3/",
    "plaats": "Apeldoorn",
    "naam": "Femke Nuijten"
  },
  {
    "onderdeel": "800 m",
    "naam": "Jirina van Kesteren",
    "prestatie": {
      "code": "\"2.32,41\""
    },
    "plaats": "Zuidbroek",
    "datum": "25-01-2004",
    "categorie": "Senioren",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "20-02-1994",
    "locatie": "indoor",
    "prestatie": {
      "code": "\"12,10\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Den Haag",
    "naam": "Mieke van der Kolk"
  },
  {
    "onderdeel": "Hoogspringen",
    "naam": "Tara van Schie",
    "prestatie": {
      "code": "\"1.56\""
    },
    "plaats": "Gent (B)",
    "datum": "03-02-2008",
    "categorie": "Senioren",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Kogelstoten",
    "naam": "Wendy IJzerman",
    "prestatie": {
      "code": "\"11.20\""
    },
    "plaats": "Den Haag",
    "datum": "26-01-1997",
    "categorie": "Senioren",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Verspringen",
    "naam": "Mieke van der Kolk",
    "prestatie": {
      "code": "\"6.16\""
    },
    "plaats": "Zwolle",
    "datum": "22-01-1994",
    "categorie": "Senioren",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "Vijfkamp",
    "naam": "Tara van Schie",
    "plaats": "Gent (B)",
    "datum": "03-02-2008",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"60 m horden\",\n        \"prestatie\": \"9,72\",\n        \"punten\": \"(756)\"\n    },\n    {\n        \"onderdeel\": \"Hoogspringen\",\n        \"prestatie\": \"1.56\",\n        \"punten\": \"(689)\"\n    },\n    {\n        \"onderdeel\": \"Kogelstoten\",\n        \"prestatie\": \"9.72\",\n        \"punten\": \"(511)\"\n    },\n    {\n        \"onderdeel\": \"Verspringen\",\n        \"prestatie\": \"5.38\",\n        \"punten\": \"(665)\"\n    },\n    {\n        \"onderdeel\": \"800 m\",\n        \"prestatie\": \"3.07,09\",\n        \"punten\": \"(302)\"\n    },\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"2932\"\n    }\n]"
    },
    "categorie": "Senioren",
    "geslacht": "Vrouwen",
    "locatie": "indoor"
  },
  {
    "onderdeel": "1 000 m",
    "naam": "Larissa Scholten (C)",
    "prestatie": {
      "code": "\"3.10,64\""
    },
    "plaats": "Utrecht",
    "datum": "22-06-2002",
    "categorie": "Junioren A",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "26-06-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"15,04\""
    },
    "categorie": "Junioren A",
    "onderdeel": "100 m horden",
    "link": "https://www.atletiek.nu/wedstrijd/startlijst/366767/50/",
    "plaats": "Breda",
    "naam": "Babs Koeman (B)"
  },
  {
    "onderdeel": "100 m",
    "naam": "Brenda Stoete (B)",
    "prestatie": {
      "code": "\"12,74\""
    },
    "plaats": "Krommenie",
    "datum": "24-07-1988",
    "categorie": "Junioren A",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "1 500 m",
    "naam": "Larissa Scholten",
    "prestatie": {
      "code": "\"5.02,39\""
    },
    "plaats": "Utrecht",
    "datum": "08-07-2007",
    "categorie": "Junioren A",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "200 m",
    "naam": "Brenda Stoete",
    "prestatie": {
      "code": "\"25,94\""
    },
    "plaats": "Amsterdam",
    "datum": "26-05-1990",
    "categorie": "Junioren A",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "12-09-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.06,87\""
    },
    "categorie": "Junioren A",
    "onderdeel": "400 m horden",
    "link": "https://www.atletiek.nu/wedstrijd/startgroep/326064/",
    "plaats": "Amersfoort",
    "naam": "Babs Koeman (B)"
  },
  {
    "onderdeel": "400 m",
    "naam": "Brenda Stoete",
    "prestatie": {
      "code": "\"1.00,64\""
    },
    "plaats": "Santpoort",
    "datum": "29-04-1990",
    "categorie": "Junioren A",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "4 x 100 m",
    "naam": "Aneska de Windt, Chantal Bruyn, Kivelly Grotendorst, Brenda Stoete",
    "prestatie": {
      "code": "\"50,64\""
    },
    "plaats": "Lisse",
    "datum": "24-06-1990",
    "categorie": "Junioren A",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "800 m",
    "naam": "Ingrid de Jager",
    "prestatie": {
      "code": "\"2.16,5\""
    },
    "plaats": "Arnhem",
    "datum": "27-06-1974",
    "categorie": "Junioren A",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "27-05-1988",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"23,26\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "Discuswerpen",
    "plaats": "Beverwijk",
    "naam": "Brenda Stoete"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "26-06-2004",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"11,16\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Sittard",
    "naam": "Sanne Immer"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "26-06-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,67\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "Hoogspringen",
    "link": "https://www.atletiek.nu/wedstrijd/startlijst/366767/13/",
    "plaats": "Breda",
    "naam": "Babs Koeman (B)"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "23-05-2001",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"10,79\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "Kogelstoten",
    "plaats": "Haarlem",
    "naam": "Baltine de Wit"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "23-08-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,71\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "Polsstokhoogspringen",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/32991/Polshoog/#208710_",
    "plaats": "Lisse",
    "naam": "Noa Claus (B)"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "27-06-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"36,97\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "Speerwerpen",
    "link": "https://www.atletiek.nu/wedstrijd/startlijst/366767/39/",
    "plaats": "Breda",
    "naam": "Babs Koeman (B)"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "20-05-1973",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5,73\""
    },
    "einddatum": "",
    "categorie": "Junioren A",
    "onderdeel": "Verspringen",
    "plaats": "Arnhem",
    "naam": "Anja Smits"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "26-06-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"100 m horden\",\n        \"prestatie\": \"15,04\",\n        \"punten\": \"(836)\"\n    },\n    {\n        \"onderdeel\": \"Hoogspringen\",\n        \"prestatie\": \"1.67\",\n        \"punten\": \"(818)\"\n    },\n    {\n        \"onderdeel\": \"Kogelstoten\",\n        \"prestatie\": \"8.40\",\n        \"punten\": \"(425)\"\n    },\n    {\n        \"onderdeel\": \"200 m\",\n        \"prestatie\": \"26,86\",\n        \"punten\": \"(724)\"\n    },\n    {\n        \"onderdeel\": \"Verspringen\",\n        \"prestatie\": \"5.61\",\n        \"punten\": \"(732)\"\n    },\n    {\n        \"onderdeel\": \"Speerwerpen\",\n        \"prestatie\": \"36.97\",\n        \"punten\": \"(609)\"\n    },\n    {\n        \"onderdeel\": \"800 m\",\n        \"prestatie\": \"2.23,67\",\n        \"punten\": \"(774)\"\n    },\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"4918 pnt\"\n    }\n]"
    },
    "einddatum": "27-06-2021",
    "categorie": "Junioren A",
    "onderdeel": "Zevenkamp",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagen/35336/229603/",
    "plaats": "Breda",
    "naam": "Babs Koeman (B)"
  },
  {
    "onderdeel": "1 000 m",
    "naam": "Larissa Scholten (C)",
    "prestatie": {
      "code": "\"3.10,64\""
    },
    "plaats": "Utrecht",
    "datum": "22-06-2002",
    "categorie": "Junioren B",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "05-06-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"14,75\""
    },
    "categorie": "Junioren B",
    "onderdeel": "100 m horden",
    "link": "meerkamp, 5 en 6 juni. https://www.atletiek.nu/wedstrijd/uitslagen/34648/222291/",
    "plaats": "Assendelft",
    "naam": "Babs Koeman"
  },
  {
    "onderdeel": "100 m",
    "naam": "Brenda Stoete",
    "prestatie": {
      "code": "\"12,74\""
    },
    "plaats": "Krommenie",
    "datum": "24-07-1988",
    "categorie": "Junioren B",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "1 500 m",
    "naam": "Jirina van Kesteren",
    "prestatie": {
      "code": "\"5.25,81\""
    },
    "plaats": "Haarlem",
    "datum": "13-08-2003",
    "categorie": "Junioren B",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "200 m",
    "naam": "Tara van Schie",
    "prestatie": {
      "code": "\"26,17\""
    },
    "plaats": "Best",
    "datum": "30-06-2007",
    "categorie": "Junioren B",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "12-09-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.06,87\""
    },
    "categorie": "Junioren B",
    "onderdeel": "400 m horden",
    "link": "https://www.atletiek.nu/wedstrijd/startgroep/326064/",
    "plaats": "Amersfoort",
    "naam": "Babs Koeman"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "09-08-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1.01,10\""
    },
    "categorie": "Junioren B",
    "onderdeel": "400 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/33857/400m/#207607_",
    "plaats": "Haarlem",
    "naam": "Vera Hylkema"
  },
  {
    "onderdeel": "4 x 100 m",
    "naam": "Babs Koeman, Mette Elgersma, Anna Hovinga, Marjolein Groot",
    "prestatie": {
      "code": "\"53,84\""
    },
    "plaats": "Heerhugowaard",
    "datum": "10-06-2018",
    "categorie": "Junioren B",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "20-09-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2.23,07\""
    },
    "categorie": "Junioren B",
    "onderdeel": "800 m",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagen/34175/213651/#meerkamponderdeel_1_6_",
    "plaats": "Haarlem",
    "naam": "Babs Koeman"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "18-04-1999",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"29,70\""
    },
    "einddatum": "",
    "categorie": "Junioren B",
    "onderdeel": "Discuswerpen",
    "plaats": "Haarlem",
    "naam": "Baltine de Wit"
  },
  {
    "onderdeel": "Hinkstapspringen",
    "naam": "Tara van Schie",
    "prestatie": {
      "code": "\"10.63\""
    },
    "plaats": "Utrecht",
    "datum": "08-07-2007",
    "categorie": "Junioren B",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "26-06-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,67\""
    },
    "einddatum": "",
    "categorie": "Junioren B",
    "onderdeel": "Hoogspringen",
    "link": "https://www.atletiek.nu/wedstrijd/startlijst/366767/13/",
    "plaats": "Breda",
    "naam": "Babs Koeman"
  },
  {
    "onderdeel": "Kogelstoten",
    "naam": "Tara van Schie",
    "prestatie": {
      "code": "\"12.14\""
    },
    "plaats": "Valkenswaard",
    "datum": "01-09-2007",
    "categorie": "Junioren B",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "23-08-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,71\""
    },
    "einddatum": "",
    "categorie": "Junioren B",
    "onderdeel": "Polsstokhoogspringen",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/32991/Polshoog/#208710_",
    "plaats": "Lisse",
    "naam": "Noa Claus"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "06-06-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"37.18\""
    },
    "categorie": "Junioren B",
    "onderdeel": "Speerwerpen",
    "link": " https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/35397/Speer/#230860_39",
    "plaats": "Assendelft",
    "naam": "Babs Koeman"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "04-07-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"5.66\""
    },
    "einddatum": "",
    "categorie": "Junioren B",
    "onderdeel": "Verspringen",
    "link": "https://www.atletiek.nu/wedstrijd/vereniging/35653/48/",
    "plaats": "Alphen aan de Rijn",
    "naam": "Babs Koeman"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "05-06-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"100 m horden\",\n        \"prestatie\": \"14,75\",\n        \"punten\": \"(875)\"\n    },\n    {\n        \"onderdeel\": \"Hoogspringen\",\n        \"prestatie\": \"1.66\",\n        \"punten\": \"(806)\"\n    },\n    {\n        \"onderdeel\": \"Kogelstoten\",\n        \"prestatie\": \"10.28\",\n        \"punten\": \"(548)\"\n    },\n    {\n        \"onderdeel\": \"200 m\",\n        \"prestatie\": \"26.54\",\n        \"punten\": \"(751)\"\n    },\n    {\n        \"onderdeel\": \"Verspringen\",\n        \"prestatie\": \"5.28\",\n        \"punten\": \"(637)\"\n    },\n    {\n        \"onderdeel\": \"Speerwerpen\",\n        \"prestatie\": \"37.18\",\n        \"punten\": \"(613)\"\n    },\n    {\n        \"onderdeel\": \"800 m\",\n        \"prestatie\": \"2.26,12\",\n        \"punten\": \"(742)\"\n    },\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"4972 pnt\"\n    }\n]"
    },
    "categorie": "Junioren B",
    "onderdeel": "Zevenkamp",
    "link": "5 en 6 juni 2021.   https://www.atletiek.nu/atleet/main/1025264/",
    "plaats": "Assendelft",
    "naam": "Babs Koeman"
  },
  {
    "onderdeel": "1 000 m",
    "naam": "Larissa Scholten",
    "prestatie": {
      "code": "\"3.10,64\""
    },
    "plaats": "Utrecht",
    "datum": "22-06-2002",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "1 500 m",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "\"5.15,44\""
    },
    "plaats": "Assendelft",
    "datum": "25-05-2018",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "150 m",
    "naam": "Tara van Schie",
    "prestatie": {
      "code": "\"19,54\""
    },
    "plaats": "Beverwijk",
    "datum": "03-07-2004",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "200 m",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "\"28,46\""
    },
    "plaats": "Edam",
    "datum": "13-05-2018",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "300 m horden",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "\"46,12\""
    },
    "plaats": "Amsterdam",
    "datum": "24-08-2019",
    "link": "https://www.atletiek.nu/wedstrijd/startlijst/226601/217/",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "300 m",
    "naam": "Vera Hijlkema",
    "prestatie": {
      "code": "\"43,60\""
    },
    "plaats": "Amsterdam",
    "datum": "26-08-2018",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "400 m",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "\"1.03,43\""
    },
    "plaats": "Heerhugowaard",
    "datum": "10-06-2018",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "4 x 80 m",
    "naam": "Sanna Piscaer, Janna de Waard, Marije Zandstra, Baltine de Wit",
    "prestatie": {
      "code": "\"41,94\""
    },
    "plaats": "Heerhugowaard",
    "datum": "17-06-1996",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "600 m",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "\"1.40,23\""
    },
    "plaats": "Amstelveen",
    "datum": "9-06-2019",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "800 m",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "\"2.31,56\""
    },
    "plaats": "Haarlem",
    "datum": "08-06-2018",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "80 m horden",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "\"12,38\""
    },
    "plaats": "Lisse",
    "datum": "15-06-2019",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "80 m",
    "naam": "Marjanne van Sambeek",
    "prestatie": {
      "code": "\"10,64\""
    },
    "plaats": "Amsterdam",
    "datum": "21-08-1993",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Discuswerpen",
    "naam": "Wendy IJzerman",
    "prestatie": {
      "code": "\"24.92\""
    },
    "plaats": "Beverwijk",
    "datum": "05-06-1993",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "19-08-2000",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,60\""
    },
    "einddatum": "",
    "categorie": "Junioren C",
    "onderdeel": "Hoogspringen",
    "plaats": "Krommenie",
    "naam": "Sanne Immer"
  },
  {
    "onderdeel": "Kogelstoten",
    "naam": "Wendy IJzerman",
    "prestatie": {
      "code": "\"12.07\""
    },
    "plaats": "Amersfoort",
    "datum": "24-04-1993",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Polsstokhoogspringen",
    "naam": "Mette Elgersma",
    "prestatie": {
      "code": "\"2.40\""
    },
    "plaats": "Heerhugowaard",
    "datum": "02-06-2017",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Speerwerpen",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "\"36.39\""
    },
    "plaats": "Zaandam",
    "datum": "12-05-2019",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Verspringen",
    "naam": "Marjanne van Sambeek",
    "prestatie": {
      "code": "\"5.31\""
    },
    "plaats": "Beverwijk",
    "datum": "05-06-1993",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Zevenkamp",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"80 m horden\",\n        \"prestatie\": \"12,42\",\n        \"punten\": \"(652)\"\n    },\n    {\n        \"onderdeel\": \"Hoogspringen\",\n        \"prestatie\": \"1.49\",\n        \"punten\": \"(615)\"\n    },\n    {\n        \"onderdeel\": \"Kogelstoten\",\n        \"prestatie\": \"9.30\",\n        \"punten\": \"(488)\"\n    },\n    {\n        \"onderdeel\": \"150 m\",\n        \"prestatie\": \"20.16\",\n        \"punten\": \"(604)\"\n    },\n    {\n        \"onderdeel\": \"Verspringen\",\n        \"prestatie\": \"5.07\",\n        \"punten\": \"(634)\"\n    },\n    {\n        \"onderdeel\": \"Speerwerpen\",\n        \"prestatie\": \"34.74\",\n        \"punten\": \"(566)\"\n    },\n    {\n        \"onderdeel\": \"600 m\",\n        \"prestatie\": \"1.40,23\",\n        \"punten\": \"(689)\"\n    },\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"4248 pnt\"\n    }\n]"
    },
    "plaats": "Amstelveen",
    "datum": "9/10-06-2019",
    "categorie": "Junioren C",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "1 000 m",
    "naam": "Pien Roozen",
    "prestatie": {
      "code": "\"00:03:15,85\""
    },
    "plaats": "Amsterdam",
    "datum": "07-09-2003",
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "1 500 m",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "\"00:05:37,07\""
    },
    "plaats": "Hoofddorp",
    "datum": "15-04-2016",
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "150 m",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "\"00:00:21,03\""
    },
    "plaats": "Naaldwijk",
    "datum": "20-08-2017",
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "400 m",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "\"00:01:05,03\""
    },
    "plaats": "Barendrecht",
    "datum": "09-09-2017",
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "4 x 60 m",
    "naam": "Jessica Ramakers, Wendy Twisk, Marjanne van Sambeek, Joanna Andrews",
    "prestatie": {
      "code": "\"33,54\""
    },
    "plaats": "Santpoort",
    "datum": "22-06-1991",
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "600 m",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "\"00:01:45,26\""
    },
    "plaats": "Zoetermeer",
    "datum": "23-09-2017",
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "60 m horden",
    "naam": "Joanna Andrews",
    "prestatie": {
      "code": "\"00:00:10,14\""
    },
    "plaats": "Krommenie",
    "datum": "28-05-1992",
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "60 m",
    "naam": "Marjanne van Sambeek",
    "prestatie": {
      "code": "\"00:00:08,14\""
    },
    "plaats": "Krommenie",
    "datum": "28-05-1992",
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "800 m",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "\"00:02:41,46\""
    },
    "plaats": "Beverwijk",
    "datum": "27-05-2016",
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Discuswerpen",
    "naam": "Anne Kuperus",
    "prestatie": {
      "code": "\"23,23\""
    },
    "plaats": "Amsterdam",
    "datum": "08-09-2013",
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "16-09-1995",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,55\""
    },
    "einddatum": "",
    "categorie": "Junioren D",
    "onderdeel": "Hoogspringen",
    "plaats": "Enschede",
    "naam": "Baltine de Wit"
  },
  {
    "onderdeel": "Kogelstoten",
    "naam": "Sanna Piscaer",
    "prestatie": {
      "code": "\"11,80\""
    },
    "plaats": "Hilversum",
    "datum": "18-06-1994",
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Polsstokhoogspringen",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "\"1,85\""
    },
    "plaats": "Haarlem",
    "datum": "19-05-2017",
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Speerwerpen",
    "naam": "Babs Koeman",
    "prestatie": {
      "code": "\"31,48\""
    },
    "plaats": "Naaldwijk",
    "datum": "20-08-2017",
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Verspringen",
    "naam": "Marjanne van Sambeek",
    "prestatie": {
      "code": "\"5,12\""
    },
    "plaats": "Amsterdam",
    "datum": "13-06-1992",
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Zeskamp",
    "naam": "Babs Koeman",
    "plaats": "Lisse",
    "datum": "08-07-2017",
    "einddatum": "09-07-2017",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"60 m horden\",\n        \"prestatie\": \"00:00:10,41\",\n        \"punten\": \"(554)\"\n    },\n    {\n        \"onderdeel\": \"Hoogspringen\",\n        \"prestatie\": \"1,47\",\n        \"punten\": \"(599)\"\n    },\n    {\n        \"onderdeel\": \"Speerwerpen\",\n        \"prestatie\": \"28,59\",\n        \"punten\": \"(473)\"\n    },\n    {\n        \"onderdeel\": \"Verspringen\",\n        \"prestatie\": \"4,91\",\n        \"punten\": \"(603)\"\n    },\n    {\n        \"onderdeel\": \"Kogelstoten\",\n        \"prestatie\": \"9,52\",\n        \"punten\": \"(499)\"\n    },\n    {\n        \"onderdeel\": \"600 m\",\n        \"prestatie\": \"00:01:46,00\",\n        \"punten\": \"(602)\"\n    },\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"3330 pnt\"\n    }\n]"
    },
    "categorie": "Junioren D",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "1 000 m",
    "naam": "Larissa Scholten",
    "prestatie": {
      "code": "\"00.02.57,26\""
    },
    "plaats": "Lisse",
    "datum": "14-05-2011",
    "categorie": "Senioren",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "26-06-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.15,04\""
    },
    "categorie": "Senioren",
    "onderdeel": "100 m horden",
    "link": "https://www.atletiek.nu/wedstrijd/startlijst/366767/50/",
    "plaats": "Breda",
    "naam": "Babs Koeman (B)"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "04-07-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.11,96\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "100 m",
    "link": "https://www.atletiek.nu/wedstrijd/vereniging/35653/48/",
    "plaats": "Alphen aan de Rijn",
    "naam": "Femke Nuijten"
  },
  {
    "onderdeel": "10 000 m",
    "naam": "Marianne van de Linde",
    "prestatie": {
      "code": "\"00.33.44,00\""
    },
    "plaats": "Santpoort",
    "datum": "13-09-1991",
    "categorie": "Senioren",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": false,
    "datum": "08-05-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.04.22,06\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "1 500 m",
    "plaats": "Portland (US)",
    "naam": "Kristen Niedrach"
  },
  {
    "onderdeel": "150 m",
    "naam": "Albertine Vermeer",
    "prestatie": {
      "code": "\"00.00.19,81\""
    },
    "plaats": "Haarlem",
    "datum": "25-03-2018",
    "categorie": "Senioren",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "1 Engelse mijl",
    "naam": "Sabine Hannema",
    "prestatie": {
      "code": "\"00.05.54,79\""
    },
    "plaats": "Haarlem",
    "datum": "15-06-2018",
    "categorie": "Senioren",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "2 000 m",
    "naam": "Marianne van de Linde",
    "prestatie": {
      "code": "\"00.06.30,0\""
    },
    "plaats": "Haarlem",
    "datum": "13-04-1989",
    "categorie": "Senioren",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "26-06-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.25,05\""
    },
    "categorie": "Senioren",
    "onderdeel": "200 m",
    "link": "https://www.atletiek.nu/wedstrijd/startlijst/366744/118/",
    "plaats": "Breda",
    "naam": "Femke Nuijten"
  },
  {
    "onderdeel": "3 000 m",
    "naam": "Marianne van de Linde",
    "prestatie": {
      "code": "\"00.09.22,86\""
    },
    "plaats": "Haarlem",
    "datum": "24-07-1992",
    "categorie": "Senioren",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "400 m horden",
    "naam": "Albertine Vermeer",
    "prestatie": {
      "code": "\"00.01.04,44\""
    },
    "plaats": "Alkmaar",
    "datum": "27-05-2018",
    "categorie": "Senioren",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "04-07-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.57,28\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "400 m",
    "link": "https://www.atletiek.nu/wedstrijd/vereniging/35653/48/",
    "plaats": "Alphen aan de Rijn",
    "naam": "Albertine Vermeer"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "19-06-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.00.49,10\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "4 x 100 m",
    "plaats": "Santpoort",
    "naam": "Brenda Stoete, Carla v/d Klei, Jaqueline Konerman, Mieke v/d Kolk"
  },
  {
    "onderdeel": "4 x 200 m",
    "naam": "Mirthe Wiltink, Femke van der Wolf, Bente Elgersma, Albertine Vermeer",
    "prestatie": {
      "code": "\"00.01.49,09\""
    },
    "plaats": "Haarlem",
    "datum": "05-07-2019",
    "categorie": "Senioren",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "4 x 400 m",
    "naam": "Vera Hijlkema, Albertine Vermeer, Mirthe Wiltink, Femke van der Wolf",
    "prestatie": {
      "code": "\"00.04.11,58\""
    },
    "plaats": "Veenendaal",
    "datum": "16-06-2019",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/25458/4x400m/",
    "categorie": "Senioren",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Vrouwen",
    "handgeklokt": true,
    "datum": "08-07-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"00.16.22,4\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "5 000 m",
    "plaats": "Santpoort",
    "naam": "Marianne van de Linde"
  },
  {
    "onderdeel": "600 m",
    "naam": "Kristen Niedrach",
    "prestatie": {
      "code": "\"00.01.36,92\""
    },
    "plaats": "Haarlem",
    "datum": "25-03-2018",
    "categorie": "Senioren",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "800 m",
    "naam": "Kristen Niedrach",
    "prestatie": {
      "code": "\"00.02.07,85\""
    },
    "plaats": "Portland (US)",
    "datum": "03-06-2021",
    "categorie": "Senioren",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "17-06-1992",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"31,28\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Discuswerpen",
    "plaats": "Santpoort",
    "naam": "Toos Stoete"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "17-07-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"12,24\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hinkstapspringen",
    "plaats": "Assen",
    "naam": "Mieke van der Kolk"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "26-06-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"1,67\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Hoogspringen",
    "link": "https://www.atletiek.nu/wedstrijd/startlijst/366767/13/",
    "plaats": "Breda",
    "naam": "Babs Koeman (B)"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "08-09-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"22,92\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelslingeren",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/32178/Kogelsl/",
    "plaats": "Hilversum",
    "naam": "Bente Elgersma"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "16-06-2019",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"11,78\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Kogelstoten",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/25458/Kogel/",
    "plaats": "Veenendaal",
    "naam": "Mirthe Wiltink"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "23-08-2020",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"2,71\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Polsstokhoogspringen",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagenonderdeel/32991/Polshoog/#208710_",
    "plaats": "Lisse",
    "naam": "Noa Claus (B)"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "20-04-1992",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"44,96\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Speerwerpen",
    "plaats": "Haarlem",
    "naam": "Toos Stoete"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "16-07-1994",
    "locatie": "outdoor",
    "prestatie": {
      "code": "\"6,21\""
    },
    "einddatum": "",
    "categorie": "Senioren",
    "onderdeel": "Verspringen",
    "plaats": "Assen",
    "naam": "Mieke van der Kolk"
  },
  {
    "geslacht": "Vrouwen",
    "datum": "26-06-2021",
    "locatie": "outdoor",
    "prestatie": {
      "code": "[\n    {\n        \"onderdeel\": \"100 m horden\",\n        \"prestatie\": \"00:00:15,04\",\n        \"punten\": \"(836)\"\n    },\n    {\n        \"onderdeel\": \"Hoogspringen\",\n        \"prestatie\": \"1,67\",\n        \"punten\": \"(818)\"\n    },\n    {\n        \"onderdeel\": \"Kogelstoten\",\n        \"prestatie\": \"8,40\",\n        \"punten\": \"(425)\"\n    },\n    {\n        \"onderdeel\": \"200 m\",\n        \"prestatie\": \"00:00:26,86\",\n        \"punten\": \"(724)\"\n    },\n    {\n        \"onderdeel\": \"Verspringen\",\n        \"prestatie\": \"5,61\",\n        \"punten\": \"(732)\"\n    },\n    {\n        \"onderdeel\": \"Speerwerpen\",\n        \"prestatie\": \"36,97\",\n        \"punten\": \"(609)\"\n    },\n    {\n        \"onderdeel\": \"800 m\",\n        \"prestatie\": \"00:02:23,67\",\n        \"punten\": \"(774)\"\n    },\n    {\n        \"onderdeel\": \"Totaal\",\n        \"prestatie\": \"\",\n        \"punten\": \"4918 pnt\"\n    }\n]\n"
    },
    "einddatum": "27-06-2021",
    "categorie": "Senioren",
    "onderdeel": "Zevenkamp",
    "link": "https://www.atletiek.nu/wedstrijd/uitslagen/35336/229603/",
    "plaats": "Breda",
    "naam": "Babs Koeman (B)"
  },
  {
    "onderdeel": "Zweedse estafette",
    "naam": "Albertine Vermeer, Kristen Niedrach, Femke van der Wolf, Evelien Verbruggen",
    "prestatie": {
      "code": "\"00.02.21,72\""
    },
    "plaats": "Gouda",
    "datum": "22-04-2018",
    "categorie": "Senioren",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "10 Engelse mijl",
    "naam": "Marianne van de Linde",
    "prestatie": {
      "code": "\"00.55.34,00\""
    },
    "datum": "01-01-1992",
    "plaats": "?",
    "categorie": "Wegatletiek",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "10 km",
    "naam": "Marianne van de Linde",
    "prestatie": {
      "code": "\"00.33.49,00\""
    },
    "datum": "04-08-1993",
    "plaats": "Berkenrode",
    "categorie": "Wegatletiek",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "15 km",
    "naam": "Marianne van de Linde",
    "prestatie": {
      "code": "\"00.52.29,00\""
    },
    "datum": "21-11-1993",
    "plaats": "Nijmegen",
    "categorie": "Wegatletiek",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "20 km",
    "naam": "Marianne van de Linde",
    "prestatie": {
      "code": "\"01.12.03,00\""
    },
    "datum": "30-04-1991",
    "plaats": "Beverwijk",
    "categorie": "Wegatletiek",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "25 km",
    "naam": "Heidi van der Plas",
    "prestatie": {
      "code": "\"02.08.06,00\""
    },
    "datum": "23-02-1991",
    "plaats": "Aerdenhout",
    "categorie": "Wegatletiek",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "30 km",
    "naam": "Marianne van de Linde",
    "prestatie": {
      "code": "\"01.53.37,00\""
    },
    "datum": "20-03-1994",
    "plaats": "Spaarnwoude",
    "categorie": "Wegatletiek",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Halve marathon",
    "naam": "Marianne van de Linde",
    "prestatie": {
      "code": "\"01.13.35,00\""
    },
    "datum": "04-10-1992",
    "plaats": "Breda",
    "categorie": "Wegatletiek",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  },
  {
    "onderdeel": "Marathon",
    "naam": "Marianne van de Linde",
    "prestatie": {
      "code": "\"02.41.52,00\""
    },
    "datum": "25-10-1992",
    "plaats": "Frankfurt (DE)",
    "categorie": "Wegatletiek",
    "geslacht": "Vrouwen",
    "locatie": "outdoor"
  }
]

export default resultaten

