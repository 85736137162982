import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import resultaten from '../resultaten'
import Chart from "../components/Chart/Chart";
import Datum from "../components/Ranglijst/components/Datum";
import Namen from "../components/Ranglijst/components/Namen";
import Prestatie from "../components/Ranglijst/components/Prestatie";

const dateStringToObject = (input) => {
  const parts = input.split('-')
  return Date.parse(`${parts[2]}-${parts[1]}-${parts[0]}`)
}

export const sorteerOpDatumEnPrestatie = (input) => {
  const result = [...input]

  result.sort((a, b) => {
    return dateStringToObject(b.datum) - dateStringToObject(a.datum) || a.parsedPrestatie.value - b.parsedPrestatie.value
  })

  result.reverse()

  return result
}

const Historie = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const params = new URLSearchParams(location.search);
  const geslacht = params.get('geslacht')
  const locatie = params.get('locatie')
  const categorie = params.get('categorie')
  const onderdeel = params.get('onderdeel')

  const records = resultaten
  const filteredRecords = filterResultaten(records, geslacht, locatie, categorie, onderdeel)

  const firstRecord = filteredRecords[0]
  const titelPrefix = `${firstRecord.geslacht} ${firstRecord.categorie} ${firstRecord.locatie}`

  const grafiek = {
    records: sorteerOpDatumEnPrestatie(filteredRecords),
  }

  const handleClick = (event) => {
    event.preventDefault()
    navigate(-1)
  }

  return (
    <div className="Historie">
      <div className="container d-print-none">
        <div className="row">
          <div className="col">
            <h1>{firstRecord.onderdeel}</h1>
            <p className="lead">
              Historie {titelPrefix}
            </p>
            <p>
              <a href="/" onClick={handleClick}>← Terug naar overzicht</a>
            </p>
            <br/>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Chart records={grafiek.records} />
          </div>
        </div>

        <div className="row">
          <br/>
          <br/>

          <table className="table">
            <thead>
            <tr>
              <th>Datum</th>
              <th>Plaats</th>
              <th>Atleet</th>
              <th className="text-end">Prestatie</th>
            </tr>
            </thead>
            <tbody>
            {
              grafiek.records.map((record, i) => {
                return (
                  <tr key={i}>
                    <td><Datum value={record.datum} /></td>
                    <td>{record.plaats}</td>
                    <td className="text-end text-lg-start"><Namen value={record.naam} /></td>
                    <td className="text-nowrap text-end">
                      <Prestatie record={record} />
                    </td>
                  </tr>
                )
              })
            }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const filterResultaten = (records, geslacht, locatie, categorie, onderdeel) => {
  return records.filter(record => {
    return record.geslacht.toLowerCase() === geslacht &&
      record.locatie.toLowerCase() === locatie &&
      record.categorie.toLowerCase().replace(' ', '-') === categorie &&
      record.onderdeel.toLowerCase().replace(' ', '-') === onderdeel
  })
}

export default Historie
